import React from 'react'
import { get } from 'lodash'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import DownloadReport from '../DownloadReport'
import NlvMisMatchTable from './NlvMisMatchTable'
import { GET_NLV_MISMATCH_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { data, loading, error } = useQuery(GET_NLV_MISMATCH_REPORT, { variables: { where: { afterBod: 0, strategy: 'ILTS' } } })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spNlvMisMatchReport')) return <NoData />
  const { spNlvMisMatchReport: [spNlvMisMatchReport] } = data

  return (
    <>
      <DownloadReport data={spNlvMisMatchReport} fileName='NLV-report' id='NLV-REPORT-PDF' />
      <div className='report-table-wrapper' id='NLV-REPORT-PDF'>
        <NlvMisMatchTable reportData={spNlvMisMatchReport} />
      </div>
    </>
  )
}

import gql from 'graphql-tag'

export const GET_TICKETS = gql`
query getTickets($where: TicketsWhereInput, $skip: Int, $limit: Int, $sort: SortInput){
  getTickets(where: $where, skip: $skip, limit: $limit, sort: $sort) {
    tickets{
      id
      ticketNumber
      subject
      description
      generatedBy{
        id
        firstName
        lastName
        role
      }
      status
      assignUser{
        id
        firstName
        lastName
        role
      }
      closedDate
      createdAt
      updatedAt
    }
    count
  }
}
`

export const GET_TICKET = gql`
query getTicket($id: ID!){
  getTicket(where: { id: $id }) {
    id
    ticketNumber
    subject
    description
    status
    closedDate
    fileUrl
    reason
    generatedBy{
      id
      firstName
      lastName
      email
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      keyId
      isAuto
      isAssociate
      fundReceived
      isKycDone
      completedSteps
      amcStartDate
      sipStartDate
      closedDate
      initialExposure
      clientCode
      strategyCode
      createdBy {
        id
      }
      updatedBy {
        id
      }
      token
      createdAt
      updatedAt
    }
    assignUser{
      id
      firstName
      lastName
      role
    }
    createdAt
    updatedAt
  }
}
`

import React from 'react'
import { Table } from 'antd'
import { NavLink } from 'react-router-dom'

const columns = [
  {
    title: <NavLink to='/reports/position-fo' className='reports-position-name'>Futures &amp; Options Report</NavLink>,
    className: 'report-title main-title',
    children: [
      {
        title: 'Script',
        dataIndex: 'Script',
        key: 'Script',
        className: 'report-text'
      },
      {
        title: 'Quantity',
        dataIndex: 'TotalQty',
        key: 'TotalQty',
        className: 'report-numeric'
      },
      {
        title: 'Trade Rate',
        dataIndex: 'TradedRate',
        key: 'TradedRate',
        className: 'report-numeric'
      },
      {
        title: 'Current Rate',
        dataIndex: 'CMP',
        key: 'CMP',
        className: 'report-numeric'
      },
      {
        title: 'Value',
        dataIndex: 'Value',
        key: 'Value',
        className: 'report-numeric'
      },
      {
        title: 'Profit / Loss',
        dataIndex: 'Pnl',
        key: 'Pnl',
        className: 'report-numeric'
      }
    ]
  }
]

export default function ({ reportData }) {
  let data = reportData.map((report, key) => {
    return { key, ...report }
  })

  return (
    <Table
      dataSource={data}
      bordered
      columns={columns}
      // scroll={{ x: true, y: 240 }}
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    />
  )
}

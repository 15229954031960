import React, { useContext, Fragment, useEffect } from 'react'
import {
  TagOutlined,
  UserOutlined,
  FormOutlined,
  WalletOutlined,
  MessageOutlined,
  SettingOutlined,
  PieChartOutlined,
  CalendarOutlined,
  ApartmentOutlined,
  DashboardOutlined,
  NotificationOutlined,
  UsergroupAddOutlined,
  CustomerServiceOutlined,
  QuestionOutlined,
  FlagOutlined,
  RetweetOutlined,
  FileTextOutlined,
  SelectOutlined,
  ExceptionOutlined
} from '@ant-design/icons'
import { NavLink, useHistory } from 'react-router-dom'
import { Menu, Modal } from 'antd'

import { AppContext } from 'AppContext'
import { useQuery } from '@apollo/react-hooks'
import { GET_ADVISORY_DATA } from 'modules/PendingAdvisory/graphql/Queries'
import { uniqBy } from 'lodash'

const { SubMenu } = Menu

export default function SideBarMenu({ pathname, setVisibleDrawer = () => { } }) {
  const history = useHistory();
  const {
    state: {
      isAdmin,
      isStaff,
      isClient,
      isAssociate,
      currentReport,
      currentUser,
    },
  } = useContext(AppContext)
  let selectedItem = pathname.split('/')[1]
  if (selectedItem === 'reports') {
    const splitArray = pathname.split('/')
    selectedItem = splitArray[splitArray.length - 1]
  }

  const { data } = useQuery(GET_ADVISORY_DATA)

  useEffect(() => {
    if (data?.getAdvisoryData?.length) {
      Modal.warning({
        title: 'Kindly Complete The Pending Advisory',
      });
      history.push("/pending-advisory");
    }
  }, [data, history])


  return (
    <Menu
      theme='dark'
      mode='inline'
      selectedKeys={[selectedItem || 'dashboard']}
    >
      <Menu.Item key='dashboard' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/' className='nav-text'>
          <DashboardOutlined />
          <span>Dashboard</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        key='BookAnAppointment'
        onClick={() => setVisibleDrawer(false)}
      >
        <a
          href='https://www.finideas.com/appointment/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <CalendarOutlined />
          <span>Book an appointment</span>
        </a>
      </Menu.Item>
      <SubMenu
        key='reports'
        className='custom-sub-menu'
        title={
          <Fragment>
            <PieChartOutlined /> <span>Reports</span>
          </Fragment>
        }
      >
        {isClient && currentReport.includes('ILTS') && (
          <Menu.Item key='performance'>
            <NavLink
              to='/reports/performance'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Performance
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('ledger') && (
          <Menu.Item key='ledger'>
            <NavLink
              to='/reports/ledger'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Ledger
            </NavLink>
          </Menu.Item>
        )}
        {isClient && currentReport.includes('Interest') && (
          <Menu.Item key='interest'>
            <NavLink
              to='/reports/interest'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Interest
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('Unhedge') && (
          <Menu.Item key='unhedge'>
            <NavLink
              to='/reports/unhedge'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Unhedge
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('trade-eq') && (
          <Menu.Item key='trade-eq'>
            <NavLink
              to='/reports/trade-eq'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Trade EQ
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('trade-fo') && (
          <Menu.Item key='trade-fo'>
            <NavLink
              to='/reports/trade-fo'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Trade FO
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('cost-to-profit') && (
          <Menu.Item key='cost-to-profit'>
            <NavLink
              to='/reports/cost-to-profit'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Cost To Profit
            </NavLink>
          </Menu.Item>
        )}
        {isClient && currentReport.includes('Average Index') && (
          <Menu.Item key='average-index'>
            <NavLink
              to='/reports/average-index'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Average Index
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('NLV Mismatch') && (
          <Menu.Item key='nlv-mismatch'>
            <NavLink
              to='/reports/nlv-mismatch'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              NLV Mismatch
            </NavLink>
          </Menu.Item>
        )}
        {isClient && currentReport.includes('Current Position') && (
          <Menu.Item key='current-position'>
            <NavLink
              to='/reports/current-position'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Current Position
            </NavLink>
          </Menu.Item>
        )}
        {isClient && currentReport.includes('NLV Tracker') && (
          <Menu.Item key='nlv-tracker'>
            <NavLink
              to='/reports/comparison-of-fund'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Comparison of Fund
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('pl Change') && (
          <Menu.Item key='pl'>
            <NavLink
              to='/reports/pl'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Change in P &amp; L
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('Rollover Tracking') && (
          <Menu.Item key='rollover-tracking'>
            <NavLink
              to='/reports/rollover-tracking'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Rollover Tracking
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('RollOver Opportunity') && (
          <Menu.Item key='rollover-opportunity'>
            <NavLink
              to='/reports/rollover-opportunity'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Rollover Opportunity
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('Capital Gain') && (
          <Menu.Item key='ilts-taxation-report'>
            <NavLink
              to='/reports/ilts-taxation-report'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Taxation Report
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('Ielts Shift') && (
          <Menu.Item key='shifting-analysis'>
            <NavLink
              to='/reports/shifting-analysis'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              Shifting Analysis
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('current-position-j-balance') && (
          <Menu.Item key='j-balance'>
            <NavLink
              to='/reports/j-balance'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              J Balance
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('current-position-eq') && (
          <Menu.Item key='position-eq'>
            <NavLink
              to='/reports/position-eq'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              EQ Position
            </NavLink>
          </Menu.Item>
        )}
        {currentReport.includes('current-position-fo') && (
          <Menu.Item key='position-fo'>
            <NavLink
              to='/reports/position-fo'
              className='nav-text'
              onClick={() => setVisibleDrawer(false)}
            >
              FO Position
            </NavLink>
          </Menu.Item>
        )}
      </SubMenu>
      {!(isClient || isAssociate) && (
        <Menu.Item key='users' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/users' className='nav-text'>
            <UserOutlined />
            <span>Users</span>
          </NavLink>
        </Menu.Item>
      )}
      {!isClient && (
        <Menu.Item key='clients' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/clients' className='nav-text'>
            <UsergroupAddOutlined />
            <span>Clients</span>
          </NavLink>
        </Menu.Item>
      )}
      {!isClient && (
        <Menu.Item key='groups' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/groups' className='nav-text'>
            <ApartmentOutlined />
            <span>Groups</span>
          </NavLink>
        </Menu.Item>
      )}
      {!isClient && (
        <Menu.Item
          key='schedule-notifications'
          onClick={() => setVisibleDrawer(false)}
        >
          <NavLink to='/schedule-notifications' className='nav-text'>
            <NotificationOutlined />
            <span>Notifications</span>
          </NavLink>
        </Menu.Item>
      )}
      {currentReport.includes('view-amc') && (
        <Menu.Item key='amc' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/amc' className='nav-text'>
            <CalendarOutlined />
            <span>AMC</span>
          </NavLink>
        </Menu.Item>
      )}
      {currentReport.includes('view-sip') && (
        <Menu.Item key='sip' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/sip' className='nav-text'>
            <CalendarOutlined />
            <span>SIP</span>
          </NavLink>
        </Menu.Item>
      )}
      {(isAdmin || isStaff) && (
        <Menu.Item key='plans' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/plans' className='nav-text'>
            <FormOutlined />
            <span>Plans</span>
          </NavLink>
        </Menu.Item>
      )}

      {(isAdmin || isStaff) && (
        <Menu.Item key='flags' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/flags' className='nav-text'>
            <FlagOutlined />
            <span>Flags</span>
          </NavLink>
        </Menu.Item>
      )}

      <Menu.Item key='tickets' onClick={() => setVisibleDrawer(false)}>
        <NavLink to='/tickets' className='nav-text'>
          <TagOutlined />
          <span>Tickets</span>
        </NavLink>
      </Menu.Item>
      {!isClient && (
        <Menu.Item key='revenue-income' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/revenue-income' className='nav-text'>
            <WalletOutlined />
            <span>Revenue</span>
          </NavLink>
        </Menu.Item>
      )}

      {(isAdmin || isStaff) && (
        <Menu.Item key='questionnaires' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/questionnaires' className='nav-text'>
            <QuestionOutlined />
            <span>Questionnaires</span>
          </NavLink>
        </Menu.Item>
      )}

      {isAdmin && (
        <Menu.Item key='client-log' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/client-log' className='nav-text'>
            <UsergroupAddOutlined />
            <span>Client Log</span>
          </NavLink>
        </Menu.Item>
      )}

      {(isAdmin || isStaff) && (
        <SubMenu
          key='settings'
          className='custom-sub-menu'
          title={
            <Fragment>
              <SettingOutlined /> <span>Setting</span>
            </Fragment>
          }
        >
          <Menu.Item
            key='agreement-format'
            onClick={() => setVisibleDrawer(false)}
          >
            <NavLink to='/agreement-format' className='nav-text'>
              <FileTextOutlined />
              <span>Agreement Format</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key='messages' onClick={() => setVisibleDrawer(false)}>
            <NavLink to='/messages' className='nav-text'>
              <MessageOutlined />
              <span>Messages</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key='report-permission'
            onClick={() => setVisibleDrawer(false)}
          >
            <NavLink to='/report-permission' className='nav-text'>
              <SettingOutlined />
              <span>Report Permission</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key='maintain' onClick={() => setVisibleDrawer(false)}>
            <NavLink to='/maintain' className='nav-text'>
              <SettingOutlined />
              <span>Maintain</span>
            </NavLink>
          </Menu.Item>
          {isAdmin && (
            <Menu.Item key='client-fields'>
              <NavLink to='/client-fields' className='nav-text'>
                <SelectOutlined />
                <span>Client Fields</span>
              </NavLink>
            </Menu.Item>
          )}
          {isAdmin && (
            <Menu.Item
              key='alert-notification'
              onClick={() => setVisibleDrawer(false)}
            >
              <NavLink to='/alert-notification' className='nav-text'>
                <SettingOutlined />
                <span>Alert Notification</span>
              </NavLink>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {(!isAdmin || isStaff) && (
        <Menu.Item key='support' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/support' className='nav-text'>
            <CustomerServiceOutlined />
            <span>Support</span>
          </NavLink>
        </Menu.Item>
      )}

      {isClient && currentUser.completedSteps === 'DONE' && (
        <Menu.Item key='reevalute' onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/reevalute' className='nav-text'>
            <RetweetOutlined />
            <span>Re-evaluate</span>
          </NavLink>
        </Menu.Item>
      )}

      {isClient && (
        <Menu.Item key='pending-advisory'
          onClick={() => setVisibleDrawer(false)}>
          <NavLink to='/pending-advisory' className='nav-text'>
            <ExceptionOutlined />
            <span>Pending Advisory {data?.getAdvisoryData?.length ? `(${uniqBy(data.getAdvisoryData, 'AdvisorID')?.length})` : ""}  </span>
          </NavLink>
        </Menu.Item>
      )}
    </Menu>
  )
}

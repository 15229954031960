import React, { useContext, useEffect, useState } from 'react'
import { Form, Button, InputNumber, Input, Select, Modal, Checkbox } from 'antd'
import CustomMeta from 'components/CustomMeta'
import { AppContext } from 'AppContext'
import moment from 'moment'
import { getName } from 'utils/User'
import openNotification from 'utils/Notification'
import client from 'apollo'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import history from 'CustomHistory'
import { E_MANDATE, E_MANDATE_BANK_DATA } from 'modules/Users/graphql/Queries'
import { get } from 'lodash'
import { COMPLETE_STEP, CREATE_MANDATE } from './graphql/Mutations'
import { GET_SP_AUTO_ADVISE } from './graphql/Queries'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import './verification.css'
import { NavLink } from 'react-router-dom'

const FormItem = Form.Item
const { Option } = Select

export default function () {
  const [form] = Form.useForm()
  const [loadings, setLoadings] = useState()
  const [bankNames, setBankNames] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [agree, setAgree] = useState(false)
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext)
  const { id, email, phone, plan, initialExposure, initialInvestment } =
    currentUser

  useEffect(() => {
    client
      .query({ query: E_MANDATE_BANK_DATA, fetchPolicy: 'network-only' })
      .then((res) => setBankNames(res?.data?.eMandateBankData || []))
  }, [])

  const { loading, error, data } = useQuery(GET_SP_AUTO_ADVISE, {
    variables: {
      where: {
        dontBuyEquity: 'YES',
        exposure: initialExposure,
        planId: parseInt(plan?.id),
        udInvestment: initialInvestment,
      },
    },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  const spAutoAdvise = get(data, 'spAutoAdvise') || []
  const spData = spAutoAdvise.find(
    (a) => a.Description === '6 Month AMC (including GST)'
  )?.mandateAmount

  const onFinish = (values) => {
    setLoadings(true)
    const {
      accountType,
      accountNumber,
      bank,
      startDate = moment().format(),
      ifscCode,
    } = values
    client
      .query({
        query: E_MANDATE,
        variables: {
          data: {
            accountType,
            accountNumber,
            bank,
            startDate,
            ifscCode,
            amount: spData,
          },
        },
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        if (!get(response, 'data.eMandate')) {
          openNotification('error', 'Something went wrong!')
          setLoadings(false)
          return false
        }
        setLoadings(true)
        const options = {
          environment: process.env.REACT_APP_DIGIO_ENVIRONMENT,
          callback: function (response) {
            if (response.hasOwnProperty('error_code')) {
              setLoadings(false)
              return console.log('error occurred in process')
            }
            openNotification('success', ' Processing complete Successfully')
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  id,
                  data: {
                    accountType,
                    accountNumber: accountNumber.toString(),
                    bank,
                    mandateId: response.digio_doc_id,
                    completedSteps: 'DONE',
                    registrationDate: moment().format('YYYY-MM-DD'),
                  },
                },
              })
              .then((res) => {
                client.mutate({
                  mutation: CREATE_MANDATE,
                  variables: {
                    data: {
                      userId: id,
                      ifscCode: ifscCode,
                    },
                  },
                })
                client.mutate({
                  mutation: COMPLETE_STEP,
                  variables: {
                    data: {
                      step: 'DONE',
                      from: 'WEB',
                    },
                  },
                })
                dispatch({
                  type: 'CURRENT_USER',
                  data: res.data.updateUser,
                })
                dispatch({
                  type: 'IS_VERIFICATION_DONE',
                  data: true,
                })
                openNotification(
                  'success',
                  'Successfully Complete E Mandate Registration'
                )
                setLoadings(false)
                history.push('/')
              })
          },
          logo: 'https://www.mylogourl.com/image.jpeg',
          theme: {
            primaryColor: '#AB3498',
            secondaryColor: '#000000',
          },
        }

        let digio = new window.Digio(options)
        digio.init()
        digio.submit(response.data.eMandate.id, email)
      })
      .catch((err) => {
        setLoadings(false)
      })
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  }

  const videoModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  return (
    <>
      <h1>E Mandate Registration</h1>
      <div
        className='gx-app-login-wrap title-verification'
        style={{ overflowX: 'hidden' }}
      >
        <CustomMeta title='E-Mandate Registration' />
        <div className='gx-app-login-content verification'>
          <Form
            {...formItemLayout}
            form={form}
            name='register'
            labelAlign='left'
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{
              customerName: getName(currentUser),
              mobileNo: phone,
              email: email,
              amount: spData,
            }}
          >
            <Button
              style={{ margin: '0 8px' }}
              type='primary'
              onClick={() => videoModal()}
            >
              View E-Mandate Demo
            </Button>
            <Modal
              title='Play Video'
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={false}
            >
              <video
                src='https://finideas.sfo2.digitaloceanspaces.com/E-Mandate-Approval.mp4'
                type='video/mp4'
                autoPlay
                controls
                width={'100%'}
              ></video>
            </Modal>
            <div>
              <div
                style={{ display: 'flex', fontSize: '20px', margin: '10px 0' }}
              >
                Customer Details
              </div>
              <FormItem
                name='customerName'
                label='Customer Name'
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  className='input-placeholder'
                  placeholder='Customer Name'
                  autoFocus={true}
                  style={{ width: '100%', textAlign: 'center' }}
                  autoComplete='off'
                  disabled
                />
              </FormItem>
              <FormItem
                name='mobileNo'
                label='Mobile No'
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  placeholder='Mobile No'
                  style={{ width: '100%', textAlign: 'center' }}
                  autoComplete='off'
                  disabled
                />
              </FormItem>
              <FormItem
                name='email'
                label='Email'
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder='Email'
                  style={{ width: '100%', textAlign: 'center' }}
                  autoComplete='off'
                  disabled
                />
              </FormItem>
            </div>
            <div>
              <div
                style={{ display: 'flex', fontSize: '20px', margin: '10px 0' }}
              >
                Bank Details
              </div>
              <FormItem
                name='accountType'
                label='Account Type'
                rules={[
                  {
                    required: true,
                    message: 'Account Type is required!',
                  },
                ]}
              >
                <Select
                  placeholder='Account Type'
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'block',
                  }}
                >
                  <Option value='SAVINGS'>SAVINGS</Option>
                  <Option value='CURRENT'>CURRENT</Option>
                  <Option value='CREDIT_CASH'>CREDIT CASH</Option>
                  <Option value='OTHER'>OTHER</Option>
                </Select>
              </FormItem>
              <FormItem
                name='accountNumber'
                label='Account Number'
                rules={[
                  {
                    required: true,
                    message: 'Account Number is required!',
                  },
                ]}
              >
                <Input
                  type='number'
                  placeholder='Account Number'
                  style={{ width: '100%', textAlign: 'center' }}
                  autoComplete='off'
                />
              </FormItem>
              <FormItem
                name='bank'
                label='Bank'
                rules={[
                  {
                    required: true,
                    message: 'Bank is required!',
                  },
                ]}
              >
                <Select
                  placeholder='Bank Name'
                  showSearch
                  className='display-field'
                >
                  {bankNames.map((bankName) => (
                    <Option key={bankName.id} value={bankName.name}>
                      {bankName.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                name='ifscCode'
                label='IFSC Code'
                rules={[
                  {
                    required: true,
                    message: 'IFSC Code is required!',
                    pattern: new RegExp('^[A-Z]{4}0[A-Z0-9]{6}$'),
                  },
                ]}
              >
                <Input
                  placeholder='IFSC Code'
                  style={{ width: '100%', textAlign: 'center' }}
                  autoComplete='off'
                />
              </FormItem>
            </div>
            <div>
              <div
                style={{ display: 'flex', fontSize: '20px', margin: '10px 0' }}
              >
                Mandate Details
              </div>
              <FormItem name='amount' label='Amount'>
                <InputNumber
                  placeholder='Amount'
                  style={{ width: '100%' }}
                  autoComplete='off'
                  max={1000000}
                  disabled
                />
              </FormItem>
            </div>
            <div>
              <div className='agree-wrapper'>
                <Checkbox onChange={handleAgree} />
                <p className='agree-content'>
                  I/We hereby declare that the details furnished above are true
                  and correct to the best of my/our knowledge and belief and
                  I/We undertake to inform you of any changes therein,
                  immediately. In case any of the above information is found to
                  be false or untrue or misleading or misrepresenting, I am/We
                  are aware that I/We may be held liable for it.
                </p>
              </div>
              <Button style={{ margin: '0 8px' }} type='primary'>
                <NavLink to='/reevalute' className='nav-text'>
                  <span>Re-evaluate</span>
                </NavLink>
              </Button>
              <Button
                style={{ margin: '0 8px' }}
                loading={loadings}
                type='primary'
                htmlType='submit'
                disabled={!agree}
              >
                Done
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

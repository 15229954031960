import React, { useState } from 'react'
import { Modal, Form, Input, Select, Button, Space, InputNumber, Switch } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import client from 'apollo'
import openNotification from 'utils/Notification'
import { DELETE_OPTION } from './graphql/Mutation'
import { GET_SECTIONS } from 'modules/Verification/graphql/Queries'


const { confirm } = Modal
const { Option } = Select
const CollectionCreateForm = ({ visible, onCreate, onUpdate, onCancel, isEdit, question = {}, planId }) => {
  const [form] = Form.useForm()
  const [selectType, setSelectType] = useState(question.type)
  const resetForm = () => {
    form.resetFields()
  }

  const handleChange = (value) => {
    setSelectType(value)
  }

  function showOptionConform(id, cb) {
    confirm({
      okType: 'danger',
      title: `Do you want to delete this Option?`,
      content: `When clicked the OK button, this Option will be Deleted`,
      onOk() {
        client
          .mutate({ mutation: DELETE_OPTION, variables: { id }, refetchQueries: [{ query: GET_SECTIONS }] })
          .then((res) => {
            openNotification('success', 'Option Deleted Successfully')
            cb()
          })
          .catch((err) => (err))
      }
    })
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isEdit ? 'Edit Questions' : 'Add New Question'}
      okText={isEdit ? 'Update Question' : 'Add Question'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            const data = {
              ...values,
              planId
            }
            if (isEdit) {
              onUpdate(data, resetForm)
            } else {
              onCreate(data, resetForm)
            }
          })
          .catch(info => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
          isRequired: true,
          ...question,
          planId
        }}
      >
        <Form.Item
          name='question'
          label='Question'
          rules={[{ required: true, message: 'Question is require', whitespace: true }]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='type'
          label='Type'
          rules={[{ required: true, message: 'Type is require' }]}
          hasFeedback
        >
          <Select style={{ width: '100%' }} onChange={handleChange}>
            <Option value="CHECK_BOX">CHECK_BOX</Option>
            <Option value="RADIO">RADIO</Option>
            <Option value="TEXT">TEXT</Option>
          </Select>
        </Form.Item>

        {selectType !== 'TEXT' ? (
          <Form.Item
            name='options'
            label='Options'
            rules={[{ required: true, message: 'Options is require' }]}
            hasFeedback
          >
            <Form.List name="options">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((props) => {
                    const { fieldKey, key, id, name, ...restField } = props
                    return (
                      <Space key={key} style={{ display: 'flex' }} align="baseline">
                        {selectType !== 'TEXT' && (
                          <>
                            <Form.Item
                              {...restField}
                              label={key === 0 ? 'Option' : ''}
                              name={[name, 'option']}
                              rules={[{ required: true, message: 'Option is require' }]}
                            >
                              <Input placeholder="Option" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label={key === 0 ? 'Point' : ''}
                              name={[name, 'point']}
                              rules={[{ required: true, message: 'Point is require' }]}
                            >
                              <InputNumber placeholder="Point" defaultValue={0} />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              label={key === 0 ? 'Default' : ''}
                              name={[name, 'isDefault']}
                              rules={[{ required: true, message: 'Point is require' },
                              ({ getFieldsValue, setFieldsValue }) => ({
                                validator(rule, value) {
                                  if (value && selectType === 'RADIO') {
                                    const { options } = getFieldsValue()
                                    fields.forEach(field => {
                                      if (field.name !== name) {
                                        Object.assign(options[field.name], { isDefault: false })
                                      }
                                    });
                                    setFieldsValue({ options })
                                  }
                                  return Promise.resolve()
                                },
                              }),]}
                            >
                              <Select
                                placeholder='Set default'
                                style={{ width: '100%' }}
                              >
                                <Option value={true}>True</Option>
                                <Option value={false}>False</Option>
                              </Select>
                            </Form.Item>
                          </>
                        )
                        }
                        <MinusCircleOutlined
                          onClick={() => {
                            if (isEdit) {
                              if (question?.options[props.key]?.id) {
                                showOptionConform(question?.options[props.key]?.id, () => remove(name))
                              } else {
                                remove(name)
                              }
                            } else {
                              remove(name)
                            }
                          }}
                        />
                      </Space>
                    )
                  })}
                  <Form.Item>
                    <Button style={{ width: '27%' }} onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Option
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        ) : (
          <Form.Item
            name='point'
            rules={[{ required: true, message: 'Point is require' }]}
            hasFeedback
          >
            <InputNumber placeholder="point" defaultValue={0} />
          </Form.Item>
        )}
        <Form.Item
          name='isRequired'
          label='Required'
        >
          <Switch defaultChecked />
        </Form.Item>
      </Form>
    </Modal >
  )
}

const CreateQuestionModal = (props) => {

  return (
    <div>
      <CollectionCreateForm
        {...props}
      />
    </div>
  )
}

export default CreateQuestionModal



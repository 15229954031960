import React, { useEffect, useState } from 'react'
import { Button, InputNumber, Modal, Table } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import client from 'apollo'

import { cloneDeep } from 'lodash'
import {
  UPDATE_ADVISORY_STATUS,
  UPDATE_ADVISORY_VALUE,
} from './graphql/Mutation'

export default function PendingAdvisoryTable({ advisoryData, refetch }) {
  const data = cloneDeep(advisoryData)
  const [accept, setAccept] = useState(false)
  const { confirm } = Modal

  useEffect(() => {
    data.forEach((d) => {
      d.TradedQuantity = +d.Quantity
    })
  }, [data])

  const columns = [
    {
      title: 'Pending Advisory',
      className: 'report-title main-title',
      children: [
        {
          title: (
            <div className='hidden-client-data'>
              {advisoryData[0]?.AdvisoryType}
              <span className='hidden-client-code'></span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Trade Type',
              dataIndex: 'TradeType',
              key: 'TradeType',
              className: 'report-text average-index-column-align',
            },
            {
              title: 'Script',
              dataIndex: 'Script',
              key: 'Script',
              className: '',
            },
            {
              title: 'Expiry',
              dataIndex: 'Expiry',
              key: 'Expiry',
              className: '',
            },
            {
              title: 'Strike',
              dataIndex: 'Strike',
              key: 'Strike',
              className: '',
            },
            {
              title: 'Type',
              dataIndex: 'Type',
              key: 'Type',
              className: '',
            },
            {
              title: 'Trade',
              dataIndex: 'Trade',
              key: 'Trade',
              className: '',
            },
            {
              title: 'Quantity',
              dataIndex: 'Quantity',
              key: 'Quantity',
              className: '',
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              {advisoryData.length
                ? new Date(advisoryData[0]?.AdvisoryDate)
                    .toISOString()
                    .replace('Z', '')
                    .replace('T', ' ')
                : ''}
              <span className='hidden-client-code'></span>
            </div>
          ),
          className: 'hidden-client-data-th',
          editable: true,
          children: [
            {
              title: 'Traded Quantity',
              dataIndex: 'TradedQuantity',
              key: 'TradedQuantity',
              render: (text, record, key) => {
                if (accept) {
                  return (
                    <InputNumber
                      defaultValue={accept && (text || record.Quantity)}
                      onChange={(value) => {
                        data[key].TradedQuantity = value
                      }}
                    />
                  )
                }
              },
              className: '',
            },
            {
              title: 'Price',
              dataIndex: 'TradePrice',
              key: 'TradePrice',
              render: (text, _, key) => {
                if (accept) {
                  return (
                    <InputNumber
                      defaultValue={_.TradePrice}
                      onChange={(value) => (data[key].TradePrice = value)}
                    />
                  )
                }
              },
              className: '',
            },
          ],
        },
      ],
    },
  ]

  const showConfirm = () => {
    let flag = true
    data.forEach((data) => {
      if (data.TradedQuantity !== 0 && data.TradePrice <= 0) {
        flag = false
      }
      if (data.TradedQuantity === 0) {
        data.TradePrice = 0
      }
    })

    flag
      ? confirm({
          title: 'Are You Sure To Submit The Advisory?',
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            if (data) {
              const statusData = data.map((d) => {
                return {
                  advisorId: parseInt(d.AdvisorID),
                  clientCode: d.ClientCode,
                  status: 'Accept',
                }
              })
              client
                .mutate({
                  mutation: UPDATE_ADVISORY_STATUS,
                  variables: { data: statusData },
                })
                .then(() => {
                  data.forEach((d) => {
                    d.AdvisorID = parseInt(d.AdvisorID)
                    if (d.AdvisoryType) {
                      delete d.AdvisoryType
                    }
                    if (d.AdvisoryDate) {
                      delete d.AdvisoryDate
                    }
                    if (d.Quantity) {
                      delete d.Quantity
                    }
                  })
                  client
                    .mutate({
                      mutation: UPDATE_ADVISORY_VALUE,
                      variables: { data },
                    })
                    .then(() => {
                      setAccept(false)
                      refetch()
                    })
                })
            } else {
              setAccept(false)
            }
          },
        })
      : confirm({
          title: 'Traded Quantity and Trade Price must be greater then 0',
          icon: <ExclamationCircleOutlined />,
        })
  }

  const handleAccept = (isReject = false) => {
    confirm({
      title: isReject
        ? 'Do You Want To Discard The Received Advisory?'
        : 'Kindly Update The Traded Quantity And Price?',
      icon: <ExclamationCircleOutlined />,
      okText: isReject ? 'Yes' : 'Accept',
      cancelText: isReject ? 'No' : 'Cancel',
      onOk() {
        if (isReject) {
          const statusData = data.map((d) => {
            return {
              advisorId: parseInt(d.AdvisorID),
              clientCode: d.ClientCode,
              status: 'Reject',
            }
          })
          client
            .mutate({
              mutation: UPDATE_ADVISORY_STATUS,
              variables: { data: statusData },
            })
            .then((res) => {
              refetch()
            })
        } else {
          setAccept(true)
        }
      },
    })
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        className='average-index-report'
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          width: '100%',
          marginTop: '1rem',
        }}
        className='advisory-btn'
      >
        {accept ? (
          <>
            <Button type='primary' onClick={showConfirm}>
              {'Submit'}
            </Button>
            <Button onClick={() => setAccept(false)}>{'Cancel'}</Button>
          </>
        ) : (
          <>
            {data.length > 0 && (
              <>
                <Button type='primary' onClick={() => handleAccept()}>
                  {'Accept'}
                </Button>
                <Button onClick={() => handleAccept(true)}>{'Reject'}</Button>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

import gql from 'graphql-tag'

export const GET_SECTIONS = gql`
  query getSections($where: GetSectionBasedOnPlanInput){
    getSections (where: $where) {
      id
		  section
		  index
		  questions {
		  	id
			  question
			  index
			  type
			  isRequired
        point
			  options {
		  		id
				  option
				  point
				  isDefault
			  }
		  }
    }
  }
  `

export const GET_KYC = gql`
  query kyc($userId: Int){
    kyc(where: { userId: $userId }) {
      id
      kycForm
      agreementData
    }
  }
  `
export const GET_SP_AUTO_ADVISE = gql`
  query spAutoAdvise($where:spAutoAdviseInput){
    spAutoAdvise(where:$where)
  }
  `
export const GET_SP_AGREEMENT_DATA = gql`
  query spAgreementData($where:spAgreementInput){
    spAgreementData(where:$where)
  }
  `

export const GET_USER_ANSWERS = gql`
  query getUserAnswers($where:WherePlanInput){
    getUserAnswers(where:$where) {
      id
      user {
        id
      }
      question {
        id
        type
      }
      selectedOptions
    }
  }
`

export const GET_ALL_USERS_ANSWERS = gql`
  query getAllUsersAnswers($where:AnswerUniqueInput){
    getAllUsersAnswers (where:$where){
      id
      user{
        id
      }
      question{
        id
        type
        question
      }
      selectedOptions
    }
  }
`

export const GET_MANDATE = gql`
  query getMandate($where:MandateWhereUniqueInput!){
    getMandate (where:$where){
       id
    user{
      id
      bank
      accountType
      accountNumber
      email
      phone
     firstName
      lastName
      mandateId
    }
    ifscCode
    mandateStatus
    bankStatus
    createdAt
    }
  }
`



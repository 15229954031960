import React, { useState, useCallback, useEffect } from 'react'

import update from 'immutability-helper'
import { Card } from './Cards'
import Section from './Section'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'
import { GET_SECTIONS } from './graphql/Query'
import { get } from 'lodash'
import Loader from 'components/loaders/Loader'

const Container = ({ planId, planName }) => {
  const [sections, setSections] = useState([])
  const { loading, error, data } = useQuery(GET_SECTIONS, { variables: { planId }, fetchPolicy: 'network-only' })

  useEffect(() => {
    if (!loading && get(data, 'getSections')) {
      setSections(data.getSections)
    }
  }, [loading, data])

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const dragCard = sections[dragIndex]
    const updatedData = update(sections, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    })
      .map((u, key) => {
        u.index = key
        return u
      })
    setSections(updatedData)
  }, [sections])
  if (loading) return <Loader />

  if (error) return <Page404 error={error} />

  const renderCard = (section, index) => {
    const { id, section: name, questions } = section
    return (
      <Card
        key={id}
        index={index}
        id={id}
        text={<Section planId={planId} planName={planName} section={section} name={name} questions={questions} />}
        moveCard={moveCard}
        sections={sections}
      />)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        {sections.map((section, i) => renderCard(section, i))}
      </div>
    </>
  )
}

export default Container

import React, { useState, useContext } from 'react'
import { Table, Button, Input, Space, Tag, DatePicker } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'

import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import { GET_TICKETS } from '../graphql/Queries'
import { getName, getUserNavLinkByObject } from 'utils/User'

const { RangePicker } = DatePicker

export default function TabTickets({ defaultFilters = {} }) {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [filters, setFilters] = useState(defaultFilters)
  const {
    state: { isClient, isAssociate },
  } = useContext(AppContext)

  const variables = { where: filters, limit: 10, skip, sort }
  const { data, loading, error } = useQuery(GET_TICKETS, {
    variables,
    fetchPolicy: 'network-only',
  })

  if (error) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getTickets.tickets')) {
    totalCount = data.getTickets.count
    tableData = data.getTickets.tickets.map((ticket, key) => ({
      key: key.toString(),
      ...ticket,
    }))
  }

  function handlePagination(page) {
    setSkip((page - 1) * 10)
    setCurrentPage(page)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (tempFilters[filedName]) {
      delete tempFilters[filedName]
    }
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.status) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = filter.status
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['status'] = ''
      delete tempFilters.status
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          format={'DD-MMM-YYYY'}
          onChange={(e) => {
            const from = `from${dataIndex}Date`
            const to = `to${dataIndex}Date`
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters[from] = e[0]
              tempFilters[to] = e[1]
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters[from]
              delete tempFilters[to]
            }
            setFilters(tempFilters)
            setCurrentPage(1)
          }}
        />
      </div>
    ),
  })

  const columns = [
    {
      title: 'Ticket Number',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      render: (text, record) => (
        <NavLink to={`/tickets/${record.id}`}>{text}</NavLink>
      ),
      ...getColumnSearchProps('ticketNumber'),
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Tag className={status}>{status}</Tag>,
      filters: [
        { text: 'OPEN', value: 'OPEN' },
        { text: 'PENDING', value: 'PENDING' },
        { text: 'CLOSED', value: 'CLOSED' },
      ],
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'User',
      dataIndex: 'generatedBy',
      key: 'generatedBy',
      render: (user) =>
        isAssociate ? getName(user) : getUserNavLinkByObject(user, isClient),
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignUser',
      key: 'assignUser',
      render: (user) =>
        isAssociate ? getName(user) : getUserNavLinkByObject(user, isClient),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('Do MMMM YYYY'),
      ...getColumnDateProps('CreatedAt'),
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Closed At',
      dataIndex: 'closedDate',
      key: 'closedDate',
      render: (date) => (date ? moment(date).format('Do MMMM YYYY') : '-'),
      ...getColumnDateProps('Closed'),
      sorter: (a, b) => a.age - b.age,
    },
  ]

  return (
    <Table
      loading={loading}
      dataSource={tableData}
      onChange={handleTableChange}
      scroll={{ x: true, y: true }}
      columns={
        isClient
          ? columns.filter((column) => column.title !== 'Client')
          : columns
      }
      pagination={{
        pageSize: 10,
        total: totalCount,
        onChange: handlePagination,
        current: currentPage,
      }}
    />
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Input } from 'antd'

import { SEND_PASSWORD_LINK, GET_EMAIL_FROM_USERNAME } from './graphql/Queries'
import openNotification from 'utils/Notification'
import Logo from 'assets/images/logo-tagline.png'
import client from 'apollo'
import './index.css'

const FormItem = Form.Item

export default function ({ history }) {
  function onFinish(values) {
    const { userName } = values
    client
      .query({
        query: GET_EMAIL_FROM_USERNAME,
        variables: { where: { userName } },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        const email = res.data.getEmailFromUserName.email
        client
          .mutate({ mutation: SEND_PASSWORD_LINK, variables: { userName } })
          .then((res) => {
            openNotification(
              'success',
              `Reset password link has been sent to your ${email}`
            )
            history.push('/login')
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => console.log(err))
  }

  let userName = window.location.href.split('=')[1] === 'undefined' && ''

  return (
    <div className='gx-login-container forgot-password-wrapper'>
      <div className='gx-login-content'>
        <div className='gx-login-header'>
          <img alt='Finideas' src={Logo} />
        </div>
        <div className='gx-mb-4'>
          <h2>Forgot Your Password ?</h2>
          <p>
            Don't worry. Recovering the password is easy. Enter the Username you
            have registered with Finideas
          </p>
        </div>
        <Form
          name='basic'
          onFinish={onFinish}
          initialValues={{ remember: true, userName }}
          className='gx-signin-form gx-form-row0'
        >
          <FormItem
            name='userName'
            rules={[
              { required: true, message: 'Please input your valid username!' },
            ]}
          >
            <Input className='gx-input-lineheight' placeholder='Username' />
          </FormItem>
          <FormItem>
            <Button type='primary' htmlType='submit' size='large'>
              Send
            </Button>
          </FormItem>
          <div className='forgot-password'>
            <Link to='/login'>Remember Password?</Link>
          </div>
        </Form>
      </div>
    </div>
  )
}

import React, { useState, useContext } from 'react'
import { Col, Button, Tooltip, Table, Modal } from 'antd'
import { EditTwoTone, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'
import moment from 'moment'

import { CREATE_AMC, UPDATE_AMC, DELETE_AMC } from 'modules/Users/graphql/Mutations'
import { GET_AMC } from 'modules/Users/graphql/Queries'
import openNotification from 'utils/Notification'
import CreateAMCModal from 'modules/AMC/Modal'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import client from 'apollo'

const { confirm } = Modal

export default function ({ id, amcAmount }) {
  let tableData = []
  const clientId = id ? parseInt(id) : undefined
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const { state: { isClient, currentReport } } = useContext(AppContext)
  const [updateData, setUpdateData] = useState(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)

  const { data, loading, error } = useQuery(GET_AMC, { variables: { clientId: clientId.toString(), ...filters, skip, sort, limit }, fetchPolicy: 'network-only' })

  if (error) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getAllAmcs.amc')) {
    totalCount = data.getAllAmcs.count
    tableData = data.getAllAmcs.amc.map((user, key) => ({ key: key.toString(), ...user }))
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === "descend") {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC'
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC'
        })
        setCurrentPage(1)
      }
    }
    else if (filter.role) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = filter.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
    else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = ''
      delete tempFilters.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleCreate(values, resetForm) {
    setShowFormLoading(true)
    const dateMoment = moment(values.date)
    const date = `${moment().format('YYYY')}-${dateMoment.format('M')}-${dateMoment.format('D')}`
    // values.startDate = moment(new Date(date)).add(1, 'days')
    values.endDate = moment(new Date(date)).add(1, 'years')
    delete values.date
    values.clientId = parseInt(clientId)
    values.whichYear = parseInt(values.whichYear.format('YYYY'))
    client.mutate({
      mutation: CREATE_AMC,
      variables: { data: values },
      refetchQueries: [{ query: GET_AMC, variables: { clientId: clientId.toString(), ...filters, skip, sort, limit }, fetchPolicy: 'network-only' }]
    })
      .then((res) => {
        openNotification('success', 'AMC Created Successfully')
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function handleUpdate(values, resetForm) {
    const dateMoment = moment(values.date)
    const date = `${moment().format('YYYY')}-${dateMoment.format('M')}-${dateMoment.format('D')}`
    // values.startDate = moment(new Date(date)).add(1, 'days')
    values.endDate = moment(new Date(date)).add(1, 'years')
    values.whichYear = parseInt(values.whichYear.format('YYYY'))
    delete values.date
    setShowFormLoading(true)
    client.mutate({
      mutation: UPDATE_AMC,
      variables: { data: values, id: updateData.id },
      refetchQueries: [{ query: GET_AMC, variables: { clientId: clientId.toString(), ...filters, skip, sort, limit }, fetchPolicy: 'network-only' }]
    })
      .then((res) => {
        openNotification('success', 'AMC Updated Successfully')
        setUpdateData(undefined)
        setShowFormModal(false)
        setIsUpdate(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this AMC?`,
      content: `When clicked the OK button, this AMC will be Deleted`,
      onOk() {
        client
          .mutate({ mutation: DELETE_AMC, variables: { id }, refetchQueries: [{ query: GET_AMC, variables: { clientId: clientId.toString(), ...filters, skip, sort, limit } }] })
          .catch((err) => console.log(err))
      }
    })
  }

  function renderAction(record) {
    return <div className='action-icons'>
      {
        currentReport.includes('Update AMC') &&
        <Tooltip title='Edit'>
          <EditTwoTone
            onClick={() => {
              setIsUpdate(true)
              setUpdateData(record)
              setShowFormModal(true)
            }}
          />
        </Tooltip>
      }
      {
        currentReport.includes('Delete AMC') && <Tooltip title='Delete AMC'>
          <DeleteOutlined onClick={() => showConfirm(record.id)} />
        </Tooltip>
      }
    </div>
  }

  const columns = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Qty',
      dataIndex: 'amcQty',
      key: 'amcQty',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Year',
      dataIndex: 'whichYear',
      key: 'whichYear',
      sorter: (a, b) => a.age - b.age,
      // ...getColumnSearchProps('whichYear')
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
      sorter: (a, b) => a.age - b.age,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: date => date ? moment(date).format('Do MMMM YYYY') : '',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Paid At',
      dataIndex: 'paidAt',
      key: 'paidAt',
      render: date => date ? moment(date).format('Do MMMM YYYY') : '',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Next Due',
      dataIndex: 'nextDueDate',
      key: 'nextDueDate',
      render: date => date ? moment(date).format('Do MMMM YYYY') : '',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.age - b.age,
      render: date => moment(date).format('Do MMMM YYYY'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record)
    },
  ]

  return (
    <>
      {
        showFormModal &&
        <CreateAMCModal
          data={updateData}
          amount={amcAmount}
          isUpdate={isUpdate}
          visible={showFormModal}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          loading={showFormLoading}
          onCancel={() => {
            setShowFormModal(false)
            setUpdateData(undefined)
            setIsUpdate(false)
          }}
        />
      }
      {
        !isClient && clientId && currentReport.includes('Create AMC') &&
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            key='2'
            type='primary'
            onClick={() => {
              setShowFormModal(true)
              setIsUpdate(false)
              setUpdateData(undefined)
            }}
          >
            Add AMC
          </Button>
        </Col>
      }
      <Col span={24}>
        <Table
          loading={loading}
          expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
            rowExpandable: record => record.name !== 'Not Expandable',
          }}
          dataSource={tableData}
          onChange={handleTableChange}
          scroll={{ x: true, y: true }}
          columns={isClient ? columns.slice(0, columns.length - 1) : columns}
          pagination={{ pageSize: limit, total: totalCount, onChange: handlePagination, current: currentPage, onShowSizeChange: handlePageSizeChange }}
        />
      </Col>
    </>
  )
}

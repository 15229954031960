import React, { Fragment, useState, useContext } from 'react'
import { PageHeader, Button, Tabs } from 'antd'
import get from 'lodash/get'

import client from 'apollo'
import TabTickets from './TabTickets'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { CREATE_TICKET } from './graphql/Mutations'
import CreateTicketModal from './CreateTicketModal'

const TabPane = Tabs.TabPane

export default function () {
  const { state: { currentReport, isClient, isAdmin, currentUser: { id } } } = useContext(AppContext)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)


  function handleCreateTicket(values, resetForm) {
    setShowFormLoading(true)
    if (get(values, 'fileUrl[0].originFileObj')) {
      values.fileUrl = get(values, 'fileUrl[0].originFileObj')
    } else {
      delete values.fileUrl
    }
    client.mutate({
      mutation: CREATE_TICKET, variables: { ...values },
    })
      .then((res) => {
        openNotification('success', 'Ticket Generated Successfully')
        setShowFormModal(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  return (
    <Fragment>
      <CustomMeta title='Tickets' />
      <PageHeader
        className='box'
        title='Tickets'
        extra={[
          !isAdmin && currentReport.includes('Create Ticket') &&
          <Button
            key='1'
            type='primary'
            onClick={() => setShowFormModal(true)}
          >
            Generate Ticket
          </Button>
        ]}
      />
      {
        !isAdmin && showFormModal &&
        <CreateTicketModal
          visible={showFormModal}
          loading={showFormLoading}
          onCreate={handleCreateTicket}
          onCancel={() => setShowFormModal(false)}
        />
      }
      {
        isClient || isAdmin ?
          <TabTickets />
          :
          <Widget title='&nbsp;' styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'>
            <Tabs defaultActiveKey='1'>
              <TabPane tab='Assigned Tickets' key='1'>
                <div className='gx-mb-2'>
                  <TabTickets
                    defaultFilters={{
                      assignUserId: parseInt(id)
                    }}
                  />
                </div>
              </TabPane>
              <TabPane tab='Generated Tickets' key='2'>
                <div className='gx-mb-2'>
                  <TabTickets
                    defaultFilters={{
                      generatedById: parseInt(id)
                    }}
                  />
                </div>
              </TabPane>
            </Tabs>
          </Widget>
      }
    </Fragment>
  )
}

import React, { useEffect, useState } from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _first from 'lodash/first'
import { useQuery } from '@apollo/react-hooks'
import { PageHeader, Select, Button } from 'antd'
import sumBy from 'lodash/sumBy'

import client from 'apollo'
import history from 'CustomHistory'
import NoData from 'components/NoData'
import { sendMePdf } from 'utils/Graph'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import ILTS from 'modules/Reports/ILTS/Tables/ILTS'
import Stock from 'modules/Reports/ILTS/Tables/Stock'
import Ledger from 'modules/Reports/ILTS/Tables/Ledger'
import DownloadReport from 'modules/Reports/DownloadReport'
import { GET_ILTS_REPORT } from 'modules/Reports/graphql/Queries'
import OpenPosition from 'modules/Reports/ILTS/Tables/OpenPosition'
import { GET_STRATEGY } from 'modules/Settings/graphql/Queries'
import 'modules/Reports/index.css'

const { Option } = Select

export default function (props) {
  const [strategy, setStrategy] = useState()
  const [sendMeLoading, setSendMeLoading] = useState(false)
  const [strategyList, setStrategyList] = useState(undefined)
  let { match: { params: { clientCode, clientId } } } = props
  const { data, loading, error } = useQuery(GET_ILTS_REPORT, { variables: { where: { clientCode, date: null, strategy } }, fetchPolicy: "network-only" })

  useEffect(() => {
    client.query({ query: GET_STRATEGY, variables: { where: { clientCode } }, fetchPolicy: 'network-only' })
      .then((res) => {
        if (_get(res, 'data.getStrategy')) {
          setStrategyList(_get(res, 'data.getStrategy'))
          if (res.data.getStrategy.length === 1) {
            setStrategy(res.data.getStrategy[0].StrategyCode)
          }
        }
      })
      .catch(() => setStrategyList([]))
  }, [clientCode])


  let spIltsreport
  let futuresOptionsReport = {}
  let stockReport = {}
  let ledgerReport = {}

  if (!loading && _get(data, 'spIltsreport')) {
    spIltsreport = data.spIltsreport[0]
    const reportFutures = spIltsreport.filter((report, key) => (report.Script && report.Script.startsWith('OPTIDX ')))
    futuresOptionsReport = reportFutures.map((reportFuture) => {
      return {
        ...reportFuture,
        TotalQty: Math.round(reportFuture.TotalQty),
        TradedRate: Math.round(reportFuture.TradedRate),
        CMP: Math.round(reportFuture.CMP),
        Value: Math.round(reportFuture.Value),
        Pnl: Math.round(reportFuture.Pnl),
      }
    })
    futuresOptionsReport = [
      ...futuresOptionsReport,
      {
        Script: 'Total',
        TotalQty: sumBy(futuresOptionsReport, 'TotalQty'),
        Value: sumBy(futuresOptionsReport, 'Value'),
        Pnl: sumBy(futuresOptionsReport, 'Pnl'),
      },
    ]

    const reportStocks = spIltsreport.filter((report, key) => (report.Script && !report.Script.startsWith('OPTIDX ')))
    stockReport = reportStocks.map((reportStock) => {
      return {
        ...reportStock,
        TotalQty: Math.round(reportStock.TotalQty),
        TradedRate: Math.round(reportStock.TradedRate),
        CMP: Math.round(reportStock.CMP),
        Value: Math.round(reportStock.Value),
        Pnl: Math.round(reportStock.Pnl),
      }
    })
    stockReport = [
      ...stockReport,
      {
        Script: 'Total',
        TotalQty: sumBy(stockReport, 'TotalQty'),
        Value: sumBy(stockReport, 'Value'),
        Pnl: sumBy(stockReport, 'Pnl'),
      },
    ]

    const ledgers = spIltsreport.filter((report, key) => ((key !== 0 && key !== 1 && key !== 2) && !report.Script))
    ledgerReport = ledgers.map((reportLedger) => {
      return {
        ...reportLedger,
        DR_Amount: Math.round(reportLedger.DR_Amount),
        CR_Amount: Math.round(reportLedger.CR_Amount),
      }
    })
    ledgerReport = [
      ...ledgerReport,
      {
        Entrydate: 'Total',
        DR_Amount: sumBy(ledgerReport, 'DR_Amount'),
        CR_Amount: sumBy(ledgerReport, 'CR_Amount'),
        remark: '',
      },
      {
        Entrydate: 'Net Value',
        DR_Amount: sumBy(ledgerReport, 'CR_Amount') - sumBy(ledgerReport, 'DR_Amount'),
        CR_Amount: '',
        remark: '',
      },
    ]
  }

  const spILTSReportData = _map(spIltsreport, (report, key) => {
    if (key === 0)
      return report
    if (report.ClientCode) delete report.ClientCode
    return report
  })

  return (
    <>
      <PageHeader
        className='box button-contents'
        title='Client Position'
        onBack={() => history.goBack()}
        extra={[
          <Button
            type='primary'
            onClick={() => history.push(`/clients/${clientId}/position/${clientCode}/${clientId}/comparison-of-fund/${clientCode}`)}
          >
            View comparison
          </Button>,
          <Button
            type='primary'
            style={{
              paddingLeft: 15
            }}
            loading={sendMeLoading}
            onClick={() => {
              setSendMeLoading(true)
              sendMePdf('ILTS-REPORT-PDF')
                .then(() => setSendMeLoading(false))
            }}
          >
            Send Me
          </Button>,
          <Select
            value={strategy ? strategy : ''}
            style={{ width: 80 }}
            className='gx-mr-3 gx-select-sm'
            onChange={(e) => setStrategy(e)}
            loading={!strategyList}
          >
            {
              _map(strategyList, (strategy, key) => {
                const { StrategyName, StrategyCode } = strategy
                return <Option value={StrategyCode} key={key}>{StrategyName}</Option>
              })
            }
          </Select>,
          <DownloadReport data={spILTSReportData} fileName='ILTS' id='ILTS-REPORT-PDF' />,
        ]}
      />
      {
        loading ?
          <Loader />
          :
          error ?
            <Page404 error={error} />
            :
            (!_get(data, 'spIltsreport') || !_first(spIltsreport)) ?
              <NoData />
              :
              <div className='report-table-wrapper' id='ILTS-REPORT-PDF'>
                <div id='ILTS-PDF-Client'>
                  {/* <DownloadReport className="ExportAS" data={[_first(spIltsreport)]} fileName='ILTS' id='ILTS-PDF-Client' /> */}
                  <ILTS
                    clientId={clientId}
                    reportData={_first(spIltsreport)}
                    strategy={strategyList && strategy && strategyList.find((s) => s.StrategyCode === strategy).StrategyCode}
                    ledgerReport={ledgerReport}
                  />
                </div>
                <br />
                <br />
                <div id='ILTS-PDF-Stock'>
                  <DownloadReport className="ExportAS" onlyCSV data={stockReport} fileName='ILTS-stock' id='ILTS-PDF-Stock' />
                  <Stock reportData={stockReport} />
                </div>
                <br />
                <br />
                <div id='ILTS-PDF-Futures'>
                  <DownloadReport className="ExportAS" onlyCSV data={futuresOptionsReport} fileName='ILTS-futures-options' id='ILTS-PDF-Futures' />
                  <OpenPosition reportData={futuresOptionsReport}
                  />
                </div>
                <br />
                <br />
                <div id='ILTS-PDF-Ledger'>
                  <DownloadReport className="ExportAS" data={ledgerReport} fileName='ILTS-ledger' id='ILTS-PDF-Ledger' />
                  <Ledger reportData={ledgerReport} clientData={_first(spIltsreport)} />
                </div>
              </div>
      }
    </>
  )
}

import gql from 'graphql-tag'
export const LOGIN = gql`
mutation login($data: LoginCreateInput!){
  login(data: $data) {
    token
  }
}
`

export const RESET_PASSWORD = gql`
mutation resetPassword($data: ResetPasswordInput){
  resetPassword(data: $data)
}
`

export const LOGIN_SIGNUP_WITH_GOOGLE = gql`
mutation loginSignupWithGoogle{
  loginSignupWithGoogle
}
`

export const SEND_SIGNUP_OTP = gql`
mutation sendSignUpOtp($where: SignUpEmailWhereUniqueInput!){
  sendSignUpOtp(where: $where)
}
`

export const SEND_LOGIN_OTP = gql`
mutation sendLoginOtp($id: ID!){
  sendLoginOtp(id: $id)
}
`
export const LOGIN_WITH_OTP = gql`
mutation loginWithOtp($otp: String!){
  loginWithOtp(otp: $otp){
    token
  }
}
`

export const RESEND_OTP = gql`
mutation resendOtp($where: SignUpEmailWhereUniqueInput!){
  resendOtp(where: $where)
}
`

import gql from 'graphql-tag'

export const UPDATE_ACCESS_SETTING = gql`
mutation updateAccessSetting($data: AccessSettingUpdateInput!, $where: AccessSettingWhereInput!){
  updateAccessSetting(data: $data, where: $where) {
    id
  }
}
`

export const UPDATE_SITE_SETTING = gql`
mutation updateSiteSetting($data: SiteSettingUpdateInput!, $where: SiteSettingWhereInput!){
 updateSiteSetting(data: $data, where: $where){
  id
  url
  status
}
}
`

export const UPDATE_SYSTEM_ALERT = gql`
mutation updateSystemAlert($data:SystemAlertUpdateInput!,$where:SystemAlertWhereUniqueInput!){
  updateSystemAlert(data:$data,where:$where){
    id
    message
    isActive
    type
    createdAt
    updatedAt
  }
}
`

export const USER_FIELD_SELECTION = gql`
mutation userFieldSelection($data:FieldSelectionUpdateInput){
  userFieldSelection(data:$data)
  {
    id
    role
    firstName
    lastName
    userName
    completedSteps
    clientCode
    email
    phone
    remark
  }
}
`

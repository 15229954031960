import gql from 'graphql-tag'

export const CREATE_NOTIFICATION = gql`
mutation createScheduleNotification($data: scheduleNotificationsCreateInput!){
  createScheduleNotification(data: $data) {
    id
  }
}
`

export const UPDATE_NOTIFICATION = gql`
mutation updateScheduleNotification($data: ScheduleNotificationUpdateInput!, $id: Int!){
  updateScheduleNotification(data: $data, where: { id: $id }) {
    id
  }
}
`

export const DELETE_NOTIFICATION = gql`
mutation deleteScheduleNotification($id: Int!){
  deleteScheduleNotification(where: { id: $id }) {
    id
  }
}
`

export const SEND_GROUP_NOTIFICATION = gql`
mutation sendGroupNotification($data: SendGroupNotificationInput!){
  sendGroupNotification(data: $data)
}
`

import React, { Fragment, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { PageHeader } from 'antd'
import get from 'lodash/get'
import moment from 'moment'

import client from 'apollo'
import { getName } from 'utils/User'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import UserForm from './components/UserForm'
import Loader from 'components/loaders/Loader'
import CustomMeta from 'components/CustomMeta'
import openNotification from 'utils/Notification'
import { GET_USER } from '../Users/graphql/Queries'
import { GET_CURRENT_USER } from 'auth/graphql/Queries'
import { UPDATE_USER } from '../Users/graphql/Mutations'

export default function (props) {
  const { state, dispatch } = useContext(AppContext)

  let initialValues = {}
  let {
    history,
    match: {
      path,
      params: { userId },
    },
  } = props
  const isProfilePage = path.includes('profile')
  userId = userId || state.currentUser.id
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
  })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />

  if (!loading && get(data, 'getUser')) {
    initialValues = JSON.parse(JSON.stringify(data.getUser))
    initialValues.dateOfBirth = initialValues.dateOfBirth
      ? moment(initialValues.dateOfBirth)
      : undefined
    initialValues.amcStartDate = initialValues.amcStartDate
      ? moment(initialValues.amcStartDate)
      : undefined
    initialValues.sipStartDate = initialValues.sipStartDate
      ? moment(initialValues.sipStartDate)
      : undefined
    initialValues.closedDate = initialValues.closedDate
      ? moment(initialValues.closedDate)
      : undefined
    initialValues.createdById = initialValues.createdBy
      ? initialValues.createdBy.id
      : undefined
  }

  function onFinish(values) {
    values = {
      ...values,
      firstName:
        values.firstName.charAt(0).toUpperCase() + values.firstName.slice(1),
      lastName:
        values.lastName.charAt(0).toUpperCase() + values.lastName.slice(1),
      email: values.email.toLowerCase(),
    }
    const queryVariables = { id: userId, data: values }
    client
      .mutate({ mutation: UPDATE_USER, variables: queryVariables })
      .then((res) => {
        openNotification('success', 'User Updated Successfully')
        if (isProfilePage || userId === state.currentUser.id) {
          client
            .query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
            .then((res) =>
              dispatch({ type: 'CURRENT_USER', data: res.data.currentUser })
            )
            .catch((err) => console.log(err))
        }
        history.goBack()
      })
      .catch((err) => console.log(err))
  }

  return (
    <Fragment>
      <CustomMeta title={getName(initialValues)} description='Edit User' />
      <PageHeader
        className='box'
        onBack={() => history.goBack()}
        title={isProfilePage ? 'Edit Details' : 'Edit User'}
      />
      <UserForm
        onFinish={onFinish}
        initialValues={initialValues}
        loading={loading}
        isUpdate
        isProfilePage={isProfilePage || state.currentUser.id === userId}
      />
    </Fragment>
  )
}

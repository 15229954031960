import { Button, Checkbox, Spin } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import Page404 from 'components/Page404'
import { get } from 'lodash'
import { GET_KYC } from './graphql/Queries'
import { AppContext } from 'AppContext'
import client from 'apollo'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import openNotification from 'utils/Notification'
import { LeftOutlined, RightOutlined, DownloadOutlined } from '@ant-design/icons'
import { KYC_FILE_UPLOAD } from 'modules/Users/graphql/Queries'
import CustomMeta from 'components/CustomMeta'
import { COMPLETE_STEP } from './graphql/Mutations'

export default function Step5({ next }) {
  const [pdfLoading, setPdfLoading] = useState(true)
  const [loadings, setLoadings] = useState(false)
  const [agree, setAgree] = useState(false)
  const { dispatch, state: { currentUser } } = useContext(AppContext)
  const { id, email } = currentUser

  const { data, loading, error } = useQuery(GET_KYC, { variables: { userId: parseInt(id) }, fetchPolicy: 'network-only' })

  useEffect(() => {
    if (!loading && get(data, 'kyc')) {
      const defaultPdf = data.kyc.kycForm
      var url = defaultPdf
      var pdfjsLib = window['pdfjs-dist/build/pdf']
      pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js'

      var pdfDoc = null,
        pageNum = 1,
        pageRendering = false,
        pageNumPending = null,
        scale = 0.8,
        canvas = document.getElementById('the-canvas'),
        ctx = canvas.getContext('2d')

      /**
       * @param num Page number.
       */
      function renderPage(num) {
        // pageRendering = true;
        // Using promise to fetch the page
        pdfDoc.getPage(num).then(function (page) {
          var viewport = page.getViewport({ scale: scale })
          canvas.height = viewport.height
          canvas.width = viewport.width

          var renderContext = {
            canvasContext: ctx,
            viewport: viewport
          }
          var renderTask = page.render(renderContext)

          renderTask.promise.then(function () {
            pageRendering = false
            if (pageNumPending !== null) {
              // New page rendering is pending
              renderPage(pageNumPending)
              pageNumPending = null
            }
          })
        })

        document.getElementById('page_num').textContent = num
      }

      function queueRenderPage(num) {
        if (pageRendering) {
          pageNumPending = num
        } else {
          renderPage(num)
        }
      }

      function onPrevPage() {
        if (pageNum <= 1) {
          return
        }
        pageNum--
        queueRenderPage(pageNum)
      }
      document.getElementById('prev').addEventListener('click', onPrevPage)

      function onNextPage() {
        if (pageNum >= pdfDoc.numPages) {
          return
        }
        pageNum++
        queueRenderPage(pageNum)
      }
      document.getElementById('next').addEventListener('click', onNextPage)

      pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
        pdfDoc = pdfDoc_
        document.getElementById('page_count').textContent = pdfDoc.numPages
        setPdfLoading(false)
        renderPage(pageNum)
      })
    }
  }, [data, loading])

  if (error) return <Page404 error={error} />

  const submitPdf = async () => {
    setLoadings(true)
    client.query({ query: KYC_FILE_UPLOAD, fetchPolicy: 'network-only' })
      .then((response) => {
        if (!get(response, 'data.kycFileUpload')) {
          openNotification('error', 'Something went wrong!')
          setLoadings(false)
          return false
        }
        const options = {
          environment: process.env.REACT_APP_DIGIO_ENVIRONMENT,
          callback: function (response) {
            if (response.hasOwnProperty("error_code")) {
              setLoadings(false)
              return console.log("error occurred in process")
            }
            client.mutate({
              mutation: UPDATE_USER, variables: {
                id: id, data: {
                  completedSteps: 'KYC_FORM'
                }
              }
            })
              .then((res) => {
                client.mutate({
                  mutation: COMPLETE_STEP, variables: {
                    data: {
                      step: 'KYC_FORM',
                      from: 'WEB'
                    }
                  }
                })
                dispatch({
                  type: 'CURRENT_USER',
                  data: res.data.updateUser
                })
                openNotification('success', 'Successfully Complete eSign on Kyc E-Sign')
                setLoadings(false)
                next()
              })
          },
          logo: "https://www.mylogourl.com/image.jpeg",
          theme: {
            primaryColor: "#AB3498",
            secondaryColor: "#000000"
          }
        }
        const digio = new window.Digio(options)
        digio.init()
        digio.submit(response.data.kycFileUpload.id, email)
      })
      .catch((err) => {
        console.log(err)
        setLoadings(false)
      })
  }

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  return (
    <>
      <h1>Kyc E-Sign</h1>
      <CustomMeta title='Kyc Form' />
      <div className="modal">
        <div className="modalContent">
          <div className="pdf-btn" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ textAlign: ' left' }}>
              <Button id="prev" shape="circle" icon={<LeftOutlined />} />
              <Button id="next" shape="circle" icon={<RightOutlined />} />
              <span>Page: <span id="page_num"></span> / <span id="page_count"></span></span>
            </div>
            <div>
              <Button type="primary" shape="round" icon={<DownloadOutlined />} onClick={() => window.open(data?.kyc?.kycForm)}>
                Download PDF
              </Button>
            </div>
          </div>
          <div id="parent" >
            {pdfLoading && < Spin size="large" />}
            <div>
              <canvas id="the-canvas" className="canvas" width="512px" height="512px"></canvas>
            </div>
          </div>
          <div style={{ marginTop: '15px' }}>
            <div className='agree-wrapper'>
              <Checkbox onChange={handleAgree} />
              <p className='agree-content'>
                I/We hereby declare that the details furnished above are true and correct to the best of my/our knowledge and belief and
                I/We undertake to inform you of any changes therein, immediately.
                In case any of the above information is found to be false or untrue or misleading or misrepresenting,
                I am/We are aware that I/We may be held liable for it.
              </p>
            </div>
            <Button type="primary" loading={loadings} onClick={() => submitPdf()} disabled={!agree}>
              Verify KYC Form
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useContext } from 'react'
import { Table } from 'antd'
import { getName } from 'utils/User'
import { AppContext } from 'AppContext'


export default function ({ reportData }) {
  const { state: { currentUser, currentUser: { clientCode } } } = useContext(AppContext)

  const columns = [
    {
      title: 'Interest Report',
      className: 'report-title main-title',
      children: [
        {
          title: <div className='hidden-client-data'>Client Code: <span className='hidden-client-code'>{clientCode}</span> </div>,
          className: 'hidden-client-data-th ',
          children: [
            {
              title: 'Monthmmm',
              dataIndex: 'Mnth',
              key: 'Mnth',
              width: '20%',
              className: 'report-numeric interest-column-align',
              align: 'right',
            },
            {
              title: 'Exposure',
              dataIndex: 'CumExposure',
              key: 'CumExposure',
              width: '25%',
              className: 'report-numeric interest-column-align'
            },
          ],
        },
        {
          title: <div className='hidden-client-data' >Client Name: <span className='hidden-client-code'>{getName(currentUser)}</span></div>,
          className: 'hidden-client-data-th ',
          children: [
            {
              title: 'Payment',
              dataIndex: 'CumDPayment',
              key: 'CumDPayment',
              width: '15%',
              className: 'report-numeric interest-column-align',
            },
            {
              title: 'Net Exposure',
              dataIndex: 'TotExposure',
              key: 'TotExposure',
              width: '20%',
              className: 'report-numeric interest-column-align'
            },
            {
              title: 'Interest',
              dataIndex: 'Interest',
              key: 'Interest',
              width: '20%',
              className: 'report-numeric interest-column-align'
            }
          ],
        },
      ]
    }
  ]
  const data = reportData.map((report, key) => {
    const round = { CumExposure: Math.round(report.CumExposure), CumDPayment: Math.round(report.CumDPayment), TotExposure: Math.round(report.TotExposure), Interest: Math.round(report.Interest) }
    if ((key + 1) === reportData.length)
      return { key, ...report, Mnth: report.Type, ...round }
    return { key, ...report, ...round }
  })

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      className='interest-report'
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
    />
  )
}

import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

export default function ({ reportData, columns, loading }) {
  const data = reportData.map((report, key) => {
    return { key, ...report, Entrydate: moment(report.Entrydate).format('DD-MMM-YYYY') }
  })

  return (
    <Table
      loading={loading}
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={{ pageSize: 10000, hideOnSinglePage: true }}
    // scroll={{ x: true, y: 'calc(100vh - 72px - 24px - 24px - 36px - 35px)' }}
    />
  )
}

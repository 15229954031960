import React, { useContext } from 'react'
import { Row, Col } from 'antd'
import {
  MobileOutlined,
  MailOutlined,
  WarningOutlined
} from '@ant-design/icons';
import SupportIcon from "../../../src/assets/images/support.png";
import { AppContext } from 'AppContext';
import '../Support/Support.css';

export default function Details() {
  const { state: { currentUser } } = useContext(AppContext)

  return (
    <div className='support'>
      <Row>
        <Col span={12}>
          <div className="elementor-head-text elementor-element elementor-element-63e5161 elementor-widget elementor-widget-heading" data-id="63e5161" data-element_type="widget" data-widget_type="heading.default">
            <img className="support-img" alt="img" src={SupportIcon} />
          </div>
        </Col>

        <Col span={12} className="divider">
          {/* <div style={{ border: "1px solid #013365", width: "30%", margin: "0 auto" }} class="elementor-divider">
            <span class="elementor-divider-separator">
            </span>
          </div> */}
          {!currentUser.createdBy ?
            (
              <div className="rm-text" >
                <WarningOutlined className="rm-icon" />
                <p className="rm-text-desc" >
                  Your RM is not assigned, <br />  please contact to administrator
                </p>
              </div>
            )
            : (
              <>
                <div class="elementor-element elementor-element-63e5161 elementor-widget elementor-widget-heading elementor-text-center" data-id="63e5161" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default elementor-heading-text-rm">Your RM is</h4>
                    <h2 className="elementor-heading-title elementor-size-default elementor-heading-text">
                      {
                        currentUser?.createdBy && currentUser?.createdBy.firstName + ' ' + currentUser?.createdBy.lastName
                      }
                    </h2>
                  </div>
                </div>
                <div className="support-phone">
                  <h1 className="support-phone-text">
                    <MobileOutlined /> {currentUser?.createdBy && currentUser.createdBy.phone ? currentUser.createdBy.phone : '-'}
                    <br />
                    <MailOutlined /> {currentUser?.createdBy && currentUser.createdBy.email ? currentUser.createdBy.email : '-'}
                  </h1>
                </div>
              </>
            )}
          {/* <div style={{ border: "1px solid #013365", width: "30%", margin: "0 auto", marginBottom: "20px" }} className="elementor-divider">
            <span className="elementor-divider-separator">
            </span>
          </div> */}
          <div className="elementor-text-editor elementor-clearfix">
            {/* <h4 style={{ textAlign: "center", fontSize: "20px" }}>
              Your RM is <strong style={{ textAlign: "center", fontSize: "20px" }}>Preet Patel !</strong>
            </h4> */}
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <hr className="hr-divider" />
      <br />
      <Row>
        <Col span={8}>
          <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-46655265" data-id="46655265" data-element_type="column">
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div className="elementor-element elementor-element-62e8b76d elementor-widget elementor-widget-heading" data-id="62e8b76d" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h6 className="elementor-heading-title elementor-size-default elementor-location">Location</h6>
                  </div>
                </div>
                <div className="elementor-element elementor-element-1a68ab6d elementor-widget elementor-widget-text-editor" data-id="1a68ab6d" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix elementor-address">
                      <p >1002, Luxuria Business Hub,<br />Near VR Mall, Gaurav Path Road,<br />Surat – 395007</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-c65259f" data-id="c65259f" data-element_type="column">
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div className="elementor-element elementor-element-75a3f45c elementor-widget elementor-widget-heading" data-id="75a3f45c" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h6 className="elementor-heading-title elementor-size-default elementor-working-hours">Working hours</h6>
                  </div>
                </div>
                <div className="elementor-element elementor-element-4deb7dc7 elementor-widget elementor-widget-text-editor" data-id="4deb7dc7" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix">
                      <p className="elementor-time">9:00 AM – 6.00 PM (IST)<br />Monday – Friday</p>
                      <p className="elementor-date">10:00 AM – 4.00 PM (IST)<br />Saturday</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-686d8bf0" data-id="686d8bf0" data-element_type="column">
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div className="elementor-element elementor-element-1a740010 elementor-widget elementor-widget-heading" data-id="1a740010" data-element_type="widget" data-widget_type="heading.default">
                  <div className="elementor-widget-container">
                    <h6 className="elementor-heading-title elementor-size-default elementor-contact-us">Connect us</h6>		</div>
                </div>
                <div className="elementor-element elementor-element-621fa52 elementor-widget elementor-widget-text-editor" data-id="621fa52" data-element_type="widget" data-widget_type="text-editor.default">
                  <div className="elementor-widget-container">
                    <div className="elementor-text-editor elementor-clearfix elementor-email-section">
                      Email: <a href="mailto:info@finideas.com">info@finideas.com</a><br />
                      Call: <a href="tel:+919374985600">+91 93749 85600</a>					</div>
                  </div>
                </div>
                <div className="elementor-element elementor-element-7aeea744 elementor-shape-rounded elementor-grid-0 e-grid-align-center elementor-widget elementor-widget-social-icons" data-id="7aeea744" data-element_type="widget" data-widget_type="social-icons.default">
                  <div className="elementor-widget-container">
                    <div className="elementor-social-icons-wrapper elementor-grid">
                      <span className="elementor-grid-item">
                        <a className="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-animation-grow elementor-repeater-item-9c86cde" href="https://www.facebook.com/finideas" target="_blank" rel="noopener noreferrer">
                          <span className="elementor-screen-only">Facebook-f</span>
                          <i className="fab fa-facebook-f"></i>					</a>
                      </span>
                      <span className="elementor-grid-item">
                        <a className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-animation-grow elementor-repeater-item-d9a9500" href="https://www.instagram.com/finideas/" target="_blank" rel="noopener noreferrer">
                          <span className="elementor-screen-only">Instagram</span>
                          <i className="fab fa-instagram"></i>					</a>
                      </span>
                      <span className="elementor-grid-item">
                        <a className="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-animation-grow elementor-repeater-item-75dde21" href="https://youtube.com/finideas" target="_blank" rel="noopener noreferrer">
                          <span className="elementor-screen-only">Youtube</span>
                          <i className="fab fa-youtube"></i>					</a>
                      </span>
                      <span className="elementor-grid-item">
                        <a className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-animation-grow elementor-repeater-item-7f0e5c7" href="http://twitter.com/finideas" target="_blank" rel="noopener noreferrer">
                          <span className="elementor-screen-only">Twitter</span>
                          <i className="fab fa-twitter"></i>					</a>
                      </span>
                      <span className="elementor-grid-item">
                        <a className="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-animation-grow elementor-repeater-item-1b67768" href="https://www.linkedin.com/company/26500644/admin/" target="_blank" rel="noopener noreferrer">
                          <span className="elementor-screen-only">Linkedin</span>
                          <i className="fab fa-linkedin"></i>					</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div >
  )
}

import React, { useContext } from 'react'
import { Steps } from 'antd'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { UserAddOutlined } from '@ant-design/icons'

import { getName } from 'utils/User'
import Widget from 'components/Widget'
import { AppContext } from 'AppContext'
import { GET_LEAD_USERS } from 'modules/Users/graphql/Queries'

const { Step } = Steps

export default function LeadUsersChain({ userId }) {
  const { state: { isAssociate } } = useContext(AppContext)
  const { data, loading } = useQuery(GET_LEAD_USERS, { variables: { userId: parseInt(userId) }, fetchPolicy: 'network-only' })

  if (loading || !_get(data, 'getLeadUsers.length')) return <></>

  const leadUsers = _sortBy(_get(data, 'getLeadUsers'), ['level'])

  return (
    <Widget>
      <Steps type="navigation" current={_get(data, 'getLeadUsers.length')} className="lead-users-steps">
        {
          _map(leadUsers, (user, key) => {
            const { role } = user
            return (
              <Step
                key={key}
                icon={<UserAddOutlined />}
                description={role.replace('_', ' ')}
                title={isAssociate ? getName(user) : <NavLink to={`/users/${user.id}`}>{getName(user)}</NavLink>}
              />
            )
          })
        }
      </Steps>
    </Widget>
  )
}

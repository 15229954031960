import gql from 'graphql-tag'

export const GET_ALL_ACCESS_SETTINGS = gql`
query getAllAccessSettings{
  getAllAccessSettings {
    id
    title
    key
    admin
    staff
    client
    associate
    salesExecutive
    masterAssociate
    createdAt
    updatedAt
  }
}
`

export const GET_STRATEGY_LIST = gql`
query getStrategyList{
  getStrategyList
}
`
export const GET_STRATEGY = gql`
query getStrategy($where: GetStrategyWhereUniqueInput!){
  getStrategy(where: $where)
}
`

export const GET_SITE_SETTING = gql`
query getSiteSettings{
  getSiteSettings{
    id
    url
    status
  }
}
`

export const GET_AGREEMENT_DATA_HTML = gql`
query{
getAgreementDataHtml
}
`

export const POST_AGREEMENT_DATA_HTML = gql`
query getAgreementDataHtmlFromFrontEnd($data: AgreementDataHtmlInput!){
  getAgreementDataHtmlFromFrontEnd(data:$data)
}
`

export const GENERATE_PDF_AGREEMENT = gql`
query generatePDF($data: generatePDFInput!){
  generatePDF(data: $data)
}
`

export const GET_SYSTEM_ALERT = gql`
query getSystemAlert{
  getSystemAlert{
    id
    message
    isActive
    type
    createdAt
    updatedAt
  }
}
`

import React, { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Modal, Form, Input, Select, Upload, Button } from 'antd'

const { Option } = Select

const CollectionCreateForm = ({ visible, onCreate, onCancel, loading }) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState(undefined)

  function resetForm() {
    form.resetFields()
  }

  const subjects = [
    { text: 'Report', value: 'Report' },
    { text: 'Staff', value: 'Staff' },
    { text: 'Technical', value: 'Technical' },
    { text: 'Others', value: 'Others' },
  ]

  const normFile = e => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: file => setFileList([]),
    beforeUpload: file => {
      setFileList(file)
      return false
    },
    fileList
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={'Generate Ticket'}
      okText={'Generate'}
      okButtonProps={{
        loading
      }}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => onCreate(values, resetForm))
          .catch(info => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public'
        }}
      >
        <Form.Item
          name='subject'
          label='Choose Subject'
          rules={[{ required: true, message: 'Please choose your Subject!', whitespace: true }]}
        >
          <Select placeholder='Choose Subject'>
            {subjects.map((subject) => <Option key={subject.value} value={subject.value}>{subject.text}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          name='description'
          label='Description'
          rules={[{ required: true, message: 'Please write your description!', whitespace: true }]}
          hasFeedback
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          name='fileUrl'
          label='Attachment'
          valuePropName='fileList'
          getValueFromEvent={normFile}
        >
          <Upload name='attachment' listType='text' {...customProps} accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'>
            <Button><UploadOutlined /> Click to upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateTicketModal = (props) => {
  return (
    <div>
      <CollectionCreateForm
        {...props}
      />
    </div>
  )
}

export default CreateTicketModal

import gql from 'graphql-tag'

export const GET_USERS_FOR_SELECT = gql`
  query getUsers(
    $where: UserCreateWhereInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getUsers(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      users {
        id
        firstName
        lastName
        email
        userName
        clientCode
      }
      count
    }
  }
`
export const GET_MEMBER_NAME = gql`
  query getMemberName {
    getMemberName
  }
`

export const USER_PUBLIC = gql`
  query {
    getUsersPublic {
      users {
        id
        firstName
        lastName
        role
        clientCode
      }
      count
    }
  }
`

export const GET_USERS = gql`
  query getUsers(
    $where: UserCreateWhereInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getUsers(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      users {
        id
        firstName
        lastName
        email
        phone
        address
        registrationDate
        clientScore
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        role
        ratio
        keyId
        isAuto
        isAssociate
        exposure
        fundReceived
        initialExposure
        isKycDone
        completedSteps
        amcStartDate
        amcAmount
        sipStartDate
        sipAmount
        closedDate
        clientCode
        strategyCode
        token
        createdAt
        updatedAt
        annualIncome
        netAvailableExposure
        initialExposure
        hierarchy
        amcReceivedDate
        amcInPer
        amcInvoiceNo
        amcRecentAmount
        disableDate
        panCardNo
        remark
        collateralValue
        clientConsentTime
        sourceOfLead
        associateIntroducer
        invoiceStatus
        riskProfileType
        discountStatus
        initialExposure
        agreementDataMailSent
        amcRecentAmount
        amcInvoiceNo
        netAvailableExposure
        hierarchy
        amcReceivedDate
        plan {
          id
          name
          description
        }
        kyc {
          id
          incomeProof
        }
        createdBy {
          id
        }
        updatedBy {
          id
        }
      }
      count
    }
  }
`

export const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(where: { id: $id }) {
      id
      firstName
      lastName
      email
      phone
      address
      cityId
      consentData
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      ratio
      keyId
      isAuto
      isAssociate
      exposure
      registrationDate
      investmentDate
      amcInPer
      sourceOfLead
      associateIntroducer
      invoiceStatus
      riskProfileType
      discountStatus

      fundReceived
      initialExposure
      isKycDone
      completedSteps
      memberName
      amcStartDate
      amcAmount
      sipStartDate
      sipAmount
      closedDate
      clientCode
      strategyCode
      token
      disableDate
      isDisableDate
      createdAt
      annualIncome
      mandateId
      plan {
        id
        name
        description
      }
      remark
      collateralValue
      updatedAt
      kyc {
        id
        panCard
        aadharCard
        passportSize
        signature
        cancelledChequeSnap
        bankStatement
      }
      createdBy {
        id
      }
      updatedBy {
        id
      }
    }
  }
`

export const GET_CLIENT_USERS = gql`
  query getClientUsers($where: UserWhereUniqueInput!, $skip: Int, $limit: Int) {
    getClientUsers(where: $where, skip: $skip, limit: $limit) {
      users {
        id
        firstName
        lastName
        email
        phone
        address
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        role
        ratio
        keyId
        isAuto
        isAssociate
        exposure
        fundReceived
        initialExposure
        isKycDone
        completedSteps
        amcStartDate
        amcAmount
        sipStartDate
        sipAmount
        closedDate
        clientCode
        strategyCode
        token
        createdAt
        updatedAt
        plan {
          id
          name
          description
        }
        createdBy {
          id
        }
        updatedBy {
          id
        }
      }
      count
    }
  }
`

export const GET_SYSTEM_USERS = gql`
  query getSystemUsers($where: UserWhereUniqueInput!, $skip: Int, $limit: Int) {
    getSystemUsers(where: $where, skip: $skip, limit: $limit) {
      users {
        id
        firstName
        lastName
        email
        phone
        address
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        role
        ratio
        keyId
        isAuto
        isAssociate
        exposure
        fundReceived
        initialExposure
        isKycDone
        completedSteps
        amcStartDate
        amcAmount
        sipStartDate
        sipAmount
        closedDate
        clientCode
        strategyCode
        token
        createdAt
        updatedAt
        createdBy {
          id
        }
        updatedBy {
          id
        }
      }
      count
    }
  }
`

export const GET_REVENUE_INCOME = gql`
  query getRevenueIncome(
    $skip: Int
    $limit: Int
    $sort: SortInput
    $where: RevenueIncomeWhereUniqueInput
  ) {
    getRevenueIncome(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      RevenueIncome {
        id
        user {
          id
          firstName
          lastName
        }
        client {
          id
          firstName
          lastName
          clientCode
          createdBy {
            id
            firstName
            lastName
            role
          }
        }
        amc {
          id
          amount
        }
        description
        commissionRatio
        commissionAmount
        createdAt
        updatedAt
      }
      counter
      commissionAmountTotal
      commissionRatioTotal
      amcAmountTotal
    }
  }
`

export const GET_ALL_AMC = gql`
  query getAllAmcs(
    $skip: Int
    $limit: Int
    $sort: SortInput
    $where: AmcWhereUniqueInput!
  ) {
    getAllAmcs(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      amc {
        id
        status
        amount
        whichYear
        paymentType
        description
        amcQty
        amcPaidForPeriod
        nextDueDate
        paidAt
        startDate
        endDate
        createdAt
        updatedAt
        invoiceNo
        client {
          id
          firstName
          lastName
          role
          clientCode
          amcAmount
          createdBy {
            id
            firstName
            lastName
            role
          }
        }
      }
      count
      total
    }
  }
`

export const GET_AMC = gql`
  query getAllAmcs(
    $clientId: [String]
    $createdById: [String]
    $whichYear: Int
    $skip: Int
    $limit: Int
    $sort: SortInput
    $startDateFrom: DateTime
    $startDateTo: DateTime
    $paidAtFrom: DateTime
    $paidAtTo: DateTime
    $nextDueDateFrom: DateTime
    $nextDueDateTo: DateTime
    $clientCodeSearch: String
  ) {
    getAllAmcs(
      where: {
        clientId: $clientId
        createdById: $createdById
        whichYear: $whichYear
        startDateFrom: $startDateFrom
        startDateTo: $startDateTo
        paidAtFrom: $paidAtFrom
        paidAtTo: $paidAtTo
        nextDueDateFrom: $nextDueDateFrom
        nextDueDateTo: $nextDueDateTo
        clientCodeSearch: $clientCodeSearch
      }
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      amc {
        id
        status
        amount
        whichYear
        paymentType
        description
        amcQty
        amcPaidForPeriod
        nextDueDate
        paidAt
        startDate
        endDate
        createdAt
        updatedAt
        invoiceNo
        client {
          id
          firstName
          lastName
          role
          clientCode
          amcAmount
          createdBy {
            id
            firstName
            lastName
            role
          }
        }
      }
      count
      total
    }
  }
`
export const GET_SIP = gql`
  query getAllSips(
    $clientId: Int
    $whichMonth: String
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getAllSips(
      where: { clientId: $clientId, whichMonth: $whichMonth }
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      sip {
        id
        status
        amount
        whichMonth
        paymentType
        description
        createdAt
        updatedAt
        client {
          id
          firstName
          lastName
        }
      }
      count
    }
  }
`

export const GET_LEAD_USERS = gql`
  query getLeadUsers($userId: Int!) {
    getLeadUsers(where: { userId: $userId })
  }
`

export const GET_USER_REVENUE_BY_CLIENT = gql`
  query getUserRevenueByClient($clientId: Int!) {
    getUserRevenueByClient(where: { clientId: $clientId }) {
      id
      client {
        id
      }
      year
      ratio
      level
      chainMember {
        id
      }
      createdAt
      updatedAt
    }
  }
`
export const GET_USERS_FOR_CLIENT = gql`
  query getUsersForClient {
    getUsersForClient {
      count
      users {
        id
        firstName
        lastName
      }
    }
  }
`

export const KYC_FILE_UPLOAD = gql`
  query kycFileUpload {
    kycFileUpload
  }
`
export const AGREEMENT_DATA_FILE_UPLOAD = gql`
  query agreementDataFileUpload {
    agreementDataFileUpload
  }
`
export const E_MANDATE_BANK_DATA = gql`
  query eMandateBankData {
    eMandateBankData
  }
`
export const E_MANDATE = gql`
  query eMandate($data: EMandateInput) {
    eMandate(data: $data)
  }
`

export const CHECK_EXIST_CLIENT_CODE = gql`
  query checkExistClientCode($where: ClientCodeExistUniqueInput!) {
    checkExistClientCode(where: $where)
  }
`

export const GET_ALL_CLIENT = gql`
  query getClientUsers($where: UserWhereUniqueInput!, $skip: Int, $limit: Int) {
    getClientUsers(where: $where, skip: $skip, limit: $limit) {
      users {
        id
        firstName
        lastName
        clientCode
      }
      count
    }
  }
`

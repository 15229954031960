import React from 'react'
import { get } from 'lodash'
import { useQuery } from '@apollo/react-hooks'

import { GET_ADVISORY_DATA } from './graphql/Queries'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import PendingAdvisoryTable from './PendingAdvisoryTable'


export default function PendingAdvisory() {
  const { data, loading, error, refetch } = useQuery(GET_ADVISORY_DATA)

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'getAdvisoryData')) return <NoData />

  return (
    <div>
      <PendingAdvisoryTable advisoryData={data.getAdvisoryData} refetch={refetch} />
    </div>
  )
}

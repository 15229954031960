import gql from 'graphql-tag'

export const GET_SECTIONS = gql`
query getSections($planId:ID!){
  getSections(where:{planId:$planId}){
    id
    section
    index
    questions {
      id
      question
      index
      type
      isRequired
      point
      options {
        id
        option
        point
        isDefault
      }
    }
  }
}
`

export const GET_QUESTIONS = gql`
  query getQuestions {
  getQuestions {
    id
    question
    index
    type
    point
    options {
      id
      option
      point
      isDefault
    }
  }
}
`
export const GET_PLANS_BASED_POINTS = gql`
  query fetchPlansBasedOnPoints($points:Int) {
    fetchPlansBasedOnPoints(where:{points:$points}){
    id
    name
    }
}
`


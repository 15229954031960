import get from 'lodash/get'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Input,
} from 'antd'

import '../index.css'
import { getName } from 'utils/User'
import Page404 from 'components/Page404'
import IltsShiftTable from './IltsShiftTable'
import DownloadReport from '../DownloadReport'
import { GET_ILTS_SHIFT, GET_NIFTY } from '../graphql/Queries'
import {
  GET_ALL_CLIENT,
  GET_USERS_FOR_SELECT,
} from 'modules/Users/graphql/Queries'
import Loader from 'components/loaders/Loader'
import moment from 'moment'
import client from 'apollo'
import { AppContext } from 'AppContext'
import { orderBy } from 'lodash'

const { Option } = Select

export default function IltsShift() {
  const [form] = Form.useForm()
  let [usersData, setUsersData] = useState([])
  const [userLoadingClient, setUserLoading] = useState(true)
  const [filters, setFilters] = useState({
    // where: {
    // clientCode: "STR09",
    // analysisLevel: 16000,
    // analysisExp: "2022-12-29T10:55:59.130Z",
    // final: "2022-12-29T10:56:05.702Z",
    // step: 1000,
    // intRate: 0.9,
    // round: 1
    // }
  })

  console.log(filters, 'filters')
  const {
    state: { currentUser, isClient },
  } = useContext(AppContext)

  let year = moment().format('YYYY')

  useEffect(() => {
    setFilters({
      where: {
        clientCode: !isClient ? '' : `${currentUser?.clientCode}`,
        analysisLevel: 19000,
        analysisExp: '2022-12-29T00:00:00.000Z',
        final: '2022-12-28T00:00:00.000Z',
        step: 3000,
        intRate: 0.05,
        round: 1000,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    client
      .query({
        query: GET_ALL_CLIENT,
        variables: {
          where: {},
          limit: 10000,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        res.data.getClientUsers.users.map((user) => {
          return usersData.push(user)
        })
        setUsersData(usersData)
        setUserLoading(false)
      })
      .catch((err) => console.log({ err }))
      .finally(() => setUserLoading(false))
  }, [usersData])

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_ILTS_SHIFT, {
    variables: filters,
    fetchPolicy: 'network-only',
  })
  const { data: userData = {}, loading: userLoading } = useQuery(
    GET_USERS_FOR_SELECT,
    {
      variables: {
        where: { role: 'CLIENT' },
        sort: { field: 'clientCode', sortingOrder: 'ASC' },
        limit: 10,
      },
      fetchPolicy: 'cache-and-network',
    }
  )
  const { ieltsShift = [] } = data
  const { data: niftyData, loading: niftyLoader } = useQuery(GET_NIFTY, {
    fetchPolicy: 'network-only',
  })
  let selectedClient
  if (
    !userLoading &&
    get(userData, 'getUsers.users') &&
    get(filters, 'where.clientCode')
  ) {
    selectedClient = get(userData, 'getUsers.users').filter(
      (u) => u.clientCode === filters.where.clientCode
    )[0]
  }

  if (userLoading) return <Loader />

  const columns = [
    {
      title: 'Shifting Analysis',
      className: 'report-title main-title',
      children: [
        {
          title: (
            <div className='hidden-client-data'>
              Client Code:{' '}
              <span className='hidden-client-code'>
                {selectedClient?.clientCode}
              </span>{' '}
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Particulars',
              dataIndex: 'particulars',
              key: 'particulars',
              className: 'report-text',
            },
            {
              title: 'Level1',
              dataIndex: 'level1',
              key: 'level1',
              className: 'report-numeric',
            },
            {
              title: 'Level2',
              dataIndex: 'level2',
              key: 'level2',
              className: 'report-numeric',
            },
          ],
        },
        {
          title: (
            <div className='hidden-client-data'>
              Client Name:{' '}
              <span className='hidden-client-code'>
                {selectedClient?.firstName} {selectedClient?.lastName}
              </span>
            </div>
          ),
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Level3',
              dataIndex: 'level3',
              key: 'level3',
              className: 'report-numeric',
            },
            {
              title: 'Level4',
              dataIndex: 'level4',
              key: 'level4',
              className: 'report-numeric',
            },
            {
              title: 'Level5',
              dataIndex: 'level5',
              key: 'level5',
              className: 'report-numeric',
            },
          ],
        },
      ],
    },
  ]

  const onFinish = (values) => {
    const data = {
      ...values,
      analysisExp: new Date(
        moment(values.analysisExp).add(330, 'minutes')
      ).toISOString(),
      final: new Date(moment(values.analysisExp)).toISOString(),
      intRate: values.intRate,
    }
    setFilters({ where: data })
  }
  return (
    !niftyLoader && (
      <>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!error && ieltsShift && (
            <DownloadReport
              data={data.ieltsShift}
              fileName='ieltsShift-report'
              id='ieltsShift-REPORT-PDF'
            />
          )}
        </div>

        <div>
          <Row gutter={8}>
            <Col span={20}>
              <Form
                form={form}
                onFinish={onFinish}
                name='advanced_search'
                labelCol={{
                  span: 14,
                }}
                wrapperCol={{
                  span: 10,
                }}
                initialValues={{
                  step: 3000,
                  intRate: 0.05,
                  round: 1000,
                  analysisLevel:
                    niftyData &&
                    Math.round(niftyData?.getNifty50[0]?.CMP / 1000, 0) * 1000,
                  clientCode: !isClient
                    ? usersData[0]?.userName
                    : `${currentUser?.clientCode}`,
                  analysisExp: moment(`${year}-12-29`),
                  final: moment(`${year}-12-28`),
                }}
              >
                <Row>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='clientCode'
                      label='User'
                      rules={[
                        { required: true, message: 'Please select users!' },
                      ]}
                      hasFeedback
                    >
                      {!isClient ? (
                        <Select
                          allowClear
                          showSearch
                          filterOption={true}
                          optionFilterProp='title'
                          placeholder='Please select users'
                        >
                          {!userLoadingClient &&
                            usersData.length &&
                            orderBy(usersData, 'id', 'desc').map(
                              (user, key) => {
                                return (
                                  user.clientCode && (
                                    <>
                                      <Option
                                        key={key}
                                        value={user.clientCode}
                                        title={`${user.clientCode} - ${getName(
                                          user
                                        )}`}
                                      >
                                        {user.clientCode} - {getName(user)}
                                      </Option>
                                    </>
                                  )
                                )
                              }
                            )}
                        </Select>
                      ) : (
                        <Input name='clientCode' readOnly />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='analysisLevel'
                      label='New Level'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select Analysis Level!',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder='Analysis Level'
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='analysisExp'
                      label='New Expiry'
                      rules={[
                        { required: true, message: 'Please select date!' },
                      ]}
                    >
                      <DatePicker
                        // format={'DD-MMM-YYYY'}
                        autoComplete='off'
                        showToday={false}
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='final'
                      label='Reporting Date'
                      rules={[
                        { required: true, message: 'Please select date!' },
                      ]}
                    >
                      <DatePicker
                        // format={'DD-MMM-YYYY'}
                        autoComplete='off'
                        showToday={false}
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='step'
                      label='Interval'
                      rules={[
                        { required: true, message: 'Please Select step!' },
                      ]}
                    >
                      <InputNumber
                        placeholder='Step'
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='intRate'
                      label='Interest'
                      rules={[
                        { required: true, message: 'Please Select intRate!' },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace('%', '')}
                        style={{ width: '150px' }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      name='round'
                      label='Round'
                      rules={[
                        { required: true, message: 'Please select users!' },
                      ]}
                    >
                      <Select
                        loading={userLoading}
                        optionFilterProp='title'
                        placeholder='Please select users'
                        style={{ width: '150px' }}
                      >
                        <Option value={1}>1</Option>
                        <Option value={10}>10</Option>
                        <Option value={100}>100</Option>
                        <Option value={1000}>1000</Option>
                        <Option value={10000}>10000</Option>
                        <Option value={100000}>Lakh</Option>
                        <Option value={1000000}>Million</Option>
                        <Option value={10000000}>Crore</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}></Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}></Col>
                </Row>

                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type='primary' htmlType='submit'>
                      Search
                    </Button>
                    <Button
                      onClick={() => {
                        form.resetFields()
                        setFilters({})
                      }}
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>

        {error ? (
          <Page404 error={error} isShow={false} />
        ) : (
          <div className='report-table-wrapper' id='ieltsShift-REPORT-PDF'>
            <IltsShiftTable
              reportData={ieltsShift || []}
              columns={columns}
              loading={loading}
            />
          </div>
        )}
      </>
    )
  )
}

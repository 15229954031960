import React, { useState, useContext } from 'react'
import {
  Form,
  Input,
  Button,
  Upload,
  Checkbox,
  Tag,
  Tooltip,
  Row,
  Col,
} from 'antd'
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { AppContext } from 'AppContext'
import pick from 'lodash/pick'
import get from 'lodash/get'
import client from 'apollo'
import { COMPLETE_STEP, UPDATE_KYC } from './graphql/Mutations'
import CustomMeta from 'components/CustomMeta'
import openNotification from '../../utils/Notification'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
} from 'modules/Users/graphql/Mutations'
import VerificationModal from './Model/VerificationModal'

function getFilesObject(tempFiles) {
  let files = pick(tempFiles, [
    'panHolderName',
    'panCard',
    'aadharCardFront',
    'passportSize',
    'signature',
    'aadharCardBack',
    'incomeProof',
    'bankStatements',
    'cancelledCheque',
  ])

  if (get(files, 'panCard[0].originFileObj')) {
    files.panCard = get(files, 'panCard[0].originFileObj')
  } else {
    delete files.panCard
  }
  if (get(files, 'aadharCardFront[0].originFileObj')) {
    files.aadharCard = get(files, 'aadharCardFront[0].originFileObj')
  } else {
    delete files.aadharCard
  }
  if (get(files, 'passportSize[0].originFileObj')) {
    files.passportSize = get(files, 'passportSize[0].originFileObj')
  } else {
    delete files.passportSize
  }
  if (get(files, 'signature[0].originFileObj')) {
    files.signature = get(files, 'signature[0].originFileObj')
  } else {
    delete files.signature
  }
  if (get(files, 'cancelledCheque[0].originFileObj')) {
    files.cancelledChequeSnap = get(files, 'cancelledCheque[0].originFileObj')
  } else {
    delete files.cancelledChequeSnap
  }
  if (get(files, 'bankStatements[0].originFileObj')) {
    files.bankStatement = get(files, 'bankStatements[0].originFileObj')
  } else {
    delete files.bankStatement
  }
  if (get(files, 'aadharCardBack[0].originFileObj')) {
    files.aadharCardBack = get(files, 'aadharCardBack[0].originFileObj')
  } else {
    delete files.aadharCardBack
  }
  if (get(files, 'incomeProof[0].originFileObj')) {
    files.incomeProof = get(files, 'incomeProof[0].originFileObj')
  } else {
    delete files.incomeProof
  }
  return files
}

const FormItem = Form.Item

export default function ({ next, reEvaluate }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [agree, setAgree] = useState(false)
  const [editAccess, setEditAccess] = useState(reEvaluate)
  const [otpModal, setOtpModal] = useState(false)
  // const [files, setFile] = useState()
  const {
    dispatch,
    state: {
      currentUser: {
        panCardNo,
        firstName,
        lastName,
        id,
        kyc,
        cvlStatus,
        clientScore,
      },
    },
  } = useContext(AppContext)
  const [fileList, setFileList] = useState([])

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  const onFinish = (values) => {
    const files = getFilesObject(values)
    if (editAccess) {
      onNext(files)
    } else if (reEvaluate) {
      setLoading(true)
      setOtpModal(true)
      // setFile(values.incomeProof[0])
      client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
        openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
      })
    } else {
      onNext(files)
    }
  }

  const onNext = (files) => {
    setLoading(true)
    client
      .mutate({
        mutation: UPDATE_KYC,
        variables: { userId: parseInt(id), ...files },
      })
      .then((res) => {
        setLoading(true)
        client
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              id,
              data: {
                completedSteps: cvlStatus ? 'KYC_FORM' : 'KYC_DOC',
              },
            },
          })
          .then((res) => {
            client.mutate({
              mutation: COMPLETE_STEP, variables: {
                data: {
                  step: 'KYC_DOC',
                  from: 'WEB'
                }
              }
            })
            dispatch({
              type: 'CURRENT_USER',
              data: res.data.updateUser,
            })
            openNotification('success', 'Kyc Document Updated Successfully')
            setLoading(false)
            if (cvlStatus) {
              next(2)
            } else {
              next()
            }
          })
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false))
  }
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 18,
      },
    },
  }

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  let obj = {}

  if (kyc?.aadharCard) {
    obj['aadharCardFront'] = [
      {
        uid: `aadharCardFront ${kyc?.id}`,
        name: 'aadhar.png',
        url: kyc?.aadharCard,
      },
    ]
  }
  if (kyc?.aadharCard) {
    obj['aadharCardBack'] = [
      {
        uid: `aadharCardBack ${kyc?.id}`,
        name: 'aadhar.png',
        url: kyc?.aadharCard,
      },
    ]
  }
  if (kyc?.panCard) {
    obj['panCard'] = [
      {
        uid: `panCard ${kyc?.id}`,
        name: 'panCard.png',
        url: kyc?.panCard,
      },
    ]
  }
  if (kyc?.bankStatement) {
    obj['bankStatements'] = [
      {
        uid: `bankStatements ${kyc?.id}`,
        name: 'bankStatement.png',
        url: kyc?.bankStatement,
      },
    ]
  }
  if (kyc?.cancelledChequeSnap) {
    obj['cancelledCheque'] = [
      {
        uid: `cancelledCheque ${kyc?.id}`,
        name: 'cancelledChequeSnap.png',
        url: kyc?.cancelledChequeSnap,
      },
    ]
  }
  if (kyc?.signature) {
    obj['signature'] = [
      {
        uid: `signature ${kyc?.id}`,
        name: 'signature.png',
        url: kyc?.signature,
      },
    ]
  }
  if (kyc?.passportSize) {
    obj['passportSize'] = [
      {
        uid: `passportSize ${kyc?.id}`,
        name: 'passportSize.png',
        url: kyc?.passportSize,
      },
    ]
  }
  if (kyc?.bankStatement) {
    obj['incomeProof'] = [
      {
        uid: `incomeProof ${kyc?.id}`,
        name: 'incomeProof.png',
        url: kyc?.bankStatement,
      },
    ]
  }

  const text = (
    <span>
      ITR Acknowledgement,
      <br />
      Salary slip (3 months latest),
      <br />
      Form-16,
      <br />
      Net worth certificate
    </span>
  )

  const editAccessFunc = () => {
    setEditAccess(false)
  }

  return (
    <>
      <Row>
        <Col span={22} style={{ textAlign: 'center' }}>
          <h1>Kyc Document</h1>
        </Col>
        <Col span={2} style={{ textAlign: 'end' }}>
          {editAccess && (
            <Button type='primary' onClick={editAccessFunc}>
              Edit
            </Button>
          )}
        </Col>
      </Row>

      <div
        className='gx-app-login-wrap'
        style={{ overflowX: 'hidden', textAlign: 'start' }}
      >
        <CustomMeta title='Kyc Doc' />
        <div className='gx-app-login-content verification'>
          <div style={{ textAlignLast: 'end' }}>
            <Tag defaultValue={clientScore} color='red'>
              Score (Q/A) : {clientScore}
            </Tag>
            <Tag color={cvlStatus ? 'green' : 'red'}>
              PAN KYC Status ({cvlStatus ? 'YES' : 'NO'})
            </Tag>
          </div>
          <Form
            {...formItemLayout}
            form={form}
            name='register'
            labelAlign='left'
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{
              panCardNo: panCardNo || '',
              panHolderName: `${firstName} ${lastName}` || '',
              ...obj,
            }}
          >
            {!cvlStatus ? (
              <>
                <FormItem
                  name='panCardNo'
                  label='PAN Card Number'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your valid pan number!',
                      pattern: new RegExp(
                        /[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}/g
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder='PAN Card Number'
                    autoFocus={true}
                    style={{ width: '100%' }}
                    autoComplete='off'
                    disabled
                  />
                </FormItem>
                <FormItem
                  name='panHolderName'
                  label='PAN Holder Name'
                  rules={[
                    {
                      required: true,
                      message: 'PAN Holder Name is required',
                    },
                  ]}
                >
                  <Input
                    placeholder='PAN Holder Name'
                    autoFocus={true}
                    style={{ width: '100%' }}
                    autoComplete='off'
                    disabled={!editAccess ? false : true}
                  />
                </FormItem>

                <FormItem
                  name='aadharCardFront'
                  label='Aadhaar Card Front'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: 'Aadhaar Card Front is required',
                    },
                  ]}
                >
                  <Upload
                    name='aadharCardFront-file'
                    listType='picture'
                    accept='.jpeg,.png,.jpg'
                    {...customProps}
                  >
                    <Button disabled={!editAccess ? false : true}>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </FormItem>

                <FormItem
                  name='aadharCardBack'
                  label='Aadhar Card Back'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: 'Aadhaar Card Back is required',
                    },
                  ]}
                >
                  <Upload
                    name='aadharCardBack-file'
                    listType='picture'
                    {...customProps}
                    accept='.jpeg,.png,.jpg'
                  >
                    <Button disabled={!editAccess ? false : true}>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </FormItem>

                <FormItem
                  name='panCard'
                  label='PAN Card'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: 'PAN Card is required',
                    },
                  ]}
                >
                  <Upload
                    name='panCard-file'
                    listType='picture'
                    {...customProps}
                    accept='.jpeg,.png,.jpg'
                  >
                    <Button disabled={!editAccess ? false : true}>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </FormItem>

                <FormItem
                  name='bankStatements'
                  label='Last 3 Months Bank Statements'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: 'Last 3 Months Bank Statements is required',
                    },
                  ]}
                >
                  <Upload
                    name='bankStatement-file'
                    listType='picture'
                    {...customProps}
                    accept='.png,.jpg'
                  >
                    <Button disabled={!editAccess ? false : true}>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </FormItem>

                <FormItem
                  name='cancelledCheque'
                  label='Cancelled Cheque'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: 'Cancelled Cheque is required',
                    },
                  ]}
                >
                  <Upload
                    name='cancelledCheque-file'
                    listType='picture'
                    {...customProps}
                    accept='.jpeg,.png,.jpg'
                  >
                    <Button disabled={!editAccess ? false : true}>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </FormItem>

                <FormItem
                  name='signature'
                  label='Signature'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: 'Signature is required',
                    },
                  ]}
                >
                  <Upload
                    name='signature-file'
                    listType='picture'
                    {...customProps}
                    accept='.jpeg,.png,.jpg'
                  >
                    <Button disabled={!editAccess ? false : true}>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </FormItem>

                <FormItem
                  name='passportSize'
                  label='Passport Size Photo'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: 'Passport Size Photo is required',
                    },
                  ]}
                >
                  <Upload
                    name='passportSize-file'
                    listType='picture'
                    {...customProps}
                    accept='.jpeg,.png,.jpg'
                  >
                    <Button disabled={!editAccess ? false : true}>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </FormItem>
                <FormItem
                  name='incomeProof'
                  label={
                    <span>
                      Income Proof&nbsp;{' '}
                      <Tooltip title={text}>
                        <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </span>
                  }
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name='incomeProof-file'
                    listType='picture'
                    {...customProps}
                    accept='.jpeg,.png,.jpg'
                  >
                    <Button disabled={!editAccess ? false : true}>
                      <UploadOutlined /> Click to upload
                    </Button>
                  </Upload>
                </FormItem>
              </>
            ) : (
              <FormItem
                name='incomeProof'
                label={
                  <span>
                    Income Proof&nbsp;{' '}
                    <Tooltip title={text}>
                      <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </span>
                }
                valuePropName='fileList'
                getValueFromEvent={normFile}
              >
                <Upload
                  name='incomeProof-file'
                  listType='picture'
                  {...customProps}
                  accept='.jpeg,.png,.jpg'
                >
                  <Button disabled={!editAccess ? false : true}>
                    <UploadOutlined /> Click to upload
                  </Button>
                </Upload>
              </FormItem>
            )}

            <div>
              <div className='agree-wrapper'>
                <Checkbox onChange={handleAgree} />
                <p className='agree-content'>
                  I/We hereby declare that the details furnished above are true
                  and correct to the best of my/our knowledge and belief and
                  I/We undertake to inform you of any changes therein,
                  immediately. In case any of the above information is found to
                  be false or untrue or misleading or misrepresenting, I am/We
                  are aware that I/We may be held liable for it.
                </p>
              </div>
            </div>
            <Button
              type='primary'
              loading={loading}
              htmlType='submit'
              disabled={!agree}
            >
              Next
            </Button>
          </Form>
          {otpModal && <VerificationModal visible={otpModal} onNext={onNext} />}
        </div>
      </div>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Select, DatePicker, Radio } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'

import { GET_USERS_FOR_SELECT } from '../../Users/graphql/Queries'
import moment from 'moment'
// import client from 'apollo'

const { Option } = Select

const CollectionCreateForm = ({
  visible,
  onCreate,
  onUpdate,
  onCancel,
  isEdit,
  selectedNotification,
}) => {
  const [form] = Form.useForm()
  const [users, setUsers] = useState([])
  const { data, loading, error } = useQuery(GET_USERS_FOR_SELECT, {
    variables: {
      where: { role: 'CLIENT' },
      sort: { field: 'clientCode', sortingOrder: 'ASC' },
      limit: 1000,
    },
    fetchPolicy: 'cache-and-network',
  })

  function resetForm() {
    form.resetFields()
  }

  function disabledDate(current) {
    return current && current < moment().subtract(1, 'days').endOf('day')
  }

  useEffect(() => {
    if (!loading && !error && get(data, 'getUsers.users')) {
      setUsers(data.getUsers.users)
    }
  }, [data, error, loading])

  // function handleSearch(value) {
  //   if (loading) return
  //   if (value) {
  //     client.query({ query: GET_USERS_FOR_SELECT, variables: { where: { role: 'CLIENT', firstName: value } }, fetchPolicy: 'network-only' })
  //       .then((res) => {
  //         if (get(res, 'data.getUsers.users'))
  //           setUsers(res.data.getUsers.users)
  //         else
  //           setUsers([])
  //         return client.query({ query: GET_USERS_FOR_SELECT, variables: { where: { role: 'CLIENT', lastName: value } }, fetchPolicy: 'network-only' })
  //       })
  //       .then((res) => {
  //         if (get(res, 'data.getUsers.users'))
  //           setUsers(users => [...users, ...res.data.getUsers.users])
  //       })
  //       .catch((err) => setUsers([]))
  //   } else {
  //     setUsers(data.getUsers.users)
  //   }
  // }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isEdit ? 'Edit Notification' : 'Add New Notification'}
      okText={isEdit ? 'Update Notification' : 'Add Notification'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (isEdit) {
              onUpdate(values, resetForm)
            } else {
              onCreate(values, resetForm)
            }
          })
          .catch((info) => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
          ...selectedNotification,
          date: get(selectedNotification, 'date')
            ? moment(selectedNotification.date)
            : undefined,
        }}
      >
        <Form.Item
          name='title'
          label='Title'
          rules={[
            {
              required: true,
              message: 'Please input your title!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='notificationType'
          label='Type'
          rules={[{ required: true, message: 'Please select type!' }]}
        >
          <Radio.Group>
            <Radio value={'SMS'}>SMS</Radio>
            <Radio value={'EMAIL'}>EMAIL</Radio>
            <Radio value={'BOTH'}>BOTH</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name='receiverIds'
          label='Select Users'
          rules={[{ required: true, message: 'Please select users!' }]}
          hasFeedback
        >
          <Select
            mode='multiple'
            loading={loading}
            filterOption={true}
            optionFilterProp='title'
            // onSearch={handleSearch}
            style={{ width: '100%' }}
            placeholder='Please select users'
          >
            {users.map((user, key) => {
              return (
                <Option key={key} value={user.id} title={user.clientCode}>
                  {user.clientCode}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='description'
          label='Description'
          rules={[
            {
              required: true,
              message: 'Please input your description!',
              whitespace: true,
            },
          ]}
          hasFeedback
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          name='date'
          label='Date'
          rules={[{ required: true, message: 'Please input your Date!' }]}
        >
          <DatePicker
            format={'DD-MMM-YYYY'}
            disabledDate={disabledDate}
            autoComplete='off'
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateNotificationModal = (props) => {
  return (
    <div>
      <CollectionCreateForm {...props} />
    </div>
  )
}

export default CreateNotificationModal

import React, { useState } from 'react'
import { Button, Input, Space, DatePicker } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import NoData from 'components/NoData'
import LedgerTable from './LedgerTable'
import Page404 from 'components/Page404'
import DownloadReport from '../DownloadReport'
import Loader from 'components/loaders/Loader'
import { GET_LEDGER_REPORT } from '../graphql/Queries'
import '../index.css'

const { RangePicker } = DatePicker

export default function () {
  const [filters, setFilters] = useState({})
  const { data, loading, error, networkStatus } = useQuery(GET_LEDGER_REPORT, {
    variables: { where: filters },
    fetchPolicy: 'network-only',
  })

  if (loading && networkStatus === 1) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spLedgerReport')) return <NoData />

  const { spLedgerReport } = data

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <RangePicker
          format={'DD-MMM-YYYY'}
          onChange={(e) => {
            const from = `fromDate`
            const to = `toDate`
            let tempFilters
            if (e) {
              confirm()
              tempFilters = JSON.parse(JSON.stringify(filters))
              tempFilters[from] = e[0]
              tempFilters[to] = e[1]
            } else {
              clearFilters()
              tempFilters = JSON.parse(JSON.stringify(filters))
              delete tempFilters[from]
              delete tempFilters[to]
            }
            setFilters(tempFilters)
          }}
        />
      </div>
    ),
  })

  const columns = [
    {
      title: 'Ledger Report',
      className: 'report-title main-title',
      children: [
        {
          title: 'Entry Date',
          dataIndex: 'Entrydate',
          key: 'Entrydate',
          className: 'report-text',
          width: '7%',
          ...getColumnDateProps('entrydate'),
        },
        {
          title: 'Type',
          dataIndex: 'Type',
          key: 'Type',
          className: 'report-text',
        },
        {
          title: 'Remarks',
          dataIndex: 'remark',
          key: 'remark',
          className: 'report-text',
          width: '15%',
        },
        {
          title: 'DR Amount',
          dataIndex: 'DR_Amount',
          key: 'DR_Amount',
          className: 'report-numeric',
        },
        {
          title: 'CR Amount',
          dataIndex: 'CR_Amount',
          key: 'CR_Amount',
          className: 'report-numeric',
        },
        {
          title: 'Strategy Code',
          dataIndex: 'StrategyCode',
          key: 'StrategyCode',
          className: 'report-text',
          ...getColumnSearchProps('strategyCode'),
        },
        {
          title: 'Client Code',
          dataIndex: 'ClientCode',
          key: 'ClientCode',
          className: 'report-text',
          ...getColumnSearchProps('clientCode'),
        },
        {
          title: 'Member Code',
          dataIndex: 'membercode',
          key: 'membercode',
          className: 'report-text',
          ...getColumnSearchProps('memberCode'),
        },
      ],
    },
  ]

  return (
    <>
      <DownloadReport
        data={spLedgerReport.filter((report, key) => key !== 0)}
        fileName='LEDGER-report'
        id='LEDGER-REPORT-PDF'
      />
      <div className='report-table-wrapper' id='LEDGER-REPORT-PDF'>
        <LedgerTable
          reportData={spLedgerReport.filter((report, key) => key !== 0)}
          columns={columns}
          loading={loading}
        />
      </div>
    </>
  )
}

import React, { useContext, useState, Fragment, useEffect } from 'react'
import { MenuOutlined, MenuUnfoldOutlined, MenuFoldOutlined, EyeOutlined, PoweroffOutlined } from '@ant-design/icons'
import { Layout, Avatar, Popover, Select, Col, Row } from 'antd'
import _omit from 'lodash/omit'
import _map from 'lodash/map'
import _get from 'lodash/get'

import { GET_STRATEGY_LIST } from 'modules/Settings/graphql/Queries'
import { CHANGE_PASSWORD } from 'modules/Users/graphql/Mutations'
import { GET_GROUPS } from 'modules/Groups/graphql/Queries'
import ChangePassword from 'components/ChangePassword'
import { getName, getFirstLetter } from 'utils/User'
import openNotification from 'utils/Notification'
import { AppContext } from 'AppContext'
import history from 'CustomHistory'
import client from 'apollo'

const { Header } = Layout
const { Option } = Select

export default function ({ collapsed, toggle, drawerToggle }) {
  const color = '#7265e6'
  const [groups, setGroups] = useState([])
  const [visible, setVisible] = useState(false)
  const [strategyList, setStrategyList] = useState([])
  const [visibleMenu, setVisibleMenu] = useState(false)
  const { state: { currentUser, isClient, MaintenanceStatus: { type, message, isActive } }, dispatch } = useContext(AppContext)

  const showFilters = _get(history, 'location.pathname') === '/' && !isClient

  useEffect(() => {
    dispatch({ type: 'SELECTED_GROUP', data: undefined })
    dispatch({ type: 'SELECTED_STRATEGY', data: 'ILTS' })
  }, [dispatch, showFilters])

  useEffect(() => {
    if (showFilters) {
      client.query({ query: GET_STRATEGY_LIST, fetchPolicy: 'network-only' })
        .then((res) => {
          if (_get(res, 'data.getStrategyList')) {
            setStrategyList(_get(res, 'data.getStrategyList'))
          }
        })
    }
  }, [showFilters])

  useEffect(() => {
    if (showFilters) {
      client.query({ query: GET_GROUPS, variables: { limit: 300 }, fetchPolicy: 'network-only' })
        .then((res) => {
          if (_get(res, 'data.getGroups.groups')) {
            setGroups(_get(res, 'data.getGroups.groups'))
          }
        })
    }
  }, [showFilters])

  const userMenuOptions = (
    <ul className='gx-user-popover'>
      <li
        onClick={() => {
          setVisibleMenu(false)
          history.push('/profile')
        }}>
        <i className='icon icon-avatar -flex-column-reverse menu-profile'></i> Profile
      </li>
      <li
        onClick={() => {
          setVisibleMenu(false)
          handleVisible()
        }}>
        <EyeOutlined /> Change Password
      </li>
      <li onClick={() => window.location.href = '/logout'}>
        <PoweroffOutlined /> Logout
      </li>
    </ul>
  )

  function handleVisible() {
    setVisible(!visible)
  }

  function handleChangePassword(values, resetForm) {
    const queryVariables = _omit(values, 'confirm')
    client.mutate({ mutation: CHANGE_PASSWORD, variables: queryVariables })
      .then((res) => {
        openNotification('success', 'Password Changed Successfully')
        handleVisible()
        resetForm()
        history.push('/logout')
      })
      .catch((err) => console.log(err))
  }

  function returnGroups() {
    return _map(groups, (group, key) => {
      const { id, name } = group
      return <Option value={id} key={key}>{name}</Option>
    })
  }

  const messageStyle = {
    textAlign: 'center',
    fontSize: '15px',
    marginTop: '10px',
    backgroundColor: '#fff',
    height: '20'
  }
  if (type === 'SUCCESS') {
    messageStyle.color = '#5b8c00'
  } else if (type === 'WARNING') {
    messageStyle.color = '#fa8c16'
  } else if (type === 'INFO') {
    messageStyle.color = '#1890ff'
  } else if (type === 'DANGER') {
    messageStyle.color = '#f5222d'
  }

  return (
    <Fragment>
      {visible && <ChangePassword visible={visible} onCreate={handleChangePassword} onCancel={handleVisible} />}
      <Header className='site-layout-background'>
        {React.createElement(window.innerWidth >= 991 ? collapsed ? MenuUnfoldOutlined : MenuFoldOutlined : MenuOutlined, {
          className: 'trigger',
          onClick: () => window.innerWidth >= 991 ? toggle() : drawerToggle(),
        })}
        <div className='gx-flex-row gx-align-items-center gx-avatar-row'>
          {
            showFilters &&
            <Select
              className='gx-mr-3 gx-select-sm'
              defaultValue=''
              onChange={value => dispatch({ type: 'SELECTED_GROUP', data: value ? parseInt(value) : undefined })}
              style={{ minWidth: 100 }}
            >
              <Option value=''>All Clients</Option>
              {returnGroups()}
            </Select>
          }
          {showFilters &&
            <Select
              defaultValue='1005'
              style={{ width: 80 }}
              className='gx-mr-3 gx-select-sm'
              onChange={(e) => dispatch({ type: 'SELECTED_STRATEGY', data: e })}
            >
              {_map(strategyList, (strategy, key) => {
                const { StrategyName, StrategyCode } = strategy
                return <Option value={StrategyCode} key={key}>{StrategyName}</Option>
              })}
            </Select>}
          <Popover
            placement='bottomRight'
            onVisibleChange={() => setVisibleMenu(!visibleMenu)}
            visible={visibleMenu}
            content={userMenuOptions}
            trigger='click'
          >
            <div>
              <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size='default' className='gx-pointer'>{getFirstLetter(currentUser)}</Avatar>
              <span className='gx-avatar-name'><span className={`header-name-ml ${showFilters ? 'header-name' : ''}`}>{getName(currentUser)}</span> <i className='icon icon-chevron-down gx-fs-xxs gx-ml-2' /></span>
              {!isClient && <div className='gx-avatar-role'><span className='gx-avatar-name'>{currentUser.role.split('_').join(' ').toLowerCase()}</span></div>}
            </div>
          </Popover>
        </div>
      </Header>
      {isClient && isActive &&
        <Row gutter={24} >
          <Col span={1}></Col>
          <Col span={22}>
            <div style={messageStyle} >
              {message}
            </div>
          </Col>
          <Col span={1}></Col>
        </Row>
      }
    </Fragment>
  )
}

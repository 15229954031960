import React, { Fragment, useState } from 'react'
import moment from 'moment'
import get from 'lodash/get'
// import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { PageHeader, Table, Button, Tooltip, Modal } from 'antd'
import { EditTwoTone, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { CREATE_NOTIFICATION, UPDATE_NOTIFICATION, DELETE_NOTIFICATION } from './graphql/Mutations'
import { GET_SCHEDULE_NOTIFICATIONS } from './graphql/Queries'
import CreateNotificationModal from './CreateNotificationModal'
import openNotification from 'utils/Notification'
import CustomMeta from 'components/CustomMeta'
import Page404 from 'components/Page404'
import client from 'apollo'

const { confirm } = Modal

export default function () {
  let tableData = []
  const [showFormModal, setShowFormModal] = useState(false)
  const [selectedNotification, setSelectedNotification] = useState(undefined)

  const { data, loading, error } = useQuery(GET_SCHEDULE_NOTIFICATIONS, { fetchPolicy: 'network-only' })

  if (error) return <Page404 error={error} />

  let totalCount = 0

  if (!loading && get(data, 'scheduleNotifications')) {
    totalCount = data.scheduleNotifications.length
    tableData = data.scheduleNotifications.map((scheduleNotification, key) => ({ key: key.toString(), ...scheduleNotification }))
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this notification?`,
      content: `When clicked the OK button, this notification will be Deleted`,
      onOk() {
        client
          .mutate({ mutation: DELETE_NOTIFICATION, variables: { id: parseInt(id) }, refetchQueries: [{ query: GET_SCHEDULE_NOTIFICATIONS }] })
          .catch((err) => console.log(err))
      }
    })
  }

  function handleHideFormModal() {
    setShowFormModal(false)
    setSelectedNotification(undefined)
  }

  function renderAction(record) {
    return <div className='action-icons'>
      <Tooltip title='Edit'>
        <EditTwoTone
          onClick={() => {
            setShowFormModal(true)
            setSelectedNotification(record)
          }} />
      </Tooltip>
      <Tooltip title='Delete Notification'>
        <DeleteOutlined onClick={() => showConfirm(record.id)} />
      </Tooltip>
    </div>
  }

  function handleCreateNotification(values, resetForm) {
    const data = values
    client.mutate({ mutation: CREATE_NOTIFICATION, variables: { data }, refetchQueries: [{ query: GET_SCHEDULE_NOTIFICATIONS }] })
      .then((res) => {
        openNotification('success', 'Notification Added Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }

  function handleUpdateNotification(values, resetForm) {
    const data = values
    const { id } = selectedNotification
    client.mutate({ mutation: UPDATE_NOTIFICATION, variables: { data, id: parseInt(id) }, refetchQueries: [{ query: GET_SCHEDULE_NOTIFICATIONS }] })
      .then((res) => {
        openNotification('success', 'Notification Updated Successfully')
        handleHideFormModal()
        resetForm()
      })
      .catch((err) => console.log(err))
  }


  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      // render: (text, record) => <NavLink to={`/schedule-notification/${record.id}`}>{text}</NavLink>,
      width: '50%',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => moment(date).format('Do MMMM YYYY'),
      sorter: (a, b) => a.name.length - b.name.length,
      width: '40%',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => renderAction(record),
      width: '10%',
    }
  ]

  return (
    <Fragment>
      <CustomMeta title='Notifications' />
      <PageHeader
        className='box'
        title='Notifications'
        extra={[
          <Button
            key='1'
            type='primary'
            onClick={() => setShowFormModal(true)}
          >
            Add Notification
          </Button>
        ]}
      />
      {showFormModal &&
        <CreateNotificationModal
          visible={showFormModal}
          onCreate={handleCreateNotification}
          onUpdate={handleUpdateNotification}
          selectedNotification={selectedNotification}
          isEdit={selectedNotification !== undefined}
          onCancel={() => handleHideFormModal()}
        />
      }
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        scroll={{ x: true, y: true }}
        pagination={{ total: totalCount }}
      />
    </Fragment>
  )
}

// import domToPdf from 'dom-to-pdf'
import _get from 'lodash/get'
import domToImage from 'dom-to-image'
import fileDownload from 'js-file-download'

import client from 'apollo'
import openNotification from './Notification'
import { GENERATE_PDF, SEND_ME_PDF } from 'modules/Reports/graphql/Queries'

export async function exportGraphAsPdf(name, id, staticHtml = null, isLandscape = false) {
  return new Promise((resolve, reject) => {
    let type = !staticHtml ? 'portrait' : 'landscape'
    if (isLandscape) {
      type = "landscape"
    }
    const html = staticHtml || document.getElementById(id)?.innerHTML
    client.query({ query: GENERATE_PDF, variables: { data: { html, type, name } }, fetchPolicy: 'network-only' })
      .then((res) => _get(res, 'data.generatePDF') && window.open(_get(res, 'data.generatePDF'), '_blank'))
      .catch((err) => console.log(err))
      .finally(() => resolve())
  })
  // domToPdf(document.getElementById(id), { filename }, function () {
  //   console.log('PDF Generated')
  // })
}

export function exportGraphAsImage(name, type, id) {
  domToImage.toBlob(document.getElementById(id))
    .then((blob) => fileDownload(blob, `${name}.${type}`))
    .catch((err) => console.log(err))
}


export async function sendMePdf(id) {
  return new Promise((resolve, reject) => {
    const html = document.getElementById(id).innerHTML
    client.query({ query: SEND_ME_PDF, variables: { data: { html, useSendInBlue: true } }, fetchPolicy: 'network-only' })
      .then(() => openNotification('success', 'Mail has been sent!'))
      .catch((err) => console.log(err))
      .finally(() => resolve())
  })
}

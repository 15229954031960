import React, { useState, useContext } from 'react'
import {
  Tooltip,
  Table,
  PageHeader,
  DatePicker,
  Modal,
  Button,
  Space,
  Form,
  Upload,
  Input,
} from 'antd'
import {
  EditTwoTone,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import moment from 'moment'
import client from 'apollo'

import {
  UPDATE_AMC,
  DELETE_AMC,
  IMPORT_AMC,
} from 'modules/Users/graphql/Mutations'
import {
  GET_ALL_AMC,
  GET_AMC,
  USER_PUBLIC,
} from 'modules/Users/graphql/Queries'
import openNotification from 'utils/Notification'
import { AppContext } from 'AppContext'
import CreateAMCModal from './Modal'
import { getName } from 'utils/User'
import {
  GENERATE_PDF,
  CUSTOM_CSV_DOWNLOAD,
} from 'modules/Reports/graphql/Queries'
import Page404 from 'components/Page404'
import { CSVLink } from 'react-csv'

const { confirm } = Modal
const { RangePicker } = DatePicker

export default function () {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const {
    state: {
      isClient,
      currentReport,
      currentUser: { id },
    },
  } = useContext(AppContext)
  const [filters, setFilters] = useState(
    isClient ? { clientId: parseInt(id).toString() } : {}
  )
  const [updateData, setUpdateData] = useState(undefined)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showFormLoading, setShowFormLoading] = useState(false)
  const [CSVLoading, setCSVLoading] = useState(false)
  const [PDFLoading, setPDFLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [csv, setCsv] = useState(undefined)
  const [AMCLoading, setAMCLoading] = useState(false)

  let variable = { where: filters, sort, skip, limit }
  if (filters.associateName) {
    delete variable.skip
    delete variable.limit
  }
  const { data, loading, error } = useQuery(GET_ALL_AMC, {
    variables: variable,
    fetchPolicy: 'network-only',
  })

  const { data: filterData, error: filterError } = useQuery(USER_PUBLIC, {
    fetchPolicy: 'network-only',
  })

  if (error || filterError) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getAllAmcs.amc')) {
    totalCount = data.getAllAmcs.count
    tableData = data.getAllAmcs.amc.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
  } else if (!loading && isClient && get(filterData, 'getAllAmcs.amc')) {
    totalCount = data.getAllAmcs.count
    tableData = data.getAllAmcs.amc.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    }
  }

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleUpdate(values, resetForm) {
    const dateMoment = moment(values.date)
    const date = `${moment().format('YYYY')}-${dateMoment.format(
      'M'
    )}-${dateMoment.format('D')}`
    values.endDate = moment(new Date(date)).add(1, 'years')
    delete values.date
    values.whichYear = parseInt(values.whichYear.format('YYYY'))
    setShowFormLoading(true)
    client
      .mutate({
        mutation: UPDATE_AMC,
        variables: { data: values, id: updateData.id },
        refetchQueries: [
          {
            query: GET_AMC,
            variables: { ...filters, skip, sort, limit },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        openNotification('success', 'AMC Updated Successfully')
        setUpdateData(undefined)
        setShowFormModal(false)
        setIsUpdate(false)
        resetForm()
      })
      .catch((err) => console.log(err))
      .finally(() => setShowFormLoading(false))
  }

  function showConfirm(id) {
    confirm({
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      title: `Do you want to delete this AMC?`,
      content: `When clicked the OK button, this AMC will be Deleted`,
      onOk() {
        client
          .mutate({
            mutation: DELETE_AMC,
            variables: { id },
            refetchQueries: [
              { query: GET_AMC, variables: { ...filters, skip, sort, limit } },
            ],
          })
          .catch((err) => console.log(err))
      },
    })
  }

  function renderAction(record) {
    return (
      <div className='action-icons'>
        {currentReport.includes('Update AMC') && (
          <Tooltip title='Edit'>
            <EditTwoTone
              onClick={() => {
                setIsUpdate(true)
                setUpdateData(record)
                setShowFormModal(true)
              }}
            />
          </Tooltip>
        )}
        {currentReport.includes('Delete AMC') && (
          <Tooltip title='Delete AMC'>
            <DeleteOutlined onClick={() => showConfirm(record.id)} />
          </Tooltip>
        )}
      </div>
    )
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleCsvDownload() {
    setCSVLoading(true)
    client
      .query({
        query: GET_AMC,
        variables: { ...filters, skip: 0, sort, limit: totalCount },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: res.data.getAllAmcs.amc,

                fields: [
                  { value: 'client.clientCode', label: 'client Code' },
                  { value: 'client.firstName', label: 'Client First name' },
                  { value: 'client.lastName', label: 'Client Last name' },
                  { value: 'client.role', label: 'Type' },
                  {
                    value: 'client.createdBy.firstName',
                    label: 'Associate Name',
                  },
                  { value: 'amcQty', label: 'AMC Amount' },
                  { value: 'amount', label: 'Quantity' },
                  { value: 'paymentType', label: 'Payment Type' },
                  { value: 'startDate', label: 'Start Date' },
                  { value: 'paidAt', label: 'Paid Date' },
                  { value: 'nextDueDate', label: 'Next DueDate' },
                  { value: 'createdAt', label: 'Created Date' },
                  { value: 'description', label: 'Description' },
                ],
                fileName: 'AMCs',
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
  }

  function handlePdfDownload() {
    setPDFLoading(true)
    client
      .query({
        query: GET_AMC,
        variables: { ...filters, limit: totalCount, skip: 0 },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        let staticHtml = ``
        if (get(res, 'data.getAllAmcs.amc').length) {
          staticHtml = `
            <div class="ant-table-wrapper nlv-tracker-report">
              <div class="ant-spin-nested-loading">
                <div class="ant-spin-container">
                  <div class="ant-table">
                    <div class="ant-table-container">
                      <div class="ant-table-content">
                        <table style="table-layout: auto;">
                          <colgroup>
                            <col style="width: 60px; min-width: 60px;">
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col style="width: 200px; min-width: 200px;">
                          </colgroup>
                          <thead class="ant-table-thead">
                            <tr>
                              <th colspan="14" class="ant-table-cell report-title main-title">AMC</th>
                            </tr>
                            <tr>
                              <th class="ant-table-cell report-text">ClientCode</th>
                              <th class="ant-table-cell report-text">ClientFirstName</th>
                              <th class="ant-table-cell report-text">ClientLastName</th>
                              <th class="ant-table-cell report-text">Type</th>
                              <th class="ant-table-cell report-text">AssociateName</th>
                              <th class="ant-table-cell report-text">Quantity</th>
                              <th class="ant-table-cell report-text">AMCAmount</th>
                              <th class="ant-table-cell report-text">PaymentType</th>
                              <th class="ant-table-cell report-text">StartDate</th>
                              <th class="ant-table-cell report-text">PaidDate</th>
                              <th class="ant-table-cell report-text">NextDueDate</th>
                              <th class="ant-table-cell report-text">CreateDate</th>
                              <th class="ant-table-cell report-text">Description</th>
                            </tr>
                          </thead>
                          <tbody class="ant-table-tbody">`
          staticHtml = `${staticHtml}`
          res.data.getAllAmcs.amc.forEach((data) => {
            const {
              client: { clientCode, firstName, lastName, role },
              amcQty,
              amount,
              paymentType,
              startDate,
              paidAt,
              nextDueDate,
              createdAt,
              description,
            } = data

            staticHtml = `${staticHtml}
                            <tr data-row-key="KYP" class="ant-table-row ant-table-row-level-0">
                              <td class="ant-table-cell report-text">${
                                clientCode || 'N/A'
                              } </td>
                              <td class="ant-table-cell report-text">${
                                firstName || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                lastName || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                role ? role.split('_').join(' ') : 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                `${data?.client?.createdBy?.firstName} ${data?.client?.createdBy?.lastName}` ||
                                'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                amcQty || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                amount || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                paymentType || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                startDate || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                paidAt || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                nextDueDate || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                createdAt || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                description || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text"></td>
                            </tr>`
          })
          staticHtml = `${staticHtml}
            </tbody >
                        </table >
                      </div >
                    </div >
                  </div >
                </div >
              </div >
            </div >
              `
        }
        client
          .query({
            query: GENERATE_PDF,
            variables: { data: { html: staticHtml, type: 'landscape' } },
            fetchPolicy: 'network-only',
          })
          .then(
            (res) =>
              get(res, 'data.generatePDF') &&
              window.open(get(res, 'data.generatePDF'), '_blank')
          )
      })
      .catch((err) => console.log(err))
      .finally(() => setPDFLoading(false))
  }

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const [form] = Form.useForm()
  const importBulkAMCs = () => {
    setAMCLoading(true)
    client
      .mutate({
        mutation: IMPORT_AMC,
        variables: { data: { csv: csv[0].originFileObj } },
        refetchQueries: [
          {
            query: GET_AMC,
            variables: { ...filters, skip, sort, limit },
            fetchPolicy: 'network-only',
          },
        ],
      })
      .then((res) => {
        res?.data?.importAmc
          ? openNotification('success', res?.data?.importAmc)
          : openNotification('error', 'Please check the file')
        setAMCLoading(false)
        setIsModalVisible(false)
      })
      .catch((err) => console.log(err))

      .finally(() => setAMCLoading(false), setIsModalVisible(false))
  }

  const importBulkAmcsModalClose = () => {
    setIsModalVisible(false)
    setCsv('')
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    if (e && e.fileList && e.fileList.length > 1 && e.file) {
      setCsv([e.file])
      return e && e.fileList
    }
    setCsv(e.fileList)
    return e && e.fileList
  }

  const customCsv = {
    multiple: false,
    onRemove: (file) => setCsv([]),
    beforeUpload: (file) => {
      setCsv(file)
      return false
    },
    csv,
  }

  const fileHeader = [
    { label: 'Client Code', key: 'client.clientCode' },
    { label: 'Which Year', key: 'whichYear' },
    { label: 'Amount', key: 'amount' },
    { label: 'Payment Type', key: 'paymentType' },
    { label: 'Description', key: 'description' },
    { label: 'Status', key: 'status' },
    { label: 'Start Date', key: 'startDate' },
    { label: 'End Date', key: 'endDate' },
    { label: 'Amc Qty', key: 'amcQty' },
    { label: 'Amc Paid For Period', key: 'amcPaidForPeriod' },
    { label: 'Invoice Number', key: 'invoiceNo' },
    { label: 'Next Due Date', key: 'nextDueDate' },
    { label: 'Paid At', key: 'paidAt' },
  ]

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus={true}
            value={selectedKeys[0]}
            placeholder={`Search ${dataIndex}`}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
          />
          <Space>
            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, dataIndex)}
              size='small'
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const columns = [
    {
      title: (
        <div
          className='hidden-client-data'
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          Total Commission Amount : {data?.getAllAmcs?.total.toFixed(2)}
          <span className='hidden-client-code'></span>{' '}
        </div>
      ),
      children: [
        {
          title: 'Client Code',
          dataIndex: 'clientCode',
          key: 'clientCode',
          render: (text, record) => (
            <NavLink to={`/ clients / ${record?.client?.id} `}>
              {record?.client?.clientCode || '-'}
            </NavLink>
          ),
          sorter: (a, b) => a.clientCode - b.clientCode,
          width: '120px',
          ...getColumnSearchProps('clientCodeSearch'),
        },
        {
          title: 'Client Name',
          dataIndex: 'client',
          key: 'client',
          sorter: (a, b) => a.client - b.client,
          render: (client) => (client === 'Total' ? 'Total' : getName(client)),
          width: '120px',
          ...getColumnSearchProps('clientNameSearch'),
        },
        {
          title: 'Associate Name',
          dataIndex: 'associateName',
          key: 'associateName',
          width: '150px',
          ...getColumnSearchProps('associateName'),
          render: (text, record) => (
            <NavLink
              to={`/ users / ${record?.client?.createdBy?.id} `}
            >{`${record?.client?.createdBy?.firstName} ${record?.client?.createdBy?.lastName} `}</NavLink>
          ),
          sorter: (a, b) => a.associateName - b.associateName,
        },
        {
          title: 'Type',
          dataIndex: 'role',
          key: 'role',
          render: (text, record) =>
            record?.client?.createdBy?.role
              ? record?.client?.createdBy.role.split('_').join(' ')
              : '',
          width: '120px',
        },
        {
          title: 'AMC Amount',
          dataIndex: 'amount',
          key: 'amount',
          width: '120px',
          sorter: (a, b) => a.amount - b.amount,
        },
        {
          title: 'Quantity',
          dataIndex: 'amcQty',
          key: 'amcQty',
          width: '100px',
          sorter: (a, b) => a.amcQty - b.amcQty,
        },
        {
          title: 'Payment Type',
          dataIndex: 'paymentType',
          key: 'paymentType',
          width: '100px',
          sorter: (a, b) => a.paymentType - b.paymentType,
        },
        {
          title: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          width: '105px',
          ...getColumnDateProps('startDate'),
        },
        {
          title: 'Paid Date ',
          dataIndex: 'paidAt',
          key: 'paidAt',
          width: '105px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps('paidAt'),
        },
        {
          title: 'Next DueDate',
          dataIndex: 'nextDueDate',
          key: 'nextDueDate',
          width: '125px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps('nextDueDate'),
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: '125px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
          // render: (text, record) => <NavLink to={`/ clients / ${ record.client.id } `}>{record.description}</NavLink>,
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => renderAction(record),
          width: '80px',
        },
      ],
    },
  ]

  const clientsColumns = [
    {
      title: (
        <div
          className='hidden-client-data'
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          Total Commission Amount : {data?.getAllAmcs?.total.toFixed(2)}
          <span className='hidden-client-code'></span>{' '}
        </div>
      ),
      children: [
        {
          title: 'AMC Amount',
          dataIndex: 'amount',
          key: 'amount',
          width: '120px',
          sorter: (a, b) => a.amount - b.amount,
        },
        {
          title: 'Quantity',
          dataIndex: 'amcQty',
          key: 'amcQty',
          width: '100px',
          sorter: (a, b) => a.amcQty - b.amcQty,
        },
        {
          title: 'Payment Type',
          dataIndex: 'paymentType',
          key: 'paymentType',
          width: '100px',
          sorter: (a, b) => a.paymentType - b.paymentType,
        },
        {
          title: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          width: '105px',
          ...getColumnDateProps('startDate'),
        },
        {
          title: 'Paid Date ',
          dataIndex: 'paidAt',
          key: 'paidAt',
          width: '105px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps('paidAt'),
        },
        {
          title: 'Next DueDate',
          dataIndex: 'nextDueDate',
          key: 'nextDueDate',
          width: '125px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
          ...getColumnDateProps('nextDueDate'),
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          width: '125px',
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          sorter: (a, b) => a.age - b.age,
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: '120px',
          sorter: (a, b) => a.age - b.age,
          // render: (text, record) => <NavLink to={`/ clients / ${ record.client.id } `}>{record.description}</NavLink>,
        },
      ],
    },
  ]

  return (
    <>
      {showFormModal && (
        <CreateAMCModal
          data={updateData}
          isUpdate={isUpdate}
          visible={showFormModal}
          onUpdate={handleUpdate}
          loading={showFormLoading}
          onCancel={() => {
            setShowFormModal(false)
            setUpdateData(undefined)
            setIsUpdate(false)
          }}
        />
      )}

      {!loading && (
        <PageHeader
          className='box'
          title='AMCs'
          extra={[
            <Button
              key='1'
              type='primary'
              onClick={handlePdfDownload}
              loading={PDFLoading}
            >
              Export Pdf
            </Button>,
            <Button
              key='2'
              type='primary'
              onClick={handleCsvDownload}
              loading={CSVLoading}
            >
              Export Csv
            </Button>,
            <Button key='3' type='primary' onClick={showModal}>
              Import Bulk AMCs
            </Button>,
          ]}
        />
      )}

      <Table
        loading={loading}
        dataSource={tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        columns={isClient ? clientsColumns : columns}
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
        }}
      />

      {isModalVisible && (
        <Modal
          title='Import Bulk AMCs'
          visible
          onOk={importBulkAMCs}
          onCancel={importBulkAmcsModalClose}
          footer={[
            <Button key='1' type='secondary' style={{ marginRight: '24%' }}>
              <CSVLink
                data={data?.getAllAmcs?.amc.map((ele) => {
                  return {
                    ...ele,
                    startDate: moment(ele.startDate).format('DD-MM-YYYY'),
                    endDate: moment(ele.endDate).format('DD-MM-YYYY'),
                    nextDueDate: moment(ele.nextDueDate).format('DD-MM-YYYY'),
                    paidAt: moment(ele.paidAt).format('DD-MM-YYYY'),
                  }
                })}
                headers={fileHeader}
                filename='ImportAmc'
              >
                {' '}
                Download Demo AMCs{' '}
              </CSVLink>
            </Button>,
            <Button key='back' onClick={importBulkAmcsModalClose}>
              Cancel
            </Button>,
            <Button
              key='submit'
              loading={AMCLoading}
              type='primary'
              onClick={importBulkAMCs}
            >
              Ok
            </Button>,
          ]}
        >
          <Form form={form} layout='vertical'>
            <Form.Item
              name='csv'
              label='Please select AMCs CSV file'
              valuePropName='csv'
              getValueFromEvent={normFile}
            >
              <Upload
                name='csv-file'
                listType='text'
                {...customCsv}
                accept='.csv'
                multiple={false}
                fileList={csv}
              >
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
                <u>
                  <p>Instructions</p>
                </u>
                <ul>
                  <li>Use Demo CSV to import AMCs.</li>
                </ul>
              </Upload>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}

import gql from 'graphql-tag'

export const CREATE_USER_REVENUE = gql`
mutation createUserRevenue($data: createUserRevenueInput!){
  createUserRevenue(data: $data) {
    id
  }
}
`

export const UPDATE_USER_REVENUE = gql`
mutation updateUserRevenue($data: updateData, $where: updateUserRevenueUniqueId){
  updateUserRevenue(data: $data, where: $where) {
    result
  }
}
`

export const DELETE_USER_REVENUE = gql`
mutation deleteUserRevenue($where: deleteUserRevenueWhereUniqInput){
  deleteUserRevenue(where: $where) {
    id
  }
}
`

export const CREATE_USER = gql`
mutation createUser($data: UserCreateInput!){
  createUser(data: $data) {
    id
  }
}
`

export const UPDATE_USER = gql`
mutation updateUser($data: UserUpdateInput!, $id: ID!){
  updateUser(data: $data, where: { id: $id }) {
    id
    firstName
    lastName
    email
    password
    cvlStatus
    annualIncome
    phone
    address
    cityId
    stateId
    countryId
    motherName
    fatherName
    dateOfBirth
    userName
    maritalStatus
    plan {
      id
      name
    }
    isActive
    role
    keyId
    isAuto
    isAssociate
    fundReceived
    isKycDone
    completedSteps
    amcStartDate
    sipStartDate
    closedDate
    initialExposure
    exposure
    initialInvestment
    clientCode
    strategyCode
    panCardNo
    createdBy {
      id
      firstName
      lastName
      email
      phone
    }
    updatedBy {
      id
    }
    token
    tokenExpiration
    disableDate
    isDisableDate
    createdAt
    updatedAt
    loginType
    isPanCardIndividual
    gender
    nationality
    residentialStatus
    sourceOfIncome
    politicallyExposed
    countryOfTax
    clientScore
    kyc {
      id
      panCard
      aadharCard
      passportSize
      signature
      cancelledChequeSnap
      bankStatement
    }
    reEvaluate
    registrationDate
    investmentDate
    amcInPer
    sourceOfLead
    associateIntroducer
    invoiceStatus
    riskProfileType
    discountStatus
  }
}
`

export const UPDATE_KYC = gql`
mutation updateKyc($panCard: Upload, $aadharCard: Upload, $passportSize: Upload, $signature: Upload, $cancelledChequeSnap: Upload, $bankStatement: Upload, $id: Int!){
  updateKyc(
    data: { panCard: $panCard, aadharCard: $aadharCard, passportSize: $passportSize, signature: $signature, cancelledChequeSnap: $cancelledChequeSnap, bankStatement: $bankStatement },
    where: { userId: $id }
  ) {
    id
  }
}
`

export const CHANGE_PASSWORD = gql`
mutation changePassword($oldPassword: String!, $newPassword: String!){
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
`

export const CREATE_AMC = gql`
mutation createAmc($data: AmcCreateInput!){
  createAmc(data: $data) {
    id
  }
}
`

export const UPDATE_AMC = gql`
mutation updateAmc($data: AmcUpdateInput!, $id: ID!){
  updateAmc(data: $data, where: { id: $id }) {
    id
  }
}
`

export const DELETE_AMC = gql`
mutation deleteAmc($id: ID!){
  deleteAmc(where: { id: $id }) {
    id
  }
}`

export const CREATE_SIP = gql`
mutation createSip($data: SipCreateInput!){
  createSip(data: $data) {
    id
  }
}
`

export const UPDATE_SIP = gql`
mutation updateSip($data: SipUpdateInput!, $id: ID!){
  updateSip(data: $data, where: { id: $id }) {
    id
  }
}
`

export const DELETE_SIP = gql`
mutation deleteSip($id: ID!){
  deleteSip(where: { id: $id }) {
    id
  }
}
`
export const IMPORT_USERS = gql`
mutation importUsers($data: importUserData!){
  importUsers(data: $data)
}
`

export const IMPORT_GROUP = gql`
mutation importGroup($data: ImportGroupInput!){
  importGroup(data: $data)
}
`

export const GENERATE_CSV = gql`
mutation generateCSV($data: generateCSVInput!){
  generateCSV(data: $data)
}
`
export const IMPORT_AMC = gql`
mutation importAmc($data:AmcWhereImportAmcInput!){
  importAmc(data:$data)
}`
export const SEND_OTP_FOR_REEVALUATE = gql`
mutation{
  sendOtpForReEvaluate
}
`

export const VERIFY_OTP_FOR_REEVALUATE = gql`
mutation verifyOtpForReEvaluate($otp:String!){
  verifyOtpForReEvaluate(otp:$otp)
}
`

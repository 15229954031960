import {
  Form,
  Input,
  Tooltip,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Upload,
  InputNumber,
  Switch,
} from 'antd'
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons'
import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import _find from 'lodash/find'
import get from 'lodash/get'
import moment from 'moment'

import {
  GET_USERS,
  GET_MEMBER_NAME,
  GET_LEAD_USERS,
} from 'modules/Users/graphql/Queries'
import { GET_PLANS } from 'modules/Plans/graphql/Query'
import countries from 'utils/countries.json'
import { AppContext } from 'AppContext'
import states from 'utils/states.json'
import cities from 'utils/cities.json'
import { getName } from 'utils/User'
import client from 'apollo'
import '../clients.css'
import Loader from 'components/loaders/Loader'

const { Option } = Select
const defaultSteps = [
  { key: 'LOGIN_CREATED', value: 'LOGIN_CREATED' },
  { key: 'CLIENT_PROFILE_1', value: 'CLIENT_PROFILE_1' },
  { key: 'CLIENT_PROFILE_2', value: 'CLIENT_PROFILE_2' },
  { key: 'RISK_PROFILE', value: 'RISK_PROFILE' },
  { key: 'KYC_DOC', value: 'KYC_DOC' },
  { key: 'KYC_FORM', value: 'KYC_FORM' },
  // { key: 'CLIENT_CONSENT', value: 'CLIENT_CONSENT' },
  { key: 'INVESTMENT_PLANNING', value: 'INVESTMENT_PLANNING' },
  { key: 'AGREEMENT_SIGN', value: 'AGREEMENT_SIGN' },
  { key: 'DONE', value: 'DONE' },
]

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } },
}

function disabledDate(current) {
  return current && current > moment().endOf('day')
}

const ClientForm = (props) => {
  const {
    state: { isStaff, isAdmin, isClient },
  } = useContext(AppContext) // currentUser
  const { onFinish, loading, isUpdate } = props
  let { initialValues } = props
  const [selectedState, setSelectedState] = useState(
    isUpdate && initialValues.stateId
      ? states.filter((state) => state.id === initialValues.stateId)[0].id
      : undefined
  )
  const [selectedCountry, setSelectedCountry] = useState(
    isUpdate && initialValues.countryId ? initialValues.countryId : undefined
  )
  const [fileList, setFileList] = useState(undefined)
  const [users, setUsers] = useState(undefined)
  const [members, setMembers] = useState(undefined)
  const [plans, setPlans] = useState([])
  const [leadUsers, setLeadUsers] = useState(undefined)
  const [disableChecked, setDisableChecked] = useState(
    (initialValues && initialValues.isDisableDate) || false
  )
  const [selectedUser, setSelectedUser] = useState(
    (initialValues && initialValues.createdById) || undefined
  )
  const [form] = Form.useForm()
  const { data: memberData, loading: memberLoading } = useQuery(
    GET_MEMBER_NAME,
    { fetchPolicy: 'network-only' }
  )
  const { data: getPlans, loading: planLoading } = useQuery(GET_PLANS, {
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (!memberLoading && get(memberData, 'getMemberName')) {
      setMembers(get(memberData, 'getMemberName'))
    }
  }, [memberData, memberLoading])

  useEffect(() => {
    if (!planLoading && get(getPlans, 'getPlans')) {
      setPlans(get(getPlans, 'getPlans'))
    }
  }, [getPlans, planLoading])

  useEffect(() => {
    if (isAdmin || isStaff) {
      client
        .query({
          query: GET_USERS,
          variables: {
            limit: 300,
            where: {
              role: [
                'ADMIN',
                'MASTER_ASSOCIATE',
                'ASSOCIATE',
                'SALES_EXECUTIVE',
                'STAFF',
              ],
            },
          },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          if (get(res, 'data.getUsers.users')) {
            setUsers(get(res, 'data.getUsers.users'))
          }
        })
        .catch((err) => {
          console.log(err)
          setUsers([])
        })
    }
  }, [isAdmin, isStaff])

  // useEffect(() => {
  //   if (!isStaff && isAdmin && !isUpdate) {
  //     client.query({ query: GET_LEAD_USERS, variables: { userId: parseInt(currentUser.id) }, fetchPolicy: 'network-only' })
  //       .then((res) => {
  //         if (get(res, 'data.getLeadUsers')) {
  //           setLeadUsers(get(res, 'data.getLeadUsers'))
  //         }
  //       })
  //       .catch((err) => console.log(err))
  //   }
  // }, [currentUser, isAdmin, isStaff, isUpdate])

  useEffect(() => {
    setLeadUsers(undefined)
    if (selectedUser && users) {
      function getLeadUsers(userId) {
        client
          .query({
            query: GET_LEAD_USERS,
            variables: { userId },
            fetchPolicy: 'network-only',
          })
          .then((res) => {
            if (get(res, 'data.getLeadUsers')) {
              let find = []
              if (users) {
                find = _find(users, (user) => user.id === selectedUser)
                setLeadUsers([
                  {
                    id: find.id,
                    role: find.role,
                    name: getName(find),
                    username: find.userName,
                    level: get(res, 'data.getLeadUsers.length'),
                  },
                  ...get(res, 'data.getLeadUsers'),
                ])
              } else {
                setLeadUsers(get(res, 'data.getLeadUsers'))
              }
            } else {
              setLeadUsers(undefined)
            }
          })
          .catch((err) => setLeadUsers(undefined))
      }
      getLeadUsers(parseInt(selectedUser))
    }
  }, [selectedUser, users])

  const normFile = (e) => {
    setFileList([])
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const customProps = {
    multiple: false,
    onRemove: (file) => setFileList([]),
    beforeUpload: (file) => {
      setFileList(file)
      return false
    },
    fileList,
  }

  initialValues = {
    ...initialValues,
    plan: initialValues?.plan?.name || null,
    collateralValue: initialValues?.collateralValue || '0',
  }

  if (!users) return <Loader />

  return (
    <Form
      {...formItemLayout}
      form={form}
      name='register'
      scrollToFirstError
      onFinish={onFinish}
      className='client-form'
      initialValues={initialValues || {}}
      // initialValues={{
      //   firstName: 'parth',
      //   lastName: 'patel',
      //   userName: 'parth',
      //   email: 'patel21parth@gmail.com',
      //   phone: '9033174273',
      //   dateOfBirth: moment(),
      //   password: '0123456789',
      //   confirm: '0123456789',
      //   motherName: 'motherName',
      //   fatherName: 'fatherName',
      //   address: 'address',
      //   clientCode: 'STR015',
      //   strategyCode: 'ILTS',
      //   amcAmount: 5000,
      //   amcStartDate: moment(),
      //   sipAmount: 5000,
      //   sipStartDate: moment(),
      //   initialExposure: 500,
      //   exposure: 500,
      //   closedDate: moment(),
      //   createdBy: moment(),
      //   remark: 'dhruv',
      //   plan: 'dhruv',
      //   collateralValue: 'lk'
      // }}
    >
      <Row gutter={16}>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='firstName'
            label='First Name'
            rules={[
              { required: true, message: 'Please input your First Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter First Name'
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='lastName'
            label='Last Name'
            rules={[
              { required: true, message: 'Please input your Last Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter Last Name'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='userName'
            autoComplete='new-password'
            rules={[
              {
                required: true,
                message: 'Please input your valid username!',
              },
            ]}
            label={
              <span>
                Username&nbsp;{' '}
                <Tooltip title='Choose your unique username for login.'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input
              autoComplete='new-password'
              placeholder='Enter Username'
              disabled={isUpdate ? true : false}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='email'
            label='E-mail'
            rules={[
              { required: true, message: 'Please input your E-mail!' },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
                pattern: new RegExp(/^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
              },
            ]}
          >
            <Input
              placeholder='Enter Your Email'
              disabled={isClient}
              style={{ textTransform: 'lowercase' }}
            />
          </Form.Item>
        </Col>

        {!isUpdate && (
          <Fragment>
            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                hasFeedback
                name='password'
                label='Password'
                autoComplete='new-password'
                rules={[
                  {
                    required: true,
                    message:
                      'Please input your password between 8 to 16 characters!',
                    min: 8,
                    max: 16,
                  },
                ]}
              >
                <Input.Password
                  placeholder='Enter Your Password'
                  autoComplete='new-password'
                  disabled={isClient}
                />
              </Form.Item>
            </Col>

            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                hasFeedback
                name='confirm'
                label='Confirm Password'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      )
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder='Enter Confirm Password'
                  disabled={isClient}
                />
              </Form.Item>
            </Col>
          </Fragment>
        )}

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='phone'
            label='Phone Number'
            rules={[
              { required: true, message: 'Please input your phone number!' },
            ]}
          >
            <Input
              autoComplete='off'
              type='number'
              style={{ width: '100%' }}
              placeholder='Enter Phone number'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='dateOfBirth'
            label='Date of Birth'
            rules={[
              { required: true, message: 'Please input your Date of Birth!' },
            ]}
          >
            <DatePicker
              inputReadOnly
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              Date={disabledDate}
              showToday={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        {/* <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='folderPath'
            label='Folder Path'
            rules={[{ required: true, message: 'Please input your Folder Path!', whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Col> */}

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='motherName'
            label='Mother Name'
            rules={[
              { required: true, message: 'Please input your Mother Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder={`Enter Mother's Name`}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='fatherName'
            label='Father Name'
            rules={[
              { required: true, message: 'Please input your Father Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder={`Enter Father's Name`}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='address'
            label='Address'
            rules={[{ required: true, message: 'Please enter your Address!' }]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter Address'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='countryId'
            label='Select your country'
            rules={[{ required: true, message: 'Please Select your country!' }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select your country'
              onChange={(value) => {
                setSelectedCountry(value)
                form.setFieldsValue({
                  stateId: undefined,
                  cityId: undefined,
                })
              }}
              disabled={isClient}
            >
              {countries.map((country) => (
                <Option key={country.id} value={country.id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='stateId'
            label='Select your state'
            rules={[{ required: true, message: 'Please Select your state!' }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select your state'
              notFoundContent='Please Select Your Country First!'
              onChange={(value) => {
                setSelectedState(
                  states.filter((state) => state.id === value)[0].id
                )
                form.setFieldsValue({
                  cityId: undefined,
                })
              }}
              disabled={isClient}
            >
              {!!selectedCountry &&
                states.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='cityId'
            label='Select your city'
            rules={[{ required: true, message: 'Please Select your city!' }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select your city'
              notFoundContent='Please Select Your State First!'
              disabled={isClient}
            >
              {!!selectedState &&
                cities
                  .filter((city) => city.state_id === selectedState)
                  .map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='clientCode'
            label='Client Code'
            rules={[
              { required: true, message: 'Please input your Client Code!' },
            ]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder='Enter Client Code'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='strategyCode'
            label='Strategy Code'
            rules={[
              { required: true, message: 'Please input your Strategy Code!' },
            ]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder='Enter Strategy Code'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='amcAmount'
            label='AMC Amount'
            // rules={[{ required: true, message: 'Please input your AMC Amount!' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder='Enter AMC Amount'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='amcStartDate'
            label='AMC Start Date'
            // rules={[{ required: true, message: 'Please input your AMC Start Date!' }]}
          >
            <DatePicker
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='sipAmount'
            label='SIP Amount'
            // rules={[{ required: true, message: 'Please input your SIP Amount!' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder='Enter SIP Amount'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='sipStartDate'
            label='SIP Start Date'
            // rules={[{ required: true, message: 'Please input your SIP Start Date!' }]}
          >
            <DatePicker
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='initialExposure'
            label='Initial Exposure'
            rules={[
              {
                required: true,
                message: 'Please input your Initial Exposure!',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder='Enter Initial Exposure'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='exposure'
            label='Exposure'
            rules={[{ required: true, message: 'Please input your Exposure!' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder='Enter Exposure'
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='completedSteps' label='User Completed Step'>
            <Select placeholder='User Completed Step' disabled={isClient}>
              {defaultSteps.map((defaultStep) => (
                <Option key={defaultStep.key} value={defaultStep.key}>
                  {defaultStep.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='closedDate'
            label='Closed Date'
            // rules={[{ required: true, message: 'Please input your Closed Date!' }]}
          >
            <DatePicker
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item name='createdById' label='Select User'>
            {!users ? (
              <Select
                showSearch
                allowClear
                loading={!users}
                placeholder='Select User'
                optionFilterProp='children'
                autoComplete='something-new'
                onChange={(value) => setSelectedUser(value)}
                disabled
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {getName(user)}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Select
                showSearch
                allowClear
                loading={!users}
                placeholder='Select User'
                optionFilterProp='children'
                autoComplete='something-new'
                onChange={(value) => setSelectedUser(value)}
                disabled={isClient}
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {getName(user)}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        {selectedUser && leadUsers && (
          <Col className='gutter-row' xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row
              style={{
                padding: 24,
                margin: '24px 0',
                background: '#fbfbfb',
                border: '1px solid #d9d9d9',
              }}
            >
              <Col
                className='gutter-row'
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <div
                  style={{
                    fontSize: 16,
                    marginBottom: 6,
                    textAlign: 'center',
                  }}
                >
                  Revenue Details
                </div>
              </Col>
              {leadUsers.map((leadUser, key) => {
                if (leadUser.role === 'ADMIN') return false
                return (
                  <Col
                    className='gutter-row'
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    key={key}
                  >
                    <Form.Item
                      name={`ratio_${leadUser.id}`}
                      label={`${leadUser.name}'s Ratio (${
                        leadUser?.role?.replace('_', ' ') || ''
                      })`}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your ratio!',
                        },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        placeholder='Enter Ratio'
                        style={{ width: '100%' }}
                        disabled={isClient}
                        // parser={value => value.replace('%', '')}
                        // formatter={value => value ? `${value}%` : ''}
                      />
                    </Form.Item>
                  </Col>
                )
              })}
            </Row>
          </Col>
        )}
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='memberName'
            label='Select Member'
            rules={[{ required: true, message: 'Please Select Member!' }]}
          >
            <Select
              showSearch
              loading={memberLoading}
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Select Member'
              disabled={isClient}
            >
              {members &&
                members.map((member) => (
                  <Option key={member.ID} value={member.ID}>
                    {member.MemberCode}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        {!isClient && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='plan'
              label='Select Plan'
              rules={[{ message: 'Please Select Plan!' }]}
            >
              <Select
                showSearch
                loading={planLoading}
                optionFilterProp='children'
                autoComplete='something-new'
                placeholder='Select Plan'
              >
                {plans &&
                  plans.map((plan) => {
                    return (
                      <Option key={plan.id} value={plan.id}>
                        {plan.name}
                      </Option>
                    )
                  })}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['remark']} label='Remark'>
            <Input disabled={isClient} />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name={['collateralValue']} label='Collateral Value'>
            <Input style={{ width: '100%' }} disabled={isClient} />
          </Form.Item>
        </Col>

        {!isUpdate && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name='createdAt' label='Created Date'>
              <DatePicker
                format={'DD-MMM-YYYY'}
                autoComplete='off'
                style={{ width: '100%' }}
                disabled={isClient}
              />
            </Form.Item>
          </Col>
        )}
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='registrationDate' label='Registration Date'>
            <DatePicker
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='investmentDate' label='Investment Date'>
            <DatePicker
              format={'DD-MMM-YYYY'}
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='amcInPer' label='AMC in Percentage'>
            <Input
              placeholder='Enter AMC Percentage'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='sourceOfLead' label='Source of Lead'>
            <Input
              placeholder='Enter Source of Lead'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='associateIntroducer' label='Associate Introducer'>
            <Input
              placeholder='Enter Associate Introducer'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='invoiceStatus' label='Invoice Status'>
            <Input
              placeholder='Enter Invoice Status'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='riskProfileType' label='Risk Profile Type'>
            <Select placeholder='Select Risk Profile Type' disabled={isClient}>
              <Option value={'High Risk'}> High Risk </Option>
              <Option value={'Moderate Risk'}> Moderate Risk </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item name='discountStatus' label='Discount Status'>
            <Input
              placeholder='Enter Discount Status'
              autoComplete='off'
              style={{ width: '100%' }}
              disabled={isClient}
            />
          </Form.Item>
        </Col>
        {isUpdate && !isClient && (
          <>
            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <div
                style={{
                  fontSize: 14,
                  margin: '8px 0',
                  color: '#535353',
                }}
              >
                Disable User:
              </div>
              <Form.Item name='isDisableDate' style={{ margin: '8px -12px' }}>
                <Switch
                  defaultChecked={disableChecked}
                  onClick={() => setDisableChecked(!disableChecked)}
                  disabled={isClient}
                />
              </Form.Item>

              {disableChecked && (
                <Form.Item
                  name='disableDate'
                  style={{ margin: '8px -12px' }}
                  rules={[
                    { required: true, message: 'Please input disable date!' },
                  ]}
                >
                  <DatePicker
                    format={'DD-MMM-YYYY'}
                    placeholder='Select Disable Date'
                    autoComplete='off'
                    showToday={false}
                    style={{ width: '100%' }}
                    disabled={isClient}
                  />
                </Form.Item>
              )}
            </Col>
            <Col
              className='gutter-row'
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
            ></Col>
          </>
        )}

        {!isUpdate && !users && (
          <Col
            className='gutter-row'
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          ></Col>
        )}

        {/* {
          leadUsers &&
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row
              style={{
                border: '1px solid #003365',
                borderRadius: '10px',
                padding: '24px 0',
                margin: '24px 0',
              }}
            >
              {
                leadUsers.map((leadUser) => {
                  return (
                    <>
                      <Col className='gutter-row' xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          name='names'
                          label='Name'
                        >
                          <Input autoComplete='off' disabled={isClient} defaultValue={getName(leadUser)} />
                        </Form.Item>
                      </Col>
                      <Col className='gutter-row' xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          name='userNames'
                          label='UserName'
                        >
                          <Input autoComplete='off' disabled={isClient} defaultValue={getName(leadUser)} />
                        </Form.Item>
                      </Col>
                      <Col className='gutter-row' xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Form.Item
                          name={`ratio_${leadUser.id}`}
                          label='Ratio'
                          rules={[{ required: true, message: 'Please input your ratio!' }]}
                        >
                          <InputNumber
                            min={0}
                            max={100}
                            placeholder='Enter Ratio'
                            style={{ width: '100%' }}
                            parser={value => value.replace('%', '')}
                            formatter={value => value ? `${value}%` : ''}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )
                })
              }
            </Row>
          </Col>
        } */}

        {/* <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='createdBy'
            label='Assign to'
            rules={[{ required: true, message: 'Please Select Assignee!' }]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              autoComplete='something-new'
              placeholder='Assign to'
            >
              {!!users && users.map((user) => <Option key={user.id} value={user.id}>{getName(user)}</Option>)}
              <Option value="lucy">Lucy</Option>
            </Select>
          </Form.Item>
        </Col> */}

        {!isClient && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='panCard'
              label='PanCard'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='panCard-file'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button disabled={isClient}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        {!isClient && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='signature'
              label='Signature'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='signature-file'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button disabled={isClient}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        {!isClient && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='aadharCard'
              label='Aadhar Card'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='aadharCard-file'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button disabled={isClient}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        {!isClient && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='passportSize'
              label='Passport Size Photo'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='passportSize-file'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button disabled={isClient}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        {!isClient && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='bankStatement'
              label='Bank Statement'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='bankStatement-file'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button disabled={isClient}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        {!isClient && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='cancelledChequeSnap'
              label='Cancelled Cheque'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                name='cancelledChequeSnap-file'
                listType='text'
                {...customProps}
                accept='.jpeg,.png,.jpg,.pdf,.docx,.doc'
              >
                <Button disabled={isClient}>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        )}

        <Col className='gutter-row' span={24}>
          <Form.Item {...tailFormItemLayout}>
            {!users ? (
              <Button type='primary' htmlType='submit' disabled>
                Save
              </Button>
            ) : (
              <Button
                type='primary'
                htmlType='submit'
                loading={loading}
                disabled={isClient}
              >
                Save
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default ClientForm

import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
  Form,
  Input,
  Tooltip,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import moment from 'moment'

import { GET_USERS } from 'modules/Users/graphql/Queries'
import { userRoles, getName } from 'utils/User'
import { AppContext } from 'AppContext'
import client from 'apollo'
import '../users.css'
import Loader from 'components/loaders/Loader'

const { Option } = Select

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
}

const tailFormItemLayout = {
  wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } },
}

function disabledDate(current) {
  return current && current > moment().endOf('day')
}

const UserForm = (props) => {
  const { onFinish, initialValues, loading, isUpdate, isProfilePage } = props
  const [users, setUsers] = useState(undefined)
  const [loadingUser, setLoadingUser] = useState(false)
  const { state } = useContext(AppContext)
  const [form] = Form.useForm()
  const { isStaff, isAdmin } = state
  const userFilters = userRoles[state.currentUser.role]

  useEffect(() => {
    setLoadingUser(true)
    if (isAdmin || isStaff) {
      client
        .query({
          query: GET_USERS,
          variables: {
            limit: 300,
            where: {
              role: [
                'ADMIN',
                'STAFF',
                'MASTER_ASSOCIATE',
                'ASSOCIATE',
                'SALES_EXECUTIVE',
              ],
            },
          },
          fetchPolicy: 'network-only',
        })
        .then((res) => {
          setLoadingUser(false)
          if (get(res, 'data.getUsers.users')) {
            if (isUpdate) {
              setUsers(
                get(res, 'data.getUsers.users').filter(
                  (user) => user.id !== initialValues.id
                )
              )
            } else {
              setUsers(get(res, 'data.getUsers.users'))
            }
          }
        })
        .catch((err) => {
          setLoadingUser(false)
          console.log(err)
          setUsers([])
        })
    }
  }, [initialValues, isAdmin, isStaff, isUpdate])
  if (loadingUser) return <Loader />

  return (
    <Form
      {...formItemLayout}
      form={form}
      name='register'
      onFinish={onFinish}
      initialValues={initialValues || {}}
      className='user-form'
      loading={loadingUser}
      scrollToFirstError
    >
      <Row gutter={16}>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='firstName'
            label='First Name'
            rules={[
              { required: true, message: 'Please input your First Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter First Name'
            />
          </Form.Item>
        </Col>
        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='lastName'
            label='Last Name'
            rules={[
              { required: true, message: 'Please input your Last Name!' },
            ]}
          >
            <Input
              style={{ textTransform: 'capitalize' }}
              placeholder='Enter Last Name'
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='userName'
            autoComplete='new-password'
            rules={[
              { required: true, message: 'Please input your valid username!' },
            ]}
            label={
              <span>
                Username&nbsp;
                <Tooltip title='Choose your unique username for login.'>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input
              autoComplete='new-password'
              placeholder='Enter Username'
              disabled={isProfilePage || isUpdate}
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='email'
            label='E-mail'
            rules={[
              { required: true, message: 'Please input your E-mail!' },
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
                pattern: new RegExp(/^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
              },
            ]}
          >
            <Input
              placeholder='Enter Your Email'
              style={{ textTransform: 'lowercase' }}
            />
          </Form.Item>
        </Col>

        {!isUpdate && (
          <Fragment>
            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name='password'
                label='Password'
                autoComplete='new-password'
                rules={[
                  {
                    required: true,
                    message:
                      'Please input your password between 8 to 16 characters!',
                    min: 8,
                    max: 16,
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  autoComplete='new-password'
                  placeholder='Enter Your Password'
                />
              </Form.Item>
            </Col>

            <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name='confirm'
                label='Confirm Password'
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      )
                    },
                  }),
                ]}
              >
                <Input.Password placeholder='Enter Confirm Password' />
              </Form.Item>
            </Col>
          </Fragment>
        )}
        {!isProfilePage && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name='role'
              label='Choose Role'
              rules={[
                {
                  required: true,
                  message: 'Please choose your Role!',
                  whitespace: true,
                },
              ]}
            >
              <Select placeholder='Choose Role'>
                {userFilters.map((role) => (
                  <Option key={role} value={role.value}>
                    {role.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='phone'
            label='Phone Number'
            rules={[
              { required: true, message: 'Please input your phone number!' },
            ]}
          >
            <Input
              type='number'
              autoComplete='off'
              style={{ width: '100%' }}
              placeholder='Enter Phone number'
            />
          </Form.Item>
        </Col>

        <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='dateOfBirth'
            label='Date of Birth'
            rules={[
              { required: true, message: 'Please input your Date of Birth!' },
            ]}
          >
            <DatePicker
              autoComplete='off'
              format={'DD-MMM-YYYY'}
              disabledDate={disabledDate}
              showToday={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        {!(isUpdate && initialValues.role === 'ADMIN') && (
          <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name='createdById' label='Select User'>
              <Select
                showSearch
                loading={!users}
                placeholder='Select user'
                optionFilterProp='children'
                autoComplete='something-new'
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {getName(user)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {/* <Col className='gutter-row' xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item
            name='folderPath'
            label='Folder Path'
            rules={[{ required: true, message: 'Please input your Folder Path!', whitespace: true }]}
          >
            <Input />
          </Form.Item>
        </Col> */}
        <Col className='gutter-row' span={24}>
          <Form.Item {...tailFormItemLayout}>
            <Button type='primary' htmlType='submit' loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default UserForm

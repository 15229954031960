import React from 'react'
import { Table } from 'antd'

const columns = [
  {
    title: 'Comparison of Fund with Index Report',
    className: 'report-title main-title',
    children: [
      {
        title: 'ReportDate',
        dataIndex: 'ReportDate',
        key: 'ReportDate',
        className: 'report-text',
        width: '100px'
      },
      {
        title: 'NIFTY',
        dataIndex: 'NIFTY',
        key: 'NIFTY',
        className: 'report-numeric',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'NLV',
        dataIndex: 'NLV',
        key: 'NLV',
        className: 'report-numeric',
        width: '60px',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'TotalExposure',
        dataIndex: 'TotalExposure',
        key: 'TotalExposure',
        className: 'report-numeric',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'NetAmt',
        dataIndex: 'NetAmt',
        key: 'NetAmt',
        className: 'report-numeric',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'Final_NLV',
        dataIndex: 'Final_NLV',
        key: 'Final_NLV',
        className: 'report-numeric',
        width: '100px',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'PnlWithOutInterest',
        dataIndex: 'PnlWithOutInterest',
        key: 'PnlWithOutInterest',
        className: 'report-numeric',
        width: '135px',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'TotExposureWithNifty',
        dataIndex: 'TotExposureWithNifty',
        key: 'TotExposureWithNifty',
        className: 'report-numeric',
        width: '150px',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'Interest',
        dataIndex: 'Interest',
        key: 'Interest',
        className: 'report-numeric',
        width: '70px',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'CumInterest',
        dataIndex: 'CumInterest',
        key: 'CumInterest',
        className: 'report-numeric',
        width: '100px',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'TotNLV',
        dataIndex: 'TotNLV',
        key: 'TotNLV',
        className: 'report-numeric',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'NIFTY%',
        dataIndex: 'NIFTY%',
        key: 'NIFTY%',
        width: '70px',
        className: 'report-percentage',
        render: (text) => text ? Math.round(text) : text
      },
      {
        title: 'TotNLV%',
        dataIndex: 'TotNLV%',
        key: 'TotNLV%',
        className: 'report-percentage',
        width: '70px',
        render: (text) => text ? Math.round(text) : text
      }
    ]
  }
]

export default function ({ reportData, id }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  if (id === 'NLV-TRACKER-REPORT-DOWNLOAD-PDF') {
    return (
      <Table
        dataSource={data}
        columns={columns}
        className='nlv-tracker-report'
        pagination={false}
      />
    )
  } else {
    return (
      <Table
        dataSource={data}
        columns={columns}
        className='nlv-tracker-report'
        pagination={{ pageSize: 1000, hideOnSinglePage: true }}
        scroll={{ x: true, y: 'calc(100vh - 24px - 24px - 72px - 36px - 35px)' }}
      />
    )
  }
}

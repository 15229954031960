import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import GoogleButton from 'react-google-button'

import { LOGIN_SIGNUP_WITH_GOOGLE } from 'auth/graphql/Mutations'
import Logo from 'assets/images/logo-tagline.png'
import CustomMeta from 'components/CustomMeta'
import { LOGIN } from './graphql/Mutations'
import { authLogin } from '.'
import client from 'apollo'
import './index.css'

const FormItem = Form.Item

export default function () {
  const [form] = Form.useForm()
  const [userNameClient, setUserName] = useState()

  const urlQuery = useLocation()
  const userName = urlQuery.search.split('=')[1]
  useEffect(() => {
    userName && setUserName(userName)
  }, [userName])
  const hashName = urlQuery.hash.split('#')[1]
  function handleLogin(values) {
    client
      .mutate({ mutation: LOGIN, variables: { data: values } })
      .then((res) => authLogin(res))
      .catch((err) => console.log(err))
  }

  function handleGoogleLogin() {
    client
      .mutate({ mutation: LOGIN_SIGNUP_WITH_GOOGLE })
      .then((res) => (window.location.href = res?.data?.loginSignupWithGoogle))
      .catch((err) => console.log(err))
  }

  return (
    <div className='gx-app-login-wrap'>
      <CustomMeta title='Login' />
      <div className='gx-app-login-container'>
        <div className='gx-app-login-main-content'>
          <div className='gx-app-logo-content'>
            <div className='gx-app-logo-content-bg'>
              {/* <img src='https://via.placeholder.com/272x395' alt='Nature' /> */}
            </div>
            <div className='gx-app-logo-wid'>
              <h1 className='login-text-color'>Sign In</h1>
              <p className='login-text-color'>
                By Sign In, you can avail full features of our services.
              </p>
            </div>
            <div className='gx-app-logo'>
              <img alt='Finideas' src={Logo} />
            </div>
          </div>
          <div className='gx-app-login-content'>
            <Form
              form={form}
              onFinish={handleLogin}
              className='gx-signin-form gx-form-row0'
              initialValues={{ userName: userName ? userName : '' }}
            >
              <FormItem
                name='userName'
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
                rules={[
                  {
                    required: true,
                    message: 'Please input your valid username!',
                  },
                ]}
              >
                <Input placeholder='Username' autoFocus={true} />
              </FormItem>
              <FormItem
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password!',
                    min: 8,
                    max: 16,
                  },
                ]}
              >
                <Input type='password' placeholder='Password' />
              </FormItem>
              <FormItem>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button type='primary' className='gx-mb-0' htmlType='submit'>
                    Sign In
                  </Button>
                  {!hashName && (
                    <Link to='/login-with-email'>
                      <Button
                        type='primary'
                        className='gx-mb-0'
                        style={{ marginRight: '10px' }}
                      >
                        Sign Up
                      </Button>
                    </Link>
                  )}
                </div>
              </FormItem>
              <hr />
              <FormItem>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <GoogleButton
                    className='google-logo'
                    label='Continue with Google'
                    type='dark'
                    onClick={handleGoogleLogin}
                    style={{
                      height: '35px',
                      width: '200px',
                      borderRadius: '5px',
                    }}
                  />
                </div>
                <span className='forgot-password'>
                  <Link to='/login-with-mobile'>Login With Mobile</Link>
                </span>
              </FormItem>
              <span className='forgot-password'>
                <Link to={`/forgot-password?name=${userNameClient}`}>
                  Forgot Password?
                </Link>
              </span>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import get from 'lodash/get'
import { useQuery } from '@apollo/react-hooks'

import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import Loader from 'components/loaders/Loader'
import DownloadReport from 'modules/Reports/DownloadReport'
import NlvTrackerTable from 'modules/Reports/NLVTracker/NlvTrackerTable'
import { GET_NLV_TRACKER_REPORT } from 'modules/Reports/graphql/Queries'
import 'modules/Reports/index.css'

export default function (props) {
  let { match: { params: { clientCode } } } = props
  const { data, loading, error } = useQuery(GET_NLV_TRACKER_REPORT, { variables: { where: { clientCode }, fetchPolicy: 'network-only' } })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spNLVTracker')) return <NoData />

  const { spNLVTracker: [spNLVTracker] } = data

  return (
    <>
      <DownloadReport data={spNLVTracker.filter((report, key) => key !== 0)} fileName='NLV-Tracker-report' id='NLV-TRACKER-REPORT-PDF' />
      <div className='report-table-wrapper' id='NLV-TRACKER-REPORT-PDF'>
        <NlvTrackerTable reportData={spNLVTracker.filter((report, key) => key !== 0)} />
      </div>
    </>
  )
}

import {
  Button,
  Form,
  Row,
  Col,
  InputNumber,
  Checkbox,
  Select,
  Input,
} from 'antd'
import { useQuery } from '@apollo/react-hooks'
import React, { useContext, useEffect, useState } from 'react'

import { getName } from 'utils/User'
import Page404 from 'components/Page404'
import DownloadReport from '../DownloadReport'
import { GET_ROLL_OVER_REPORT } from '../graphql/Queries'
import RolloverTrackingTable from './RolloverTrackingTable'
import { GET_ALL_CLIENT } from 'modules/Users/graphql/Queries'
import '../index.css'
import client from 'apollo'
import { orderBy } from 'lodash'
import { AppContext } from 'AppContext'
const { Option } = Select

export default function RolloverTrackingReport() {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})
  const [columnName, setColumnName] = useState()
  let [usersData, setUsersData] = useState([])
  const [userLoading, setUserLoading] = useState(true)
  const {
    state: { currentUser, isClient },
  } = useContext(AppContext)

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_ROLL_OVER_REPORT, {
    variables: filters,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    client
      .query({
        query: GET_ALL_CLIENT,
        variables: {
          where: {},
          limit: 10000,
        },
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        res.data.getClientUsers.users.map((user) => {
          return usersData.push(user)
        })
        setUsersData(usersData)
        setUserLoading(false)
      })
      .catch((err) => console.log({ err }))
      .finally(() => setUserLoading(false))
  }, [usersData])

  const { rollOver = [] } = data

  const newColumns = [
    {
      title: 'Select User',
      dataIndex: 'clientCode',
      key: 'clientCode',
      className: 'report-text',
    },
    {
      title: columnName?.diff ? `${columnName?.diff}%` : 'DIff',
      dataIndex: 'diff',
      key: 'diff',
      className: 'report-numeric',
    },
    {
      title: columnName?.diff1 ? `${columnName?.diff1}%` : 'DIff1',
      dataIndex: 'diff1',
      key: 'diff1',
      className: 'report-numeric',
    },
    {
      title: columnName?.diff2 ? `${columnName?.diff2}%` : 'DIff2',
      dataIndex: 'diff2',
      key: 'diff2',
      className: 'report-numeric',
    },
    {
      title: 'Detailed',
      dataIndex: 'detailed',
      key: 'detailed',
      className: 'report-numeric',
    },
    {
      title: 'All Clients',
      dataIndex: 'allClients',
      key: 'allClients',
      className: 'report-numeric',
    },
    {
      title: 'Only shift',
      dataIndex: 'onlyShift',
      key: 'onlyShift',
      className: 'report-numeric',
    },
  ]

  const columns = [
    {
      title: 'Rollover Tracking',
      className: 'report-title main-title',
      children: [
        {
          title: 'Script',
          dataIndex: 'Script',
          key: 'script',
          className: 'report-text',
        },
        {
          title: '5%',
          dataIndex: 'perc5',
          key: 'perc5',
          className: 'report-numeric',
        },
        {
          title: '10%',
          dataIndex: 'perc10',
          key: 'perc10',
          className: 'report-numeric',
        },
        {
          title: '20%',
          dataIndex: 'perc20',
          key: 'perc20',
          className: 'report-numeric',
        },
      ],
    },
  ]

  const columns2 = [
    {
      title: 'cmp',
      dataIndex: 'cmp',
      key: 'cmp',
      className: 'report-numeric',
    },
    {
      title: 'leve',
      dataIndex: 'leve',
      key: 'leve',
      className: 'report-numeric',
    },
    {
      title: 'option_type',
      dataIndex: 'option_type',
      key: 'option_type',
      className: 'report-numeric',
    },
    {
      title: 'shift1',
      dataIndex: 'shift1',
      key: 'shift1',
      className: 'report-numeric',
    },
    {
      title: 'strike_price',
      dataIndex: 'strike_price',
      key: 'strike_price',
      className: 'report-numeric',
    },
  ]

  const onFinish = (values) => {
    setColumnName(values)
    if (!values.allClients) {
      values.allClients = 0
    } else {
      values.allClients = 1
    }

    if (!values.onlyShift) {
      values.onlyShift = 0
    } else {
      values.onlyShift = 1
    }

    if (!values.detailed) {
      values.detailed = 0
    } else {
      values.detailed = 1
    }

    setFilters({
      where: values,
    })
  }

  const ClientShift =
    filters?.where?.allClients && filters?.where?.onlyShift ? columns2 : columns

  return (
    <>
      {!error && rollOver && (
        <DownloadReport
          data={data.rollOver}
          fileName='ROLLOVER-TRACKING-report'
          id='ROLLOVER-TRACKING-REPORT-PDF'
        />
      )}
      <br />
      <br />
      <Row>
        <Col span={24}>
          <Form
            form={form}
            onFinish={onFinish}
            name='advanced_search'
            initialValues={{
              diff: 20,
              diff1: 10,
              diff2: 5,
              onlyShift: true,
              detailed: true,
              clientCode: !isClient
                ? usersData[0]?.userName
                : `${currentUser?.clientCode}-${currentUser?.firstName} ${currentUser?.lastName}`,
            }}
          >
            <Row>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  hasFeedback
                  name='clientCode'
                  label='Select Users'
                  rules={[{ required: true, message: 'Please select users!' }]}
                >
                  {!isClient ? (
                    <Select
                      allowClear
                      showSearch
                      filterOption={true}
                      optionFilterProp='title'
                      placeholder='Please select users'
                    >
                      {!userLoading &&
                        usersData.length &&
                        orderBy(usersData, 'id', 'desc').map((user, key) => {
                          return (
                            user.clientCode && (
                              <>
                                <Option
                                  key={key}
                                  value={user.clientCode}
                                  title={`${user.clientCode} - ${getName(
                                    user
                                  )}`}
                                >
                                  {user.clientCode} - {getName(user)}
                                </Option>
                              </>
                            )
                          )
                        })}
                    </Select>
                  ) : (
                    <Input name='clientCode' readOnly />
                  )}
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ padding: '0 3rem' }}></Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ padding: '0 3rem' }}></Col> */}

              <Col
                xs={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  name='diff'
                  label='Diff'
                  rules={[{ required: true, message: 'Please Select Diff!' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}%`}
                    placeholder='Enter Diff'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  name='diff1'
                  label='Diff1'
                  rules={[{ required: true, message: 'Please Select Diff1!' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}%`}
                    placeholder='Enter Diff1'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={4}
                lg={4}
                xl={4}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  name='diff2'
                  label='Diff2'
                  rules={[{ required: true, message: 'Please Select Diff2!' }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}%`}
                    placeholder='Enter Diff2'
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  valuePropName='checked'
                  name='detailed'
                  label='Detailed'
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  valuePropName='checked'
                  name='allClients'
                  label='All Clients'
                >
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{ padding: '0 3rem' }}
              >
                <Form.Item
                  valuePropName='checked'
                  name='onlyShift'
                  label='Only Shift'
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button type='primary' htmlType='submit'>
                  Search
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFilters({})
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {error ? (
        <Page404 error={error} isShow={false} />
      ) : (
        <div className='report-table-wrapper' id='ROLLOVER-TRACKING-REPORT-PDF'>
          <RolloverTrackingTable
            columns={newColumns}
            reportData={filters?.where ? [filters?.where] : []}
          />
          <RolloverTrackingTable
            reportData={rollOver || []}
            columns={ClientShift}
            loading={loading}
          />
        </div>
      )}
    </>
  )
}

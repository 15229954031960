import React from 'react'
import { Table } from 'antd'

export default function ({ reportData, clientData }) {
  const {
    ClientCode,
    ClientName,
  } = clientData

  const columns = [
    {
      title: 'Ledger',
      className: 'report-title main-title',
      children: [
        {
          title: <div className='hidden-client-data'>Client Code: <span className='hidden-client-code'>{ClientCode}</span> </div>,
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Entry Date',
              dataIndex: 'Entrydate',
              key: 'Entrydate',
              className: 'report-text'
            },
            {
              title: 'Debit Amount',
              dataIndex: 'DR_Amount',
              key: 'DR_Amount',
              className: 'report-numeric'
            },
          ],
        },
        {
          title: <div className='hidden-client-data'>Client Name: <span className='hidden-client-code'>{ClientName}</span></div>,
          className: 'hidden-client-data-th',
          children: [
            {
              title: 'Credit Amount',
              dataIndex: 'CR_Amount',
              key: 'CR_Amount',
              className: 'report-numeric'
            },
            {
              title: 'Remark',
              dataIndex: 'remark',
              key: 'remark',
              className: 'report-numeric'
            },
          ],
        },
      ]
    }
  ]
  // let data = reportData.map((report, key) => { return { key, ...report } })
  return (
    <Table
      className='ledger-ilts-report'
      bordered
      dataSource={reportData}
      columns={columns}
      // scroll={{ x: true, y: 240 }}
      pagination={{ pageSize: 1000, hideOnSinglePage: true }}
    />
  )
}

import gql from 'graphql-tag'

export const GET_CURRENT_USER = gql`
query currentUser{
  currentUser {
    id
    firstName
    lastName
    email
    phone
    address
    cityId
    stateId
    countryId
    motherName
    fatherName
    dateOfBirth
    userName
    isActive
    role
    keyId
    isAuto
    isAssociate
    fundReceived
    isKycDone
    completedSteps
    amcStartDate
    sipStartDate
    closedDate
    initialExposure
    clientCode
    strategyCode
    panCardNo
    completedSteps
    annualIncome
    cvlStatus
    clientScore
    exposure
    initialInvestment
    reEvaluate
    createdBy {
      id
      firstName
      lastName
      email
      phone
    }
    updatedBy {
      id
    }
    token
    disableDate
    isDisableDate
    createdAt
    updatedAt
    loginType
    isPanCardIndividual
    gender
    nationality
    residentialStatus
    maritalStatus
    sourceOfIncome
    politicallyExposed
    countryOfTax
    plan{
      id
      name
    }
    kyc {
      id
      panCard
      aadharCard
      passportSize
      signature
      cancelledChequeSnap
      bankStatement
    }
  }
}
`

export const GET_EMAIL_FROM_USERNAME = gql`
  query getEmailFromUserName($where: UserNameInput!) {
    getEmailFromUserName(where: $where)
  }
`

export const SEND_PASSWORD_LINK = gql`
query sendResetPasswordLink($userName: String!){
  sendResetPasswordLink(userName: $userName)
}
`
export const CHECK_PHONE_BELONGS_TO = gql`
query checkPhoneBelongsTo($phone: String!){
  checkPhoneBelongsTo(phone: $phone){
    id
    firstName
    lastName
    clientCode
  }
}`

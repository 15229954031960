import React, { useContext, useState } from 'react'
import { Form, Input, Select, Button, DatePicker, Radio, Row, Col } from 'antd'
import moment from 'moment'

import './verification.css'
import CustomMeta from 'components/CustomMeta'
import { AppContext } from 'AppContext'
import countries from 'utils/countries.json'
import cities from 'utils/cities.json'
import ClientProfileModal2 from './Model/ClientProfile2'
import { UPDATE_USER } from 'modules/Users/graphql/Mutations'
import client from 'apollo'
import openNotification from 'utils/Notification'
import { COMPLETE_STEP, DISCLAIMER_MAIL } from './graphql/Mutations'
import { orderBy } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { GET_PLANS } from 'modules/Plans/graphql/Query'

const FormItem = Form.Item
const { Option } = Select

export default function ({ next, reEvaluate }) {
  const [form] = Form.useForm()
  const [showVerifyModal, setShowVerifyModal] = useState(false)
  const { dispatch, state } = useContext(AppContext)
  const { id, plan: plansName } = state.currentUser
  const planName = plansName?.name
  const [editAccess, setEditAccess] = useState(reEvaluate)
  const [formValue, setFormValue] = useState({})
  const dateFormat = 'DD-MMM-YYYY'
  const [loadings, setLoadings] = useState()
  const {
    gender,
    address,
    fatherName,
    nationality,
    residentialStatus,
    sourceOfIncome,
    politicallyExposed,
    countryOfTax,
    dateOfBirth,
    maritalStatus,
    cityId,
    plan,
    // planId,
  } = state.currentUser
  const { data: getPlan } = useQuery(GET_PLANS, { fetchPolicy: 'network-only' })

  const onFinish = (values) => {
    values.fatherName =
      values.fatherName.charAt(0).toUpperCase() +
      values.fatherName.slice(1).toLowerCase()
    if (editAccess === true) {
      onNext()
    } else {
      setFormValue(values)
      setShowVerifyModal(true)
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  }

  const handleHideFormModal = () => {
    setShowVerifyModal(false)
  }

  const editAccessFunc = () => {
    setEditAccess(false)
  }

  const onNext = () => {
    setLoadings(true)
    const data = {
      completedSteps: 'CLIENT_PROFILE_2',
    }

    client
      .mutate({ mutation: UPDATE_USER, variables: { id, data } })
      .then((res) => {
        client.mutate({ mutation: DISCLAIMER_MAIL })
        client.mutate({
          mutation: COMPLETE_STEP,
          variables: {
            data: {
              step: 'CLIENT_PROFILE_2',
              from: 'WEB',
            },
          },
        })
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        openNotification('success', 'Client Profile Updated Successfully')
        next()
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  return (
    <>
      <Row>
        <Col span={22} style={{ textAlign: 'center' }}>
          <h1>Client Profile</h1>
        </Col>
        <Col span={2} style={{ textAlign: 'end' }}>
          {editAccess && (
            <Button type='primary' onClick={editAccessFunc}>
              Edit
            </Button>
          )}
        </Col>
      </Row>

      <div className='gx-app-login-wrap title-verification'>
        <CustomMeta title='Client Profile 2' />
        <div className='gx-app-login-content verification'>
          <Form
            {...formItemLayout}
            form={form}
            name='register'
            onFinish={onFinish}
            initialValues={{
              dateOfBirth: dateOfBirth ? moment(dateOfBirth) : '',
              address: address || '',
              fatherName: fatherName || '',
              gender: gender || undefined,
              nationality: nationality || 'Indian',
              residentialStatus: residentialStatus || undefined,
              sourceOfIncome: sourceOfIncome || undefined,
              politicallyExposed: politicallyExposed || 'NO',
              countryOfTax: countryOfTax || 'India',
              maritalStatus: maritalStatus || undefined,
              cityId: cityId || undefined,
              planId: parseInt(plan?.id) || undefined,
            }}
            labelAlign='left'
            scrollToFirstError
          >
            <FormItem
              name='dateOfBirth'
              label='Date Of Birth'
              rules={[
                {
                  required: true,
                  message: 'Date Of Birth is required!',
                },
              ]}
            >
              <DatePicker
                inputReadOnly
                format={dateFormat}
                style={{ width: '100%' }}
                disabled={!editAccess ? false : true}
              />
            </FormItem>

            <FormItem
              name='gender'
              label='Gender'
              rules={[
                {
                  required: true,
                  message: 'Gender is required!',
                },
              ]}
            >
              <Radio.Group disabled={!editAccess ? false : true}>
                <Radio value='MALE'>Male</Radio>
                <Radio value='FEMALE'>Female</Radio>
                <Radio value='OTHER'>Other</Radio>
              </Radio.Group>
              {/* <Select placeholder="Gender" style={{ width: '100%' }}>
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHER">Other</Option>
            </Select> */}
            </FormItem>

            <FormItem
              name='address'
              label='Address'
              rules={[
                {
                  required: true,
                  message: 'Address is required !',
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder='Address'
                autoFocus={true}
                style={{ width: '100%' }}
                disabled={!editAccess ? false : true}
              />
            </FormItem>

            <FormItem
              name='fatherName'
              label='Name of father/spouse '
              rules={[
                {
                  required: true,
                  message: 'father/spouse is required !',
                },
              ]}
            >
              <Input
                placeholder='father/spouse Name'
                autoFocus={true}
                style={{ width: '100%', textTransform: 'capitalize' }}
                disabled={!editAccess ? false : true}
              />
            </FormItem>

            <FormItem
              name='nationality'
              label='Nationality'
              rules={[
                {
                  required: true,
                  message: 'Nationality is required !',
                },
              ]}
            >
              <Select
                placeholder='Nationality'
                className='display-field'
                disabled={!editAccess ? false : true}
              >
                {countries.map((country) => (
                  <Option key={country.id} value={country.nationalityName}>
                    {country.nationalityName}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              name='residentialStatus'
              label='Residential Status'
              rules={[
                {
                  required: true,
                  message: 'Residential Status is required !',
                },
              ]}
            >
              <Radio.Group disabled={!editAccess ? false : true}>
                <Radio value='RESIDENT_INDIVIDUAL'>Resident Individual</Radio>
                <Radio value='NON_RESIDENT_INDIAN'>Non Resident Indian</Radio>
              </Radio.Group>
              {/* <Select placeholder="Residential Status" style={{ width: '100%' }} >
                <Option value="INDIVIDUAL">Individual</Option>
                <Option value="JOIN_FAMILY">Join Family</Option>
              </Select> */}
            </FormItem>

            <FormItem
              name='maritalStatus'
              label='Marital Status'
              rules={[
                {
                  required: true,
                  message: 'Marital Status is required !',
                },
              ]}
            >
              <Radio.Group disabled={!editAccess ? false : true}>
                <Radio value='SINGLE'>Single</Radio>
                <Radio value='MARRIED'>Married</Radio>
              </Radio.Group>
              {/* <Select placeholder="Marital Status" style={{ width: '100%' }} >
                <Option value="SINGLE">Single</Option>
                <Option value="MARRIED">Married</Option>
              </Select> */}
            </FormItem>

            <FormItem
              name='sourceOfIncome'
              label='Source of Income'
              rules={[
                {
                  required: true,
                  message: 'Source of Income is required !',
                },
              ]}
            >
              <Select
                placeholder='Source of Income'
                className='display-field'
                disabled={!editAccess ? false : true}
              >
                <Option value='SALARY'>Salary</Option>
                <Option value='BUSINESS_INCOME'>Business Income</Option>
                <Option value='OTHER'>Others</Option>
              </Select>
            </FormItem>

            <FormItem
              name='politicallyExposed'
              label='Politically Exposed'
              rules={[
                {
                  required: true,
                  message: 'Politically Exposed is required !',
                },
              ]}
            >
              <Select
                placeholder='Politically Exposed'
                className='display-field'
                disabled={!editAccess ? false : true}
              >
                <Option value='YES'>Yes</Option>
                <Option value='NO'>No</Option>
                <Option value='RELATED'>Related</Option>
              </Select>
            </FormItem>

            <FormItem
              name='countryOfTax'
              label='Country of tax'
              rules={[
                {
                  required: true,
                  message: 'Country of tax is required !',
                },
              ]}
            >
              <Select
                placeholder='Country of tax'
                className='display-field'
                disabled={!editAccess ? false : true}
              >
                {/* <Option value="INDIA">India</Option>
                <Option value="NOT_INDIA">Not Indian</Option> */}
                {countries.map((country) => (
                  <Option key={country.id} value={country.nationalityName}>
                    {country.nationalityName}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              name='cityId'
              label='City'
              rules={[
                {
                  required: true,
                  message: 'City is required !',
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp='children'
                placeholder='City'
                className='display-field'
                disabled={!editAccess ? false : true}
              >
                {orderBy(cities, 'name').map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              name='planId'
              label='Your Select Interested ILTS plan?'
              rules={[
                {
                  required: true,
                  message: 'Select Plan!',
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp='children'
                placeholder='Select Plan'
                className='display-field'
                disabled={!editAccess ? false : true}
              >
                {getPlan &&
                  getPlan?.getPlans.map((plan, key) => {
                    return (
                      <Option key={key} value={parseInt(plan.id)}>
                        {plan.name}
                      </Option>
                    )
                  })}
              </Select>
            </FormItem>
            <div>
              <Button type='primary' htmlType='submit' loading={loadings}>
                Verify And Next
              </Button>

              {showVerifyModal && (
                <ClientProfileModal2
                  next={next}
                  formValue={formValue}
                  visible={setShowVerifyModal}
                  onCancel={() => handleHideFormModal()}
                  reEvaluate={reEvaluate}
                  planName={planName}
                />
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

import gql from 'graphql-tag'

export const GET_SCHEDULE_NOTIFICATIONS = gql`
query scheduleNotifications{
  scheduleNotifications {
    id
    receivers{
      id
      firstName
      lastName
      email
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      keyId
      kyc {
        id
        panCard
        aadharCard
        passportSize
        signature
        cancelledChequeSnap
        bankStatement
      }
      isAuto
      isAssociate
      fundReceived
      isKycDone
      completedSteps
      amcStartDate
      sipStartDate
      closedDate
      initialExposure
      clientCode
      strategyCode
      createdBy {
        id
      }
      updatedBy {
        id
      }
      token
      createdAt
      updatedAt
    }
    receiverIds
    title
    mobile
    email
    description
    status
    date
    notificationType
    updatedBy {
      id
      firstName
      lastName
      email
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      keyId
      kyc {
        id
        panCard
        aadharCard
        passportSize
        signature
        cancelledChequeSnap
        bankStatement
      }
      isAuto
      isAssociate
      fundReceived
      isKycDone
      completedSteps
      amcStartDate
      sipStartDate
      closedDate
      initialExposure
      clientCode
      strategyCode
      createdBy {
        id
      }
      updatedBy {
        id
      }
      token
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
      email
      phone
      address
      cityId
      stateId
      countryId
      motherName
      fatherName
      dateOfBirth
      userName
      isActive
      role
      keyId
      kyc {
        id
        panCard
        aadharCard
        passportSize
        signature
        cancelledChequeSnap
        bankStatement
      }
      isAuto
      isAssociate
      fundReceived
      isKycDone
      completedSteps
      amcStartDate
      sipStartDate
      closedDate
      initialExposure
      clientCode
      strategyCode
      createdBy {
        id
      }
      updatedBy {
        id
      }
      token
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
`

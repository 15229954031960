import gql from 'graphql-tag'

export const GET_ILTS_REPORT = gql`
query spIltsreport($where: SpiltsreportReportInput!){
  spIltsreport(where: $where)
}
`

export const GET_AVERAGE_INDEX_REPORT = gql`
query spAverageIndexreport($where: SpInterestAndspAverageReportInput){
  spAverageIndexreport(where: $where)
}
`

export const GET_INTEREST_REPORT = gql`
query spInterestReport($where: SpInterestAndspAverageReportInput){
  spInterestReport(where: $where)
}
`

export const GET_MONTHLY_RETURN_REPORT = gql`
query spMonthlyReturnReport($where: SpMonthlyReturnReportInput){
  spMonthlyReturnReport(where: $where)
}
`

export const GET_MONTHLY_EXPOSURE_REPORT = gql`
query spMonthlyExposureReport($where: SpMonthlyExposureReportInput){
  spMonthlyExposureReport(where: $where)
}
`

export const GET_PERFORMANCE_REPORT = gql`
query spPerformanceReport($where: SpPerformanceReportInput!){
  spPerformanceReport(where: $where)
}
`

export const GET_NLV_MISMATCH_REPORT = gql`
query spNlvMisMatchReport($where: SpNlvMisMatchReportInput){
  spNlvMisMatchReport(where: $where)
}
`

export const GET_NLV_TRACKER_REPORT = gql`
query spNLVTracker($where: SpNLVTrackerInput){
  spNLVTracker(where: $where)
}
`

export const GET_NLV_INDIVIDUAL_REPORT = gql`
query nlvIndividualReport($groupId: Int){
  nlvIndividualReport(where: { groupId: $groupId })
}
`

export const GET_UNHEDGE_REPORT = gql`
query spUnhedgeReportInput($where: SpUnhedgeReportInput){
  spUnhedgeReportInput(where: $where)
}
`

export const GET_CURRENT_POSITION_REPORT = gql`
query spCurrentPosition{
  spCurrentPosition
}
`

export const GET_TRADER_EQ_REPORT = gql`
query spTradeReportEQ($where: ReportGroupInput){
  spTradeReportEQ(where: $where)
}
`

export const GET_TRADER_FO_REPORT = gql`
query spTradeReportFO($where: ReportGroupInput){
  spTradeReportFO(where: $where)
}
`

export const GET_LEDGER_REPORT = gql`
query spLedgerReport($where: ReportGroupInput){
  spLedgerReport(where: $where)
}
`

export const GET_PL_CHANGE_REPORT = gql`
query plChange($where: PlChangeWhereUniqueInput){
  plChange(where: $where)
}
`

export const GET_ROLL_OVER_REPORT = gql`
query rollOver($where: RollOverWhereUniqueInput){
  rollOver(where: $where)
}
`
export const GET_CAPITAL_GAIN = gql`
query capitalGain($where: CapitalGainWhereUniqueInput){
  capitalGain(where: $where)
}
`

export const GET_ROLL_OVER_OPPORTUNITY = gql`
query rollOverOpportunity($where: RollOverOpportunityWhereUniqueInput!){
  rollOverOpportunity(where: $where)
}
`
export const GET_ILTS_SHIFT = gql`
query ieltsShift($where: IeltsShiftWhereUniqueInput){
  ieltsShift(where: $where)
}
`

export const GET_USER_PERFORMANCE_REPORT = gql`
query spUserPerformanceReport($where: UserPerformanceInput){
  spUserPerformanceReport(where: $where)
}
`

export const GENERATE_PDF = gql`
query generatePDF($data: generatePDFInput!){
  generatePDF(data: $data)
}
`

export const SEND_ME_PDF = gql`
query sendMeReport($data: generatePDFInput!){
  sendMeReport(data: $data)
}
`
export const GET_FINANCIAL_YEAR = gql`
query getFinancialYear {
  getFinancialYear
}
`
export const PL_CHANGE_HISTORY_SEARCH = gql`
query plChangeHistorySearch{
  plChangeHistorySearch
}
`
export const SP_CURRENT_POSITION_JBALANCE = gql`
query spCurrentPositionJBalance($where: SpCurrentPositionJBalanceInput){
  spCurrentPositionJBalance(where: $where)
}
`

export const SP_CURRENT_POSITIONFO = gql`
  query spCurrentPositionFO($where: SpCurrentPositionFOInput){
    spCurrentPositionFO(where: $where)
}
`
export const SP_CURRENT_POSITIONEQ = gql`
  query spCurrentPositionEQ($where: SpCurrentPositionEQInput){
    spCurrentPositionEQ(where: $where)
}
`
export const CUSTOM_CSV_DOWNLOAD = gql`
  query customCSVDownload($data: CustomCSVDownloadInput!){
    customCSVDownload(data: $data)
}
`
export const GET_NIFTY =gql`
query{
  getNifty50
}
`

import gql from 'graphql-tag'

export const GET_GROUP = gql`
  query getGroup($where: WhereGroupUniqueInput) {
    getGroup(where: $where) {
      id
      name
      userIds
      users {
        id
        firstName
        lastName
        email
        phone
        address
        cityId
        stateId
        countryId
        motherName
        fatherName
        dateOfBirth
        userName
        isActive
        role
        keyId
        kyc {
          id
          panCard
          aadharCard
          passportSize
          signature
          cancelledChequeSnap
          bankStatement
        }
        isAuto
        isAssociate
        fundReceived
        isKycDone
        completedSteps
        amcStartDate
        sipStartDate
        closedDate
        initialExposure
        clientCode
        strategyCode
        createdBy {
          id
        }
        updatedBy {
          id
        }
        token
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_GROUPS = gql`
  query getGroups(
    $where: WhereGroupInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getGroups(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      groups {
        id
        name
        userIds
        users {
          id
          phone
          email
        }
        createdAt
      }
      count
    }
  }
`

export const GET_GROUPS_NAME = gql`
  query getGroups(
    $where: WhereGroupInput
    $skip: Int
    $limit: Int
    $sort: SortInput
  ) {
    getGroups(where: $where, skip: $skip, limit: $limit, sort: $sort) {
      groups {
        id
        name
        createdAt
      }
    }
  }
`

export const ALL_FIELD_SELECTION = gql`
  query Query {
    getAllFieldSelection {
      id
      role
      firstName
      lastName
      userName
      completedSteps
      clientCode
      email
      phone
      remark
      action
      registrationDate
      investmentDate
      amcInPer
      clientConsentTime
      sourceOfLead
      associateIntroducer
      invoiceStatus
      riskProfileType
      discountStatus
      address
      panCardNo
      clientScore
      disableDate
      initialExposure
      annualIncome
      dateOfBirth
      agreementDataMailSent
      plan
      netAvailableExposure
      incomeProof
      hierarchy
      amcReceivedDate
      amcInvoiceNo
      amcRecentAmount
    }
  }
`
export const GET_FIELD_SELECTION = gql`
  query ($where: FieldWhereUniqueInput) {
    getFieldSelection(where: $where)
  }
`

import gql from 'graphql-tag'

export const DISCLAIMER_MAIL = gql`
  mutation disclaimerMail {
    disclaimerMail
  }
`
export const UPDATE_KYC = gql`
  mutation updateKyc(
    $userId: Int
    $panCard: Upload
    $aadharCard: Upload
    $passportSize: Upload
    $signature: Upload
    $cancelledChequeSnap: Upload
    $bankStatement: Upload
    $aadharCardBack: Upload
    $incomeProof: Upload
    $panHolderName: String
  ) {
    updateKyc(
      where: { userId: $userId }
      data: {
        panCard: $panCard
        aadharCard: $aadharCard
        passportSize: $passportSize
        signature: $signature
        cancelledChequeSnap: $cancelledChequeSnap
        bankStatement: $bankStatement
        aadharCardBack: $aadharCardBack
        incomeProof: $incomeProof
        panHolderName: $panHolderName
      }
    ) {
      id
      panCard
      aadharCard
      passportSize
      signature
      cancelledChequeSnap
      bankStatement
      aadharCardBack
      incomeProof
      panHolderName
    }
  }
`

export const GIVE_ANSWER = gql`
  mutation giveAnswer($data: AnswerCreateInputWithAnnualIncome) {
    giveAnswer(data: $data)
  }
`
export const INVESTMENT_PLANNING_MAIL = gql`
  mutation investmentPlanningMail($where: InvestmentPlanningMailInput) {
    investmentPlanningMail(where: $where)
  }
`
export const RISK_ASSESSMENT_MAIL = gql`
  mutation riskAssessmentMail {
    riskAssessmentMail
  }
`
export const FETCH_CVL_STATUS = gql`
  mutation fetchCvlStatus {
    fetchCvlStatus
  }
`
export const CREATE_MANDATE = gql`
  mutation createMandate($data: mandateCreateInput!) {
    createMandate(data: $data) {
      ifscCode
    }
  }
`
export const CONSENT_DATA = gql`
  mutation consentData {
    consentData
  }
`
export const COMPLETE_STEP = gql`
  mutation completeStep($data: completeStepInput!) {
    completeStep(data: $data) {
      id
      step
      from
    }
  }
`

import gql from 'graphql-tag'

export const GET_PLANS = gql`
  query getPlans {
    getPlans {
      id
      name
      description
      minPoints
      maxPoints
      percentage
      stock
      sip
      pnlOn
      interestFromDebt
      features
      planRiskType
      planRisk
      minRisk
      maxRisk
      tCost
    }
  }
`

export const GET_PLAN = gql`
  query getPlan($where: GetPlanInput) {
    getPlan(where: $where) {
      id
      name
    }
  }
`

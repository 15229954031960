import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import { Select } from 'antd'

import { exportGraphAsPdf } from 'utils/Graph'
const { Option } = Select

export default function DownloadReport({
  data = [],
  fileName,
  id,
  staticHtml = null,
  isLandscape = false,
  className = '',
  onlyCSV = false,
}) {
  const [buttonLoading, setButtonLoading] = useState(false)

  function handleExport(value) {
    if (value === 'CSV') return false
    setButtonLoading(true)
    exportGraphAsPdf(fileName, id, staticHtml, isLandscape).finally(() =>
      setButtonLoading(false)
    )
  }

  return (
    <Select
      className={`gx-mb-2 gx-select-sm download-button ${className}`}
      value='1'
      onChange={handleExport}
      loading={buttonLoading}
    >
      <Option value='1' disabled>
        Export As
      </Option>
      {!onlyCSV && <Option value='PDF'>PDF</Option>}
      <Option value='CSV'>
        <CSVLink
          data={data}
          className='csv-btn'
          filename={`${fileName}.csv`}
          onClick={(e) => e.stopPropagation()}
        >
          CSV
        </CSVLink>
      </Option>
    </Select>
  )
}

import React from 'react'
import { Table } from 'antd'

export default function ({ reportData, columns, loading }) {
  const data = reportData.map((report, key) => { return { key, ...report } })

  return (
    <Table
      loading={loading}
      dataSource={data}
      columns={columns}
      className='nlv-tracker-report'
      pagination={{ pageSize: 100, hideOnSinglePage: true }}
      scroll={{ x: true, y: 'calc(100vh - 72px - 24px - 24px - 24px - 40px - 65px)' }}
    />
  )
}

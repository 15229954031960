import React, { useState, useContext } from 'react'
import { Table, PageHeader, DatePicker, Button, Space, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { GET_REVENUE_INCOME } from 'modules/Users/graphql/Queries'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'
import client from 'apollo'
import {
  CUSTOM_CSV_DOWNLOAD,
  GENERATE_PDF,
} from 'modules/Reports/graphql/Queries'

const { RangePicker } = DatePicker

export default function RevenueIncome() {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const {
    state: {
      isAdmin,
      currentUser: { id },
    },
  } = useContext(AppContext)
  const [filters, setFilters] = useState(isAdmin ? {} : { userId: id })
  const [CSVLoading, setCSVLoading] = useState(false)
  const [PDFLoading, setPDFLoading] = useState(false)

  const { data, loading, error } = useQuery(GET_REVENUE_INCOME, {
    variables: { where: filters, limit, skip, sort },
    fetchPolicy: 'no-cache',
  })

  if (error) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getRevenueIncome.RevenueIncome')) {
    totalCount = data.getRevenueIncome.counter
    tableData = data.getRevenueIncome.RevenueIncome.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
    if (!isAdmin && get(data, 'getRevenueIncome.total')) {
      tableData = [
        ...tableData,
        {
          client: 'Total',
          description: data.getRevenueIncome.total,
        },
      ]
    }
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.role) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = filter.role
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = ''
      delete tempFilters.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    value = typeof value === 'object' ? value[0] : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = ''
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          autoFocus={true}
          value={selectedKeys[0]}
          placeholder={`Search ${dataIndex}`}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys[0], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    sorter: (a, b) => a.age - b.age,
  })

  const columns = [
    {
      title: (
        <div
          className='hidden-client-data'
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          Total Amount :{' '}
          {Math.round(data?.getRevenueIncome?.commissionAmountTotal) || 0}
          <span className='hidden-client-code'></span>{' '}
        </div>
      ),
      children: [
        {
          title: 'Client Code',
          dataIndex: 'clientCode',
          key: 'clientCode',
          sorter: (a, b) => a.clientCode - b.clientCode,
          render: (text, record) => (
            <NavLink to={`/clients/${record?.client?.id}`}>
              {record?.client?.clientCode || '-'}
            </NavLink>
          ),
          width: '120px',
          ...getColumnSearchProps('clientCode'),
        },
        {
          title: 'Client Name',
          dataIndex: 'client',
          key: 'client',
          sorter: (a, b) => a.client - b.client,
          render: (client) => (client === 'Total' ? 'Total' : getName(client)),
          ...getColumnSearchProps('clientName'),
          width: '120px',
        },
        {
          title: 'Associate Name',
          dataIndex: 'associateName',
          key: 'associateName',
          sorter: (a, b) => a.associateName - b.associateName,
          render: (text, record) => (
            <NavLink to={`/users/${record?.user?.id}`}>
              {`${record?.user?.firstName} ${record?.user?.lastName}` || '-'}
            </NavLink>
          ),
          ...getColumnSearchProps('associateName'),
          width: '150px',
        },
        {
          title: 'Type',
          dataIndex: 'role',
          key: 'role',
          sorter: (a, b) => a.role - b.role,
          render: (text, record) => record?.client?.createdBy?.role,
          width: '20%',
        },
        {
          title: 'Amc Amount',
          dataIndex: 'amount',
          key: 'amount',
          sorter: (a, b) => a.amount - b.amount,
          render: (text, record) => record?.amc?.amount,
          width: '20%',
        },
        {
          title: 'Commission Ratio(%)',
          dataIndex: 'commissionRatio',
          key: 'commissionRatio',
          sorter: (a, b) => a.commissionRatio - b.commissionRatio,
          width: '120px',
        },
        {
          title: 'Commission Amount',
          dataIndex: 'commissionAmount',
          key: 'commissionAmount',
          sorter: (a, b) => a.commissionAmount - b.commissionAmount,
          render: (commissionAmount) =>
            commissionAmount ? Math.round(commissionAmount) : '-',
          width: '120px',
        },
        {
          title: 'Created At',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: (a, b) => a.createdAt - b.createdAt,
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          ...getColumnDateProps('createdAt'),
          width: '140px',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          render: (Description) =>
            Description === 'Description' ? 'Description' : Description || '-',
          width: '150px',
        },
      ],
    },
  ]

  function handleCsvDownload() {
    setCSVLoading(true)
    client
      .query({
        query: GET_REVENUE_INCOME,
        variables: { where: filters, skip, sort, limit: totalCount },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        client
          .query({
            query: CUSTOM_CSV_DOWNLOAD,
            variables: {
              data: {
                data: res.data.getRevenueIncome.RevenueIncome,
                fields: [
                  { value: 'client.clientCode', label: 'client Code' },
                  { value: 'client.firstName', label: 'Client Name' },
                  {
                    value: 'client.createdBy.firstName',
                    label: 'Associate Name',
                  },
                  { value: 'client.createdBy.role', label: 'Type' },
                  { value: 'amc.amount', label: 'Amc Amount' },
                  { value: 'commissionRatio', label: 'Commission Ratio(%)' },
                  { value: 'commissionAmount', label: 'Commission Amount' },
                  { value: 'createdAt', label: 'Created At' },
                  { value: 'description', label: 'Description' },
                ],
              },
            },
          })
          .then(
            (res) =>
              get(res, 'data.customCSVDownload') &&
              window.open(get(res, 'data.customCSVDownload'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => {
            setLimit(10)
            setSkip(0)
            setCSVLoading(false)
          })
      })
  }

  function handlePdfDownload() {
    setPDFLoading(true)
    client
      .query({
        query: GET_REVENUE_INCOME,
        variables: { where: filters, skip, sort, limit: totalCount },
        fetchPolicy: 'no-cache',
      })
      .then((res) => {
        let staticHtml = ``
        if (get(res, 'data.getRevenueIncome.RevenueIncome').length) {
          staticHtml = `
            <div class="ant-table-wrapper nlv-tracker-report">
              <div class="ant-spin-nested-loading">
                <div class="ant-spin-container">
                  <div class="ant-table">
                    <div class="ant-table-container">
                      <div class="ant-table-content">
                        <table style="table-layout: auto;">
                          <colgroup>
                            <col style="width: 60px; min-width: 60px;">
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col>
                            <col style="width: 200px; min-width: 200px;">
                          </colgroup>
                          <thead class="ant-table-thead">
                            <tr>
                              <th colspan="14" class="ant-table-cell report-title main-title">Revenue Income</th>
                            </tr>
                            <tr>
                              <th class="ant-table-cell report-text">ClientCode</th>
                              <th class="ant-table-cell report-text">ClientFirstName</th>
                              <th class="ant-table-cell report-text">ClientLastName</th>
                              <th class="ant-table-cell report-text">AssociateName</th>
                              <th class="ant-table-cell report-text">Type</th>
                              <th class="ant-table-cell report-text">AmcAmount</th>
                              <th class="ant-table-cell report-text">CommissionRatio(%)</th>
                              <th class="ant-table-cell report-text">CommissionAmount</th>
                              <th class="ant-table-cell report-text">CreatedDate</th>
                              <th class="ant-table-cell report-text">Description</th>
                            </tr>
                          </thead>
                          <tbody class="ant-table-tbody">`
          staticHtml = `${staticHtml}`
          res.data.getRevenueIncome.RevenueIncome.forEach((data) => {
            const {
              client: { clientCode, firstName, lastName, createdBy },
              amc,
              commissionRatio,
              commissionAmount,
              createdAt,
              description,
            } = data
            staticHtml = `${staticHtml}
                            <tr data-row-key="KYP" class="ant-table-row ant-table-row-level-0">
                              <td class="ant-table-cell report-text">${
                                clientCode || 'N/A'
                              } </td>
                              <td class="ant-table-cell report-text">${
                                firstName || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                lastName || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                createdBy?.firstName || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                createdBy?.role || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                amc?.amount
                              }</td>
                              <td class="ant-table-cell report-text">${
                                commissionRatio || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                commissionAmount || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                createdAt || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text">${
                                description || 'N/A'
                              }</td>
                              <td class="ant-table-cell report-text"></td>
                            </tr>`
          })
          staticHtml = `${staticHtml}
                          </tbody >
                        </table >
                      </div >
                    </div >
                  </div >
                </div >
              </div >
            </div >
                  `
        }
        client
          .query({
            query: GENERATE_PDF,
            variables: { data: { html: staticHtml, type: 'landscape' } },
            fetchPolicy: 'network-only',
          })
          .then(
            (res) =>
              get(res, 'data.generatePDF') &&
              window.open(get(res, 'data.generatePDF'), '_blank')
          )
          .catch((err) => console.log(err))
          .finally(() => setPDFLoading(false))
      })
  }

  return (
    <>
      <PageHeader
        className='box'
        title='Revenue Income'
        extra={[
          <Button
            key='1'
            type='primary'
            loading={PDFLoading}
            onClick={handlePdfDownload}
          >
            Export Pdf
          </Button>,
          <Button
            key='2'
            type='primary'
            loading={CSVLoading}
            onClick={handleCsvDownload}
          >
            Export Csv
          </Button>,
        ]}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        className={
          !isAdmin && get(data, 'getRevenueIncome.total')
            ? 'user-revenue-table'
            : ''
        }
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
          hideOnSinglePage: true,
        }}
      />
    </>
  )
}

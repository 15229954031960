// import jwtDecode from 'jwt-decode'
import openNotification from 'utils/Notification'
import history from 'CustomHistory'

export function authHeader() {
  let token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    return 'Basic ' + token
  }
  return {}
}

export function isAuthenticated() {
  return JSON.parse(localStorage.getItem('token'))
}

export function silentAuth() {
  // if (this.isAuthenticated()) {
  //   return new Promise((resolve, reject) => {
  //     this.auth0.checkSession({}, (err, authResult) => {
  //       if (err) {
  //         localStorage.removeItem(this.authFlag)
  //         return reject(err)
  //       }
  //       this.setSession(authResult)
  //       resolve()
  //     })
  //   })
  // }
}

// export async function scheduleRenewal() {
//   let expiresAt = this.expiresAt
//   // if (!expiresAt) {
//   //   const cookies = new Cookies()
//   //   const accessToken = await cookies.get('accessToken')
//   //   if (accessToken) {
//   //     const { exp } = jwtDecode(accessToken)
//   //     if (exp)
//   //       expiresAt = exp * 100
//   //   }
//   // }
//   // const timeout = expiresAt - Date.now()
//   // if (timeout > 0) {
//   //   setTimeout(() => {
//   //     this.silentAuth()
//   //   }, timeout - 60000)
//   // }
// }

export function authLogin(response) {
  localStorage.setItem('token', JSON.stringify(response.data.login.token))
  openNotification('success', 'Logged in successfully')
  history.push('/')
}

export function authLogout() {
  openNotification('success', 'Logout Successfully')
  localStorage.removeItem('token')
  history.replace('/')
}

export function loginWithOtp(res) {
  localStorage.setItem('token', JSON.stringify(res.data.loginWithOtp.token))
  history.push('/')
}

export function authWithOtp(response) {
  localStorage.setItem('token', JSON.stringify(response.data.loginWithOtp.token))
  openNotification('success', 'Logged in successfully')
  history.push('/')
}

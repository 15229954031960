import React, { Fragment, useContext, useState } from 'react'
import { Table, DatePicker, Button, Space, Select, Divider } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import Page404 from 'components/Page404'
import { AppContext } from 'AppContext'
import { getName } from 'utils/User'
import { GET_REVENUE_INCOME, GET_USERS } from '../graphql/Queries'

const { RangePicker } = DatePicker
const { Option } = Select

export default function UserRevenue({ userId }) {
  let tableData = []
  const [sort, setSort] = useState()
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const {
    state: {
      isAdmin,
      currentUser: { id },
    },
  } = useContext(AppContext)
  const [filters, setFilters] = useState(isAdmin ? {} : { userId: id })

  const { data, loading, error } = useQuery(GET_REVENUE_INCOME, {
    variables: {
      where: { ...filters, createdById: +userId },
      limit,
      skip,
      sort,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    data: filterData,
    loading: filterLoading,
    error: filterError,
  } = useQuery(GET_USERS, {
    variables: {
      where: {
        role: [
          'CLIENT',
          'ADMIN',
          'STAFF',
          'MASTER_ASSOCIATE',
          'ASSOCIATE',
          'SALES_EXECUTIVE',
        ],
      },
      limit: 500,
      skip: 0,
    },
    fetchPolicy: 'network-only',
  })

  if (error || filterError) return <Page404 error={error} />

  let totalCount = 0
  if (!loading && get(data, 'getRevenueIncome.RevenueIncome')) {
    totalCount = data.getRevenueIncome.counter
    tableData = data.getRevenueIncome.RevenueIncome.map((user, key) => ({
      key: key.toString(),
      ...user,
    }))
    if (!isAdmin && get(data, 'getRevenueIncome.total')) {
      tableData = [
        ...tableData,
        {
          client: 'Total',
          description: data.getRevenueIncome.total,
        },
      ]
    }
  }

  function handleTableChange(pagination, filter, sorter) {
    if (sorter && sorter.field && sorter.order) {
      if (sorter.order === 'descend') {
        setSort({
          field: sorter.field,
          sortingOrder: 'DESC',
        })
        setCurrentPage(1)
      } else {
        setSort({
          field: sorter.field,
          sortingOrder: 'ASC',
        })
        setCurrentPage(1)
      }
    } else if (filter.role) {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = filter.role
      setFilters(tempFilters)
      setCurrentPage(1)
    } else {
      const tempFilters = JSON.parse(JSON.stringify(filters))
      tempFilters['role'] = ''
      delete tempFilters.role
      setFilters(tempFilters)
      setCurrentPage(1)
    }
  }

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const from = `${dataIndex}From`
      const to = `${dataIndex}To`
      let defaultValue = []
      if (!_isEmpty(filters[from])) {
        defaultValue = [moment(filters[from]), moment(filters[to])]
      }
      return (
        <div style={{ padding: 8 }}>
          <RangePicker
            format={'DD-MMM-YYYY'}
            defaultValue={defaultValue}
            allowClear={true}
            onChange={(e) => {
              let tempFilters
              if (e) {
                confirm()
                tempFilters = JSON.parse(JSON.stringify(filters))
                tempFilters[from] = e[0]
                tempFilters[to] = e[1]
              } else {
                clearFilters()
                tempFilters = JSON.parse(JSON.stringify(filters))
                delete tempFilters[from]
                delete tempFilters[to]
              }
              setFilters(tempFilters)
              setCurrentPage(1)
            }}
          />
        </div>
      )
    },
  })

  function handlePagination(page) {
    setSkip((page - 1) * limit)
    setCurrentPage(page)
  }

  function handlePageSizeChange(current, size) {
    setLimit(size)
  }

  function handleSearch(value, confirm, filedName) {
    // if (filedName === 'clientName' || filedName === 'clientId') {
    //   filedName = "clientId"
    // } else {
    //   filedName = "createdById"
    // }
    filedName === 'clientName' || filedName === 'clientId'
      ? (filedName = 'clientId')
      : (filedName = 'userId')
    value =
      typeof value === 'object' &&
      filedName !== 'clientId' &&
      filedName !== 'clientName'
        ? value[0]
        : value
    confirm()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    tempFilters[filedName] = tempFilters[filedName]
      ? [...tempFilters[filedName], ...value]
      : value
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  function handleReset(clearFilters, filedName) {
    filedName === 'clientName' || filedName === 'clientId'
      ? (filedName = 'clientId')
      : (filedName = 'userId')
    clearFilters()
    const tempFilters = JSON.parse(JSON.stringify(filters))
    if (tempFilters[filedName]) {
      delete tempFilters[filedName]
    }
    setFilters(tempFilters)
    setCurrentPage(1)
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          {filterLoading ? (
            <Select
              className='amc-revenue-filter-loader'
              loading={filterLoading}
              style={{ width: '100%' }}
              disabled
            ></Select>
          ) : (
            <Select
              className='amc-revenue-filter-loader'
              loading={filterLoading}
              optionFilterProp='children'
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              placeholder='Please select'
              defaultValue={selectedKeys || []}
              onChange={(v) => setSelectedKeys(v)}
              showSearch
            >
              {filterData?.getUsers?.users
                .filter((user) =>
                  dataIndex !== 'associateName'
                    ? user.role === 'CLIENT'
                    : user.role !== 'CLIENT'
                )
                .map((amc, key) => {
                  if (dataIndex === 'clientName') {
                    return (
                      <Option key={key} value={amc.id}>
                        {getName(amc)}
                      </Option>
                    )
                  } else if (dataIndex === 'clientId') {
                    return (
                      <Option key={key} value={amc.id}>
                        {amc.clientCode}
                      </Option>
                    )
                  } else {
                    return (
                      <Option
                        key={key}
                        value={amc.id}
                      >{`${amc.firstName} ${amc.lastName}`}</Option>
                    )
                  }
                })}
            </Select>
          )}

          <Divider />
          <Space>
            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, dataIndex, selectedKeys)}
              size='small'
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const columns = [
    {
      title: (
        <div
          className='hidden-client-data'
          style={{ borderBottom: '1px solid #e8e8e8' }}
        >
          Total Amount : {data?.getRevenueIncome?.commissionAmountTotal}
          <span className='hidden-client-code'></span>{' '}
        </div>
      ),
      children: [
        {
          title: 'Client Code',
          dataIndex: 'clientCode',
          key: 'clientCode',
          sorter: (a, b) => a.clientCode - b.clientCode,
          render: (text, record) => (
            <NavLink to={`/clients/${record.client.id}`}>
              {record.client.clientCode || '-'}
            </NavLink>
          ),
          width: '120px',
          ...getColumnSearchProps('clientId'),
        },
        {
          title: 'Client Name',
          dataIndex: 'client',
          key: 'client',
          sorter: (a, b) => a.client - b.client,
          render: (client) => (client === 'Total' ? 'Total' : getName(client)),
          ...getColumnSearchProps('clientName'),
          width: '120px',
        },
        {
          title: 'Associate Name',
          dataIndex: 'associateName',
          key: 'associateName',
          sorter: (a, b) => a.associateName - b.associateName,
          render: (text, record) => (
            <NavLink to={`/users/${record.user.id}`}>
              {`${record?.user?.firstName} ${record?.user?.lastName}` || '-'}
            </NavLink>
          ),
          ...getColumnSearchProps('associateName'),
          width: '150px',
        },
        {
          title: 'Type',
          dataIndex: 'role',
          key: 'role',
          sorter: (a, b) => a.role - b.role,
          render: (text, record) => record?.client?.createdBy?.role,
          width: '20%',
        },
        {
          title: 'Amc Amount',
          dataIndex: 'amount',
          key: 'amount',
          sorter: (a, b) => a.amount - b.amount,
          render: (text, record) => record?.amc?.amount,
          width: '20%',
        },
        {
          title: 'Commission Ratio(%)',
          dataIndex: 'commissionRatio',
          key: 'commissionRatio',
          sorter: (a, b) => a.commissionRatio - b.commissionRatio,
          width: '120px',
        },
        {
          title: 'Commission Amount',
          dataIndex: 'commissionAmount',
          key: 'commissionAmount',
          sorter: (a, b) => a.commissionAmount - b.commissionAmount,
          render: (commissionAmount) =>
            commissionAmount ? Math.round(commissionAmount) : '-',
          width: '120px',
        },
        {
          title: 'Created At',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: (a, b) => a.createdAt - b.createdAt,
          render: (date) => (date ? moment(date).format('Do MMMM YYYY') : ''),
          ...getColumnDateProps('createdAt'),
          width: '140px',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          render: (Description) =>
            Description === 'Description' ? 'Description' : Description || '-',
          width: '150px',
        },
      ],
    },
  ]

  return (
    <Fragment>
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        onChange={handleTableChange}
        scroll={{ x: true, y: true }}
        className={
          !isAdmin && get(data, 'getRevenueIncome.total')
            ? 'user-revenue-table'
            : ''
        }
        pagination={{
          pageSize: limit,
          total: totalCount,
          onChange: handlePagination,
          current: currentPage,
          onShowSizeChange: handlePageSizeChange,
          hideOnSinglePage: true,
        }}
      />
    </Fragment>
  )
}

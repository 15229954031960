import React from 'react'
import { NavLink } from 'react-router-dom'
import sumBy from 'lodash/sumBy'

export default function ({ reportData, clientId, strategy, ledgerReport }) {
  const {
    ClientCode,
    // MemberName,
    ClientName,
    ReportDate,
    NIFTY,
    // BANKNIFTY,
    NIFTYEntry,
    LedgerDate,
    // StockPnl,
    // POSPnl,
    LedgerBalance,
    // PnlBooked,
    // PnlunBooked,
    // PnlWithOutInterest,
    InterestSaved,
    PnlAfterInterest,
    // ProffitPayOut,
    TotalExposure,
    InvestmentDate,
    StockReceived,
    NLV,
    AbsReturn,
    // YOYReturn,
    // RetOnFundReceived,
    AnaualReturnInterest,
    // OtherExpenses,
    // OriginalExposure,
    // NiftyReturn,
    AbsReturnIdx,
    AnualReturnIdx,
    // TotalReturn,
    // HedgingFWDReturn
    PnlWithOutInterest,
  } = reportData

  let data = ledgerReport.map((report, key) => {
    return { key, ...report }
  })
  const NetInvestment = sumBy(data, 'CR_Amount') - sumBy(data, 'DR_Amount')

  return (
    <div
      className='report-text ant-table-wrapper'
      id='itts-performance-wrapper'
    >
      <div className='report-text ant-spin-nested-loading'>
        <div className='report-text ant-spin-container'>
          <div className='report-text ant-table ant-table-middle ant-table-bordered ant-table-fixed-header ant-table-scroll-horizontal'>
            <div className='report-text ant-table-container'>
              <div className='report-text ant-table-body'>
                {/* style={{ overflow: 'scroll', maxHeight: 'calc(100vh - 72px - 24px - 24px)' }} */}
                {/* <table style={{ minWidth: 100, tableLayout: 'fixed' }}> */}
                <table>
                  <colgroup>
                    <col style={{ width: 80, minWidth: 80 }} />
                    <col style={{ width: 100, minWidth: 100 }} />
                    <col style={{ width: 150, minWidth: 150 }} />
                    <col style={{ width: 150, minWidth: 150 }} />
                    <col style={{ width: 100, minWidth: 100 }} />
                    <col style={{ width: 100, minWidth: 100 }} />
                  </colgroup>
                  <thead className='report-text ant-table-thead'>
                    <tr>
                      <th
                        colSpan='7'
                        className='report-text ant-table-cell report-title main-title'
                      >
                        <span
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          Performance Report
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <th
                        colSpan='7'
                        className='report-text ant-table-cell report-title-tag'
                      >
                        <span
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          This is an indicative report. Please check your
                          back-office for final report
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='report-text ant-table-tbody'>
                    <tr
                      aria-hidden='true'
                      className='report-text ant-table-measure-row'
                      style={{ height: 0 }}
                    >
                      <td style={{ padding: 0, border: 0, height: 0 }}></td>
                      <td style={{ padding: 0, border: 0, height: 0 }}></td>
                      <td style={{ padding: 0, border: 0, height: 0 }}></td>
                      <td style={{ padding: 0, border: 0, height: 0 }}></td>
                      <td style={{ padding: 0, border: 0, height: 0 }}></td>
                      <td style={{ padding: 0, border: 0, height: 0 }}></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell bold'>
                        Client Code
                      </td>
                      <td className='ant-table-cell text-center' colSpan={6}>
                        {ClientCode}
                      </td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell bold'>
                        Client Name
                      </td>
                      <td className='ant-table-cell text-center' colSpan={6}>
                        {ClientName}
                      </td>
                    </tr>
                    {/* <tr data-row-key='0' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>Trading Member</td>
                      <td className='ant-table-cell text-center' colSpan={6}>{MemberName}</td>
                    </tr> */}
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={7}>
                        &nbsp;
                      </td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td
                        className='report-text ant-table-cell'
                        colSpan={2}
                      ></td>
                      <td className='report-numeric ant-table-cell'>
                        Strategy
                      </td>
                      <td className='report-numeric ant-table-cell'>Index</td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={3}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Absolute Return
                      </td>
                      <td className='report-numeric ant-table-cell navLinK-hidden-pdf-link'>
                        {clientId ? (
                          <NavLink
                            to={`/clients/${clientId}/monthly-exposure/${ClientCode.replace(
                              'ILTS',
                              ''
                            ).trim()}/${PnlAfterInterest}?strategy=${strategy}`}
                          >
                            {AbsReturn}
                          </NavLink>
                        ) : AbsReturn }
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {AbsReturnIdx?.toFixed(2)}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={3}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Annualized Return
                      </td>
                      <td className='report-numeric ant-table-cell navLinK-hidden-pdf-link'>
                        {clientId ? (
                          <NavLink
                            to={`/clients/${clientId}/monthly-return/${ClientCode.replace(
                              'ILTS',
                              ''
                            ).trim()}?strategy=${strategy}`}
                          >
                              {AnaualReturnInterest}
                          </NavLink>
                        ) : AnaualReturnInterest }
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {AnualReturnIdx}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={3}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={7}>
                        &nbsp;
                      </td>
                    </tr>
                    {/* <tr data-row-key='0' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell' colSpan={2}>Original Exposure</td>
                      <td className='report-numeric ant-table-cell'>{OriginalExposure}</td>
                      <td className='report-numeric ant-table-cell' colSpan={4}></td>
                    </tr> */}
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Current Exposure
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {TotalExposure}
                      </td>
                      <td
                        className='report-numeric ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Net Profit
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {Math.round(PnlAfterInterest)}
                      </td>
                      <td
                        className='report-numeric ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={7}>
                        &nbsp;
                      </td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Strategy Started On
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {InvestmentDate}
                      </td>
                      <td
                        className='report-numeric ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Nifty Average Entry Price
                      </td>
                      <td className='report-numeric ant-table-cell navLinK-hidden-pdf-link'>
                        {clientId ? (
                          <NavLink
                            to={`/clients/${clientId}/nifty-entry/${ClientCode.replace(
                              'ILTS',
                              ''
                            ).trim()}?strategy=${strategy}`}
                          >
                            {NIFTYEntry}
                          </NavLink>
                        ) : (
                          NIFTYEntry
                        )}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Nifty Current Price
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {Math.round(NIFTY)}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    {/* <tr data-row-key='0' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell' colSpan={7}>&nbsp;</td>
                    </tr> */}
                    {/* <tr data-row-key='0' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell' colSpan={2}>Nifty Return</td>
                      <td className='report-numeric ant-table-cell'>{NiftyReturn}</td>
                      <td className='report-text ant-table-cell' colSpan={4}></td>
                    </tr> */}
                    {/* <tr data-row-key='0' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell' colSpan={2}>Hedging and forwarding return</td>
                      <td className='report-numeric ant-table-cell'>{HedgingFWDReturn}</td>
                      <td className='report-text ant-table-cell' colSpan={4}></td>
                    </tr> */}
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Saving of interest
                      </td>
                      <td className='report-numeric ant-table-cell navLinK-hidden-pdf-link'>
                        {clientId ? (
                          <NavLink
                            to={`/clients/${clientId}/saving-of-interest/${ClientCode.replace(
                              'ILTS',
                              ''
                            ).trim()}?strategy=${strategy}`}
                          >
                            {Math.round(InterestSaved)}
                          </NavLink>
                        ) : (
                          Math.round(InterestSaved)
                        )}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Profit without Interest
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {Math.round(PnlWithOutInterest)}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    {/* <tr data-row-key='0' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell' colSpan={2}>Total</td>
                      <td className='report-numeric ant-table-cell'>{Math.round(TotalReturn)}</td>
                      <td className='report-text ant-table-cell' colSpan={4}></td>
                    </tr> */}
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={7}>
                        &nbsp;
                      </td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Ledger Balance
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {LedgerBalance}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Collateral Value
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {StockReceived}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Net Liquidation Value
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {Math.round(NLV)}
                        {/* {
                          clientId ?
                            <NavLink to={`/clients/${clientId}/`}>{Math.round(NLV)}</NavLink>
                            :
                            Math.round(NLV)
                        } */}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Net Investment
                      </td>
                      <td className='report-numeric ant-table-cell'>
                        {Math.round(NetInvestment)}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={7}>
                        &nbsp;
                      </td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Ledger Last Updated
                      </td>
                      <td className='report-text ant-table-cell'>
                        {LedgerDate}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr
                      data-row-key='0'
                      className='report-text ant-table-row ant-table-row-level-0'
                    >
                      <td className='report-text ant-table-cell' colSpan={2}>
                        Price Last Updated
                      </td>
                      <td className='report-text ant-table-cell'>
                        {ReportDate}
                      </td>
                      <td
                        className='report-text ant-table-cell'
                        colSpan={4}
                      ></td>
                    </tr>
                    {/* <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>Ledger Balance</td>
                      <td className='report-numeric ant-table-cell'>{LedgerBalance}</td>
                      <td className='report-text ant-table-cell'></td>
                      <td className='report-text ant-table-cell'></td>
                      <td className='report-text ant-table-cell'></td>
                      <td className='report-text ant-table-cell'></td>
                      <td className='report-text ant-table-cell'></td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>P&amp;L Booked</td>
                      <td className='report-numeric ant-table-cell'>{PnlBooked}</td>
                      <td className='report-text ant-table-cell'></td>
                      <td className='report-text ant-table-cell bold'>Portfolio</td>
                      <td className='report-text ant-table-cell bold'>Date</td>
                      <td className='report-numeric ant-table-cell' colSpan={2}>{ReportDate}</td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>P&amp;L Unbooked</td>
                      <td className='report-numeric ant-table-cell'>{PnlunBooked}</td>
                      <td className='report-text ant-table-cell bold bb-none text-center'>Absolute Return</td>
                      <td className='ant-numeric-cell report-percentage'>{AbsReturn > 0 ? `${AbsReturn}%` : 'N/A'}</td>
                      <td className='report-text ant-table-cell bold'>Nifty 50</td>
                      <td className='report-numeric ant-table-cell' colSpan={2}>{NIFTY}</td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>P&amp;L without Interest</td>
                      <td className='report-numeric ant-table-cell'>{PnlWithOutInterest}</td>
                      <td className='report-text ant-table-cell bold bb-none text-center'>Absolute Return on Exposure</td>
                      <td className='ant-numeric-cell report-percentage'>{YOYReturn > 0 ? `${YOYReturn}%` : 'N/A'}</td>
                      <td className='report-text ant-table-cell bold'>Nifty Entry</td>
                      <td className='report-numeric ant-table-cell' colSpan={2}>{NIFTYEntry}</td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>Interest Saved</td>
                      <td className='report-numeric ant-table-cell'>{InterestSaved}</td>
                      <td className='report-text ant-table-cell bold bb-none text-center'>Return on Fund Received</td>
                      <td className='ant-numeric-cell report-percentage'>{RetOnFundReceived > 0 ? `${RetOnFundReceived}%` : 'N/A'}</td>
                      <td className='report-text ant-table-cell bold'>Update Ledger Date</td>
                      <td className='report-numeric ant-table-cell' colSpan={2}>{LedgerDate}</td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>P&amp;L after Interest</td>
                      <td className='report-numeric ant-table-cell'>{PnlAfterInterest}</td>
                      <td className='report-text ant-table-cell bold text-center'>Annualized Return </td>
                      <td className='ant-numeric-cell report-percentage'>{AnaualReturnInterest > 0 ? `${AnaualReturnInterest}%` : 'N/A'}</td>
                      <td className='report-text ant-table-cell bold'>Other Expenses</td>
                      <td className='report-numeric ant-table-cell' colSpan={2}>{OtherExpenses}</td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>Profit Payout</td>
                      <td className='report-numeric ant-table-cell'>{ProffitPayOut}</td>
                      <td className='report-text ant-table-cell text-center'>* Assumed interest saved @ 9%</td>
                      <td className='report-text ant-table-cell'></td>
                      <td className='report-text ant-table-cell bold'>Stock P&amp;L</td>
                      <td className='report-numeric ant-table-cell' colSpan={2}>{StockPnl}</td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>Total Exposure</td>
                      <td className='report-numeric ant-table-cell'>{TotalExposure}</td>
                      <td className='report-text ant-table-cell' colSpan={2}></td>
                      <td className='report-text ant-table-cell bold'>F&amp;O P&amp;L</td>
                      <td className='report-numeric ant-table-cell' colSpan={2}>{POSPnl}</td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>Investment Date</td>
                      <td className='report-numeric ant-table-cell'>{InvestmentDate}</td>
                      <td className='report-text ant-table-cell' colSpan={5}></td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>Stock Received</td>
                      <td className='report-numeric ant-table-cell'>{StockReceived}</td>
                      <td className='report-text ant-table-cell' colSpan={5}></td>
                    </tr>
                    <tr data-row-key='1' className='report-text ant-table-row ant-table-row-level-0'>
                      <td className='report-text ant-table-cell bold'>NLV</td>
                      <td className='report-numeric ant-table-cell'>{NLV}</td>
                      <td className='report-text ant-table-cell' colSpan={5}></td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

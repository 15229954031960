import React from 'react'
import { Row, Table, Col } from 'antd'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts"
import _isEmpty from 'lodash/isEmpty'
import './ILTSShift.css'

export default function IltsShiftTable({ reportData, columns, loading }) {
  const data = reportData.map((report, key) => {
    return { key, ...report }
  })
  let pnChartData = []
  let pnOverCharData = []
  let nlvCharData = []
  let overAllPlChartData = []
  let nlvWithIntrestChartData = []

  if (reportData.length !== 0) {
    Object.keys(reportData[1]).forEach(key => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['pl1'] = reportData[1][key]
        newObj['name'] = reportData[0][key]
        pnChartData.push(newObj)
      }
    })
    for (const iterator of pnChartData) {
      Object.keys(reportData[1]).forEach(key => {
        if (key !== 'particulars' && iterator.name === key) {
          iterator['pl2'] = reportData[2][key]
        }
      })
    }
    Object.keys(reportData[3]).forEach(key => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['pl1'] = reportData[3][key]
        newObj['name'] = reportData[0][key]
        pnOverCharData.push(newObj)
      }
    })
    for (let iterator of pnOverCharData) {
      Object.keys(reportData[4]).forEach(key => {
        if (key !== 'particulars' && iterator.name === key) {
          iterator['pl2'] = reportData[4][key]
        }
      })
    }
    Object.keys(reportData[5]).forEach(key => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['pl1'] = reportData[5][key]
        newObj['name'] = reportData[0][key]
        nlvCharData.push(newObj)
      }
    })
    for (const iterator of nlvCharData) {
      Object.keys(reportData[6]).forEach(key => {
        if (key !== 'particulars' && iterator.name === key) {
          iterator['pl2'] = reportData[6][key]
        }
      })
    }
    Object.keys(reportData[7]).forEach(key => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['pl1'] = reportData[7][key]
        newObj['name'] = reportData[0][key]
        overAllPlChartData.push(newObj)
      }
    })
    for (const iterator of overAllPlChartData) {
      Object.keys(reportData[8]).forEach(key => {
        if (key !== 'particulars' && iterator.name === key) {
          iterator['pl2'] = reportData[8][key]
        }
      })
    }
    Object.keys(reportData[9]).forEach(key => {
      if (key !== 'particulars') {
        let newObj = {}
        newObj['pl1'] = reportData[9][key]
        newObj['name'] = reportData[0][key]
        nlvWithIntrestChartData.push(newObj)
      }
    })
    for (const iterator of nlvWithIntrestChartData) {
      Object.keys(reportData[10]).forEach(key => {
        if (key !== 'particulars' && iterator.name === key) {
          iterator['pl2'] = reportData[10][key]
        }
      })
    }
  }
  return (
    <div>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        className='nlv-tracker-report'
        pagination={{ pageSize: 100, hideOnSinglePage: true }}
      // scroll={{ x: true, y: 'calc(100vh - 72px - 24px - 24px - 36px - 35px)' }}
      />
      <Row>
        <div className="Chart-break">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        {!_isEmpty(pnChartData) && (
          <Col flex={2}>
            <h1 style={{ display: 'flex', justifyContent: 'center' }}>Change in P&L</h1>
            <LineChart
              width={500}
              height={300}
              data={pnChartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pl1"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="pl2"
                stroke="#ff0000"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </Col>
        )}
        <div className="Chart-break">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        {!_isEmpty(pnOverCharData) && (
          <Col flex={2}>
            <h1 style={{ display: 'flex', justifyContent: 'center' }}>OverAll P&L</h1>
            <LineChart
              width={500}
              height={300}
              data={pnOverCharData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pl1"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="pl2"
                stroke="#ff0000"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </Col>
        )}
        <div className="Chart-break">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        {!_isEmpty(nlvCharData) && (
          <Col flex={2}>
            <h1 style={{ display: 'flex', justifyContent: 'center' }}>NLV at Current Position</h1>
            <LineChart
              width={500}
              height={300}
              data={nlvCharData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pl1"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="pl2"
                stroke="#ff0000"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </Col>
        )}
        <div className="Chart-break">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        {
          !_isEmpty(overAllPlChartData) && (
            <Col flex={2}>
              <h1 style={{ display: 'flex', justifyContent: 'center' }}>Overall P&L With Interest</h1>
              <LineChart
                width={500}
                height={300}
                data={overAllPlChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="pl1"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="pl2"
                  stroke="#ff0000"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </Col>
          )
        }
        <div className="Chart-break">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        {
          !_isEmpty(nlvWithIntrestChartData) && (
            <Col flex={2}>
              <h1 style={{ display: 'flex', justifyContent: 'center', width: "50%" }}>NLV With Interest</h1>
              <LineChart
                width={500}
                height={300}
                data={nlvWithIntrestChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="pl1"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="pl2"
                  stroke="#ff0000"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </Col>
          )
        }
      </Row>
    </div >
  )
}

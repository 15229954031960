import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Form, Checkbox, Divider } from 'antd'
import { GET_FIELD_SELECTION } from 'modules/Groups/graphql/Queries'
import client from 'apollo'
import { AppContext } from 'AppContext'
import Loader from 'components/loaders/Loader'
import { USER_FIELD_SELECTION } from 'modules/Settings/graphql/Mutations'

function SelectedFieldsModal({ onCancel, selectedData }) {
  const [loading, setLoading] = useState(false)
  const [updatedField, setUpdatedField] = useState({})
  const [adminUpdateField, setAdminUpdateField] = useState({})
  // const [type, setType] = useState(undefined)
  const [selectAllCheck, setSelectAllCheck] = useState()
  const {
    state: { currentUser },
  } = useContext(AppContext)

  const updateFieldAdmin = JSON.parse(localStorage.getItem('updateFieldAdmin'))

  useEffect(() => {
    setLoading(true)
    client
      .query({
        query: GET_FIELD_SELECTION,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        for (const key in res?.data?.getFieldSelection) {
          setUpdatedField((prevStep) => ({
            ...prevStep,
            [key]: res?.data?.getFieldSelection[key],
          }))
        }
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }, [currentUser, selectedData])

  useEffect(() => {
    setAdminUpdateField(updateFieldAdmin)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSelectedFieldModal = (e, key, data) => {
    setSelectAllCheck(false)
    setUpdatedField({
      ...updatedField,
      [key]: e.target.checked,
    })
  }

  const updateSelectedFieldModalAdmin = (e, key, data) => {
    setSelectAllCheck(false)
    setAdminUpdateField({
      ...adminUpdateField,
      [key]: e.target.checked,
    })
  }

  const handleOk = () => {
    setLoading(true)
    if (currentUser.userName === 'admin') {
      localStorage.setItem('updateFieldAdmin', JSON.stringify(adminUpdateField))
      setLoading(false)
      onCancel()
    } else {
      client
        .mutate({
          mutation: USER_FIELD_SELECTION,
          variables: { data: updatedField },
        })
        .then((res) => {
          setLoading(false)
          onCancel()
        })
        .catch((err) => console.log(err))
    }
  }

  const selectAll = (value) => {
    setSelectAllCheck(value)
    if (currentUser.userName === 'admin') {
      for (const item in adminUpdateField) {
        setAdminUpdateField((prevStep) => ({
          ...prevStep,
          [item]: value,
        }))
      }
    } else {
      for (const item in updatedField) {
        setUpdatedField((prevStep) => ({
          ...prevStep,
          [item]: value,
        }))
      }
    }
  }

  return (
    <Modal
      title='Select Field'
      visible
      onCancel={onCancel}
      footer={[
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            handleOk()
          }}
        >
          Save
        </Button>,
        <Button key='Reset' onClick={(e) => selectAll(true)}>
          Reset
        </Button>,
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      {
        <Form
          initialValues={{
            role: currentUser.role,
          }}
        >
          {!loading ? (
            <Checkbox
              onChange={(e) => selectAll(e.target.checked)}
              name='selectAllcheck'
              checked={selectAllCheck}
            >
              ---SELECT ALL---
            </Checkbox>
          ) : (
            ''
          )}
          <Divider />
          {currentUser.userName !== 'admin' ? (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {!loading ? (
                Object.keys(updatedField)?.map((key) => {
                  if (
                    key !== 'id' &&
                    key !== 'role' &&
                    key !== 'action' &&
                    key !== '__typename'
                  ) {
                    return (
                      <div style={{ width: '50%' }}>
                        <div key={key}>
                          <Checkbox
                            checked={updatedField[key]}
                            key={key}
                            onChange={(e) => updateSelectedFieldModal(e, key)}
                            loading={loading}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {key.split(/(?=[A-Z])/).join(' ')}
                          </Checkbox>
                        </div>
                      </div>
                    )
                  } else {
                    return null
                  }
                })
              ) : (
                <Loader />
              )}
            </div>
          ) : (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {!loading ? (
                Object.keys(adminUpdateField)?.map((key) => {
                  if (
                    key !== 'id' &&
                    key !== 'role' &&
                    key !== 'action' &&
                    key !== '__typename'
                  ) {
                    return (
                      <div style={{ width: '50%' }}>
                        <div key={key}>
                          <Checkbox
                            checked={adminUpdateField[key]}
                            key={key}
                            onChange={(e) =>
                              updateSelectedFieldModalAdmin(e, key)
                            }
                            loading={loading}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {key.split(/(?=[A-Z])/).join(' ')}
                          </Checkbox>
                        </div>
                      </div>
                    )
                  } else {
                    return null
                  }
                })
              ) : (
                <Loader />
              )}
            </div>
          )}
        </Form>
      }
    </Modal>
  )
}

export default SelectedFieldsModal

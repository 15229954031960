import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Select } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import get from 'lodash/get'

import { getName } from 'utils/User'
import { GET_USERS_FOR_SELECT } from '../../Users/graphql/Queries'
// import client from 'apollo'

const { Option } = Select

const CollectionCreateForm = ({ visible, onCreate, onUpdate, onCancel, isEdit, selectedGroup }) => {
  const [form] = Form.useForm()
  const [users, setUsers] = useState([])
  const { data, loading, error } = useQuery(GET_USERS_FOR_SELECT, { variables: { where: { role: 'CLIENT' }, sort: { field: 'clientCode', sortingOrder: 'ASC' }, limit: 1000 }, fetchPolicy: 'cache-and-network' })

  function resetForm() {
    form.resetFields()
  }

  useEffect(() => {
    if (!loading && !error && get(data, 'getUsers.users')) {
      setUsers(data.getUsers.users)
    }
  }, [data, error, loading])

  // function handleSearch(value) {
  //   if (loading) return
  //   if (value) {
  //     client.query({ query: GET_USERS_FOR_SELECT, variables: { where: { role: 'CLIENT', firstName: value } }, fetchPolicy: 'network-only' })
  //       .then((res) => {
  //         if (get(res, 'data.getUsers.users'))
  //           setUsers(res.data.getUsers.users)
  //         else
  //           setUsers([])
  //         return client.query({ query: GET_USERS_FOR_SELECT, variables: { where: { role: 'CLIENT', lastName: value } }, fetchPolicy: 'network-only' })
  //       })
  //       .then((res) => {
  //         if (get(res, 'data.getUsers.users'))
  //           setUsers(users => [...users, ...res.data.getUsers.users])
  //       })
  //       .catch((err) => setUsers([]))
  //   } else {
  //     setUsers(data.getUsers.users)
  //   }
  // }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={isEdit ? 'Edit Group' : 'Add New Group'}
      okText={isEdit ? 'Update Group' : 'Add Group'}
      cancelText='Cancel'
      onCancel={() => {
        resetForm()
        onCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            if (isEdit) {
              onUpdate(values, resetForm)
            } else {
              onCreate(values, resetForm)
            }
          })
          .catch(info => console.log('Validate Failed:', info))
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          modifier: 'public',
          ...selectedGroup
        }}
      >
        <Form.Item
          name='name'
          label='Group Name'
          rules={[{ required: true, message: 'Please input your group name!', whitespace: true }]}
          hasFeedback
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='userIds'
          label='Select Users'
          rules={[{ required: true, message: 'Please select users!' }]}
          hasFeedback
        >
          <Select
            mode='multiple'
            loading={loading}
            filterOption={true}
            optionFilterProp='title'
            // onSearch={handleSearch}
            style={{ width: '100%' }}
            placeholder='Please select users'
          >
            {
              users.map((user, key) => {
                return (
                  <Option
                    key={key}
                    value={user.id}
                    title={`${user.clientCode} - ${getName(user)}`}
                  >
                    {user.clientCode} - {getName(user)}
                  </Option>
                )
              })
            }
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

const CreateGroupModal = (props) => {
  return (
    <div>
      <CollectionCreateForm
        {...props}
      />
    </div>
  )
}

export default CreateGroupModal

/* eslint-disable array-callback-return */
import React, { useContext } from 'react'
import { Col, Collapse, Row, Tabs } from 'antd'

import SystemUsers from 'modules/Users/components/SystemUsers'
import UserClients from 'modules/Users/components/UserClients'
import UserRevenue from 'modules/Users/components/UserRevenue'
import { userDetails, userDocuments } from 'utils/User'
import { AppContext } from 'AppContext'
import AMC from 'modules/Clients/AMC'
import SIP from 'modules/Clients/SIP'
import AboutItem from './AboutItem'
import Widget from '../Widget'
import UserEMandate from 'modules/Users/components/UserEMandate'
import UserQuestionnaire from 'modules/Users/components/UserQuestionnaire'
import { Document, Page, pdfjs } from 'react-pdf'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import moment from 'moment'
const { Panel } = Collapse

const TabPane = Tabs.TabPane
export default function About(props) {
  const {
    details,
    userId,
    isProfile,
    details: { annualIncome, id },
  } = props
  const {
    state: { isClient },
  } = useContext(AppContext)
  const aboutList = userDetails(details)
  const documentList = userDocuments(details.kyc, isClient)

  const GET_USER_PDF = gql`
    query usersPdf($userId: Int) {
      usersPdf(where: { userId: $userId }) {
        id
        type
        pdfUrl
        createdAt
      }
    }
  `

  const { data } = useQuery(GET_USER_PDF, {
    variables: { userId: parseInt(id) },
    fetchPolicy: 'network-only',
  })

  const PDFs = data?.usersPdf

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  return (
    <Widget
      title='Details'
      styleName='gx-card-tabs gx-card-tabs-right gx-card-profile'
    >
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Overview' key='1'>
          <div className='gx-mb-2'>
            <Row>
              {aboutList.map((about, index) => (
                <Col key={index} xl={6} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem {...about} />
                </Col>
              ))}
            </Row>
          </div>
        </TabPane>
        {details.role === 'CLIENT' && (
          <TabPane tab='SIP' key='2'>
            <div className='gx-mb-2'>
              <Row>
                <SIP {...details} />
              </Row>
            </div>
          </TabPane>
        )}
        {details.role === 'CLIENT' && (
          <TabPane tab='AMC' key='3'>
            <div className='gx-mb-2'>
              <Row>
                <AMC {...details} />
              </Row>
            </div>
          </TabPane>
        )}
        {details.role === 'CLIENT' && (
          <TabPane tab='Documents' key='4'>
            <div className='gx-mb-2'>
              <Row>
                {documentList.map((doc, index) => (
                  <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem {...doc} />
                  </Col>
                ))}
              </Row>
            </div>
          </TabPane>
        )}
        {!isProfile && details.role !== 'CLIENT' && (
          <TabPane tab='Users' key='5'>
            <div className='gx-mb-2'>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <SystemUsers userId={parseInt(userId)} />
                </Col>
              </Row>
            </div>
          </TabPane>
        )}
        {!isProfile && details.role !== 'CLIENT' && (
          <TabPane tab='Clients' key='6'>
            <div className='gx-mb-2'>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <UserClients userId={parseInt(userId)} />
                </Col>
              </Row>
            </div>
          </TabPane>
        )}
        {!isProfile && !isClient && details.role !== 'CLIENT' && (
          <TabPane tab='Revenue Income' key='7'>
            <div className='gx-mb-2'>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <UserRevenue userId={userId} />
                </Col>
              </Row>
            </div>
          </TabPane>
        )}
        {!isProfile && details.role === 'CLIENT' && props?.details?.plan?.id && (
          <TabPane tab='Questionnaire' key='8'>
            <div className='gx-mb-2'>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <UserQuestionnaire
                    annualIncome={annualIncome}
                    userId={parseInt(props?.details?.id)}
                    planId={props?.details?.plan?.id}
                  />
                </Col>
              </Row>
            </div>
          </TabPane>
        )}
        {!isProfile && details.role === 'CLIENT' && props?.details?.mandateId && (
          <TabPane tab='E-mandate Report' key='9'>
            <div className='gx-mb-2'>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <UserEMandate userId={parseInt(props?.details?.id)} />
                </Col>
              </Row>
            </div>
          </TabPane>
        )}
        {/* {!isProfile && details.role === 'CLIENT' && props?.details?.mandateId &&(
          <TabPane tab='Consent Data' key='10'>
            {PDFs &&
              PDFs?.length !== 0 &&
              PDFs.map((pdf, key) => {
                if (pdf.type === 'clientConsent')
                  return (
                        <Collapse key={key}>
                      <Panel
                        header={moment(pdf.createdAt).format('DD-MM-YYYY, h:mm a')}
                      >
                        <div
                          className='gx-mb-2'
                          onClick={() => window.open(pdf.pdfUrl, '_blank')}
                        >
                          <Row>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                              <div className='pdfViewer'>
                                <Document file={pdf.pdfUrl}>
                                  <Page pageNumber={1} />
                                </Document>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    </Collapse>
                  )
              })}
          </TabPane>
        )} */}
        {details.role === 'CLIENT' && PDFs?.length !== 0 && (
          <TabPane tab='Risk Assessment' key='11'>
            {PDFs &&
              PDFs?.length !== 0 &&
              PDFs.map((pdf, key) => {
                if (pdf.type === 'riskAssessment')
                  return (
                    <Collapse key={key}>
                      <Panel
                        header={moment(pdf.createdAt).format(
                          'DD-MM-YYYY, h:mm a'
                        )}
                      >
                        <div
                          className='gx-mb-2'
                          onClick={() => window.open(pdf.pdfUrl, '_blank')}
                        >
                          <Row>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                              <div className='pdfViewer'>
                                <Document file={pdf.pdfUrl}>
                                  <Page pageNumber={1} />
                                </Document>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    </Collapse>
                  )
              })}
          </TabPane>
        )}
        {details.role === 'CLIENT' && PDFs?.length !== 0 && (
          <TabPane tab='Suitability' key='13'>
            {PDFs &&
              PDFs?.length !== 0 &&
              PDFs.map((pdf, key) => {
                if (pdf.type === 'suitabilityReport')
                  return (
                    <Collapse key={key}>
                      <Panel
                        header={moment(pdf.createdAt).format(
                          'DD-MM-YYYY, h:mm a'
                        )}
                      >
                        <div
                          className='gx-mb-2'
                          onClick={() => window.open(pdf.pdfUrl, '_blank')}
                        >
                          <Row>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                              <div className='pdfViewer'>
                                <Document file={pdf.pdfUrl}>
                                  <Page pageNumber={1} />
                                </Document>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    </Collapse>
                  )
              })}
          </TabPane>
        )}
        {details.role === 'CLIENT' && PDFs?.length !== 0 && (
          <TabPane tab='Agreement Report' key='12'>
            {PDFs &&
              PDFs?.length !== 0 &&
              PDFs.map((pdf, key) => {
                if (pdf.type === 'agreementReport')
                  return (
                    <Collapse key={key}>
                      <Panel
                        header={moment(pdf.createdAt).format(
                          'DD-MM-YYYY, h:mm a'
                        )}
                      >
                        <div
                          className='gx-mb-2'
                          onClick={() => window.open(pdf.pdfUrl, '_blank')}
                        >
                          <Row>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                              <div className='pdfViewer'>
                                <Document file={pdf.pdfUrl}>
                                  <Page pageNumber={1} />
                                </Document>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    </Collapse>
                  )
              })}
          </TabPane>
        )}
      </Tabs>
    </Widget>
  )
}

import React, { useContext, useState } from 'react'
import { Modal, Descriptions, Checkbox, Button, Row, Col } from 'antd'
import get from 'lodash/get'

import { AppContext } from 'AppContext'
import client from 'apollo'
import {
  SEND_OTP_FOR_REEVALUATE,
  UPDATE_USER,
} from 'modules/Users/graphql/Mutations'
import {
  COMPLETE_STEP,
  CONSENT_DATA,
  GIVE_ANSWER,
  RISK_ASSESSMENT_MAIL,
} from '../graphql/Mutations'
import openNotification from 'utils/Notification'
import { orderBy } from 'lodash'
import VerificationModal from './VerificationModal'
import moment from 'moment'

const VerifyDetailsShow = ({
  visible,
  onCancel,
  formValue,
  next,
  section,
  points,
  reEvaluate,
}) => {
  const [agree, setAgree] = useState(false)
  const [loadings, setLoadings] = useState(false)
  const [otpModal, setOtpModal] = useState(false)
  const [modalClose, setModalClose] = useState(true)
  const {
    dispatch,
    state: { currentUser },
  } = useContext(AppContext)
  const { id, cvlStatus } = currentUser

  const handleAgree = (e) => {
    setAgree(e.target.checked)
  }

  let getAnswers = []
  if (get(formValue, 'answers')) {
    getAnswers = formValue.answers

    let array = []
    getAnswers.forEach((item) => {
      item = {
        ...item,
        planId: +item.planId,
      }

      array.push(item)
    })

    getAnswers = array
  }

  const onNext = () => {
    setLoadings(true)
    client
      .mutate({
        mutation: GIVE_ANSWER,
        variables: {
          data: {
            answers: getAnswers,
            annualIncome: formValue.annualIncome.toString(),
          },
        },
      })
      .then(() =>
        client.mutate({
          mutation: UPDATE_USER,
          variables: {
            id,
            data: {
              registrationDate: moment().format('YYYY-MM-DD'),
              completedSteps: cvlStatus ? 'KYC_FORM' : 'RISK_PROFILE',
              clientScore: points,
            },
          },
        })
      )
      .then((res) => {
        dispatch({ type: 'CURRENT_USER', data: res.data.updateUser })
        client.mutate({ mutation: RISK_ASSESSMENT_MAIL })
        client.mutate({ mutation: CONSENT_DATA })
        client
          .mutate({
            mutation: COMPLETE_STEP,
            variables: {
              data: {
                step: 'RISK_PROFILE',
                from: 'WEB',
              },
            },
          })
          .then((res) => {
            openNotification(
              'success',
              'Risk Profile Assessment Updated Successfully'
            )
            next()
          })
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadings(false))
  }

  const renderItem = (question, answer, isHeading = false) => {
    return (
      <Descriptions.Item
        span={3}
        style={{ backgroundColor: isHeading ? '#f2f2f2' : '' }}
      >
        <Row>
          <Col span={16} style={{ borderRight: '1px solid #f2f2f2 ' }}>
            {question.question}
          </Col>
          <Col span={8}>{answer}</Col>
        </Row>
      </Descriptions.Item>
    )
  }

  const onOk = () => {
    if (reEvaluate) {
      setOtpModal(true)
      client.mutate({ mutation: SEND_OTP_FOR_REEVALUATE }).then((res) => {
        openNotification('success', res?.data?.sendOtpForReEvaluate?.message)
      })
      setModalClose(false)
    } else {
      onNext()
    }
  }

  return (
    <>
      {modalClose ? (
        <Modal
          width={'80%'}
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button
                onClick={() => onOk()}
                type='primary'
                disabled={!agree}
                loading={loadings}
              >
                Next
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <Descriptions bordered>
            {renderItem({ question: 'QUESTION(s)' }, 'ANSWER(s)', true)}
            {renderItem(
              { question: '1. What is your annual Income?' },
              formValue.annualIncome
            )}
            {section.map((section, key) =>
              orderBy(section.questions, 'index').map((question, key) => {
                let answer
                if (question.type === 'TEXT') {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.answer
                } else if (question.type === 'CHECK_BOX') {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.selectedOptionsId
                  if (answer)
                    answer = question.options
                      .filter((o) => answer.includes(parseInt(o.id)))
                      .map((o) => o.option)
                      .join(', ')
                } else {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.selectedOptionsId
                  if (answer)
                    answer = question.options
                      .filter((o) => answer === parseInt(o.id))
                      .map((o) => o.option)
                      .join(', ')
                }

                return renderItem(question, answer)
              })
            )}
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
        </Modal>
      ) : (
        <Modal
          style={{ display: 'none' }}
          width={'80%'}
          maskClosable={false}
          visible={visible}
          title={'Verify Your Details'}
          cancelText='Cancel'
          footer={
            <>
              <Button onClick={() => onCancel()}>Cancel</Button>
              <Button onClick={() => onOk()} type='primary' disabled={!agree}>
                Next
              </Button>
            </>
          }
          onCancel={() => onCancel()}
        >
          <Descriptions bordered>
            {renderItem({ question: 'QUESTION(s)' }, 'ANSWER(s)', true)}
            {renderItem(
              { question: '1. What is your annual Income?' },
              formValue.annualIncome
            )}
            {section.map((section, key) =>
              orderBy(section.questions, 'index').map((question, key) => {
                let answer
                if (question.type === 'TEXT') {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.answer
                } else if (question.type === 'CHECK_BOX') {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.selectedOptionsId
                  if (answer)
                    answer = question.options
                      .filter((o) => answer.includes(parseInt(o.id)))
                      .map((o) => o.option)
                      .join(', ')
                } else {
                  answer = getAnswers.find(
                    (value) => value.questionId === parseInt(question.id)
                  )?.selectedOptionsId
                  if (answer)
                    answer = question.options
                      .filter((o) => answer === parseInt(o.id))
                      .map((o) => o.option)
                      .join(', ')
                }

                return renderItem(question, answer)
              })
            )}
          </Descriptions>
          <br />
          <div className='agree-wrapper'>
            <Checkbox onChange={handleAgree} />
            <p className='agree-content'>
              I/We hereby declare that the details furnished above are true and
              correct to the best of my/our knowledge and belief and I/We
              undertake to inform you of any changes therein, immediately. In
              case any of the above information is found to be false or untrue
              or misleading or misrepresenting, I am/We are aware that I/We may
              be held liable for it.
            </p>
          </div>
        </Modal>
      )}
      <VerificationModal
        visible={otpModal}
        onCancel={onCancel}
        onNext={onNext}
      />
    </>
  )
}

const RiskProfile = (props) => {
  return (
    <div>
      <VerifyDetailsShow {...props} />
    </div>
  )
}

export default RiskProfile

import React, { useState, useEffect, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import {
  GET_ALL_ACCESS_SETTINGS,
  GET_SITE_SETTING,
  GET_SYSTEM_ALERT,
} from 'modules/Settings/graphql/Queries'
import { GET_CURRENT_USER } from './graphql/Queries'
import { AppContext } from 'AppContext'
import Callback from './Callback'
import client from 'apollo'
import * as Auth from '.'
import { GET_FIELD_SELECTION } from 'modules/Groups/graphql/Queries'

function GuardedRoute(props) {
  const { component: Component, path } = props
  const [loading, setLoading] = useState(true)

  const isAuthenticated = Auth.isAuthenticated()
  const { dispatch } = useContext(AppContext)

  useEffect(() => {
    const url = window.location.href
    const token = url.split('?')
    if (token && !isAuthenticated) {
      const getToken = token[1]?.split('=')
      if (getToken) {
        if (getToken[1][getToken[1].length - 1] === '#') {
          const token = getToken[1].slice(0, -1)
          localStorage.setItem('token', JSON.stringify(token))
          window.location.href = '/'
        } else {
          localStorage.setItem('token', JSON.stringify(getToken[1]))
          window.location.href = '/'
        }
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    let userRole = ''
    if (isAuthenticated !== undefined && isAuthenticated) {
      client
        .query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
        .then((res) => {
          const { role, completedSteps } = res.data.currentUser
          userRole = role
          dispatch({ type: 'CURRENT_USER', data: res.data.currentUser })
          dispatch({ type: 'IS_ADMIN', data: role === 'ADMIN' })
          dispatch({ type: 'IS_STAFF', data: role === 'STAFF' })
          dispatch({
            type: 'IS_MASTER_ASSOCIATE',
            data: role === 'MASTER_ASSOCIATE',
          })
          dispatch({ type: 'IS_ASSOCIATE', data: role === 'ASSOCIATE' })
          dispatch({
            type: 'IS_SALES_EXECUTIVE',
            data: role === 'SALES_EXECUTIVE',
          })
          dispatch({ type: 'IS_CLIENT', data: role === 'CLIENT' })
          dispatch({
            type: 'IS_VERIFICATION_DONE',
            data: completedSteps === 'DONE',
          })
        })
        .then(() =>
          client.query({
            query: GET_ALL_ACCESS_SETTINGS,
            fetchPolicy: 'network-only',
          })
        )
        .then((res) => {
          function getRole(role) {
            if (!role.includes('_')) return role
            if (role === 'sales_executive') return 'salesExecutive'
            if (role === 'master_associate') return 'masterAssociate'
            return role
          }
          const {
            data: { getAllAccessSettings },
          } = res
          const reportData = getAllAccessSettings
            .filter((r) => r[getRole(userRole.toLowerCase())])
            .map((r) => r.title)
          dispatch({ type: 'CURRENT_REPORT', data: reportData })
        })
        .then(() =>
          client
            .query({
              query: GET_FIELD_SELECTION,
              variables: { where: { id: '1' } },
              fetchPolicy: 'network-only',
            })
            .then((res) => {
              let tempLocalObj = {}
              if (userRole === 'ADMIN') {
                for (const key in res?.data?.getFieldSelection) {
                  if (res?.data?.getFieldSelection[key] === true) {
                    tempLocalObj[key] = res?.data?.getFieldSelection[key]
                  }
                }
              }
              localStorage.setItem(
                'updateFieldAdmin',
                JSON.stringify(tempLocalObj)
              )
            })
            .catch((err) => console.log(err))
        )
        .then(() =>
          client.query({ query: GET_SITE_SETTING, fetchPolicy: 'network-only' })
        )
        .then((res) => {
          if (
            res.data.getSiteSettings[0].status &&
            res.data.getSiteSettings[0].url.split('/')[2] !==
              window.location.href.split('/')[2] &&
            userRole !== 'ADMIN'
          ) {
            window.location.href = res.data.getSiteSettings[0].url
          }
        })
        .then(() =>
          client.query({ query: GET_SYSTEM_ALERT, fetchPolicy: 'network-only' })
        )
        .then((res) =>
          dispatch({
            type: 'MAINTENANCE_STATUS',
            data: res.data.getSystemAlert,
          })
        )
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [dispatch, isAuthenticated])
  return loading ? (
    <Route component={Callback} />
  ) : (
    <Route
      path={path}
      render={(props) => {
        if (isAuthenticated !== undefined && !isAuthenticated)
          return <Redirect to='/login' />
        return <Component {...props} />
      }}
    />
  )
}

export default GuardedRoute

import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { get } from 'lodash'

import NoData from 'components/NoData'
import { AppContext } from 'AppContext'
import Page404 from 'components/Page404'
import InterestTable from './InterestTable'
import Loader from 'components/loaders/Loader'
import DownloadReport from '../DownloadReport'
import { GET_INTEREST_REPORT } from '../graphql/Queries'
import '../index.css'

export default function () {
  const { state: { currentUser: { clientCode } } } = useContext(AppContext)
  const { data, loading, error } = useQuery(GET_INTEREST_REPORT, { variables: { where: { clientCode, date: null, strategy: 'ILTS' } } })

  if (loading) return <Loader />
  if (error) return <Page404 error={error} />
  if (!get(data, 'spInterestReport')) return <NoData />

  const { spInterestReport: [spInterestReport] } = data

  return (
    <>
      <DownloadReport data={spInterestReport} fileName='interest-report' id='INTEREST-REPORT-PDF' />
      <div className='report-table-wrapper interest-table-report' id='INTEREST-REPORT-PDF'>
        <InterestTable reportData={spInterestReport} />
      </div>
    </>
  )
}

import { get, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Form, Row, Col, Select, Button } from 'antd'

import '../index.css'
import { getName } from 'utils/User'
import NoData from 'components/NoData'
import Page404 from 'components/Page404'
import DownloadReport from '../DownloadReport'
import Loader from 'components/loaders/Loader'
import { GET_USERS_FOR_SELECT } from 'modules/Users/graphql/Queries'
import { GET_CAPITAL_GAIN, GET_FINANCIAL_YEAR } from '../graphql/Queries'

const { Option } = Select

export default function IltsTaxationReport() {
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})

  const { data = {}, loading, error } = useQuery(GET_CAPITAL_GAIN, { variables: filters, fetchPolicy: 'network-only' })
  const { data: financialYearData, loading: financialYearLoading } = useQuery(GET_FINANCIAL_YEAR, { fetchPolicy: 'network-only' })
  const { data: userData, loading: userLoading } = useQuery(GET_USERS_FOR_SELECT, { variables: { where: { role: 'CLIENT' }, sort: { field: 'clientCode', sortingOrder: 'ASC' }, limit: 1000 }, fetchPolicy: 'cache-and-network' })

  let capitalGain = []
  let capitalGainCSV = []
  let capitalGainFooter = []

  if (!loading && get(data, 'capitalGain')) {
    capitalGain = data.capitalGain.slice(0, data.capitalGain.length - 7)
    capitalGainFooter = data.capitalGain.slice(data.capitalGain.length - 7, data.capitalGain.length)
    capitalGain.push({ GrossProfit: 'Total Volume', Volume: capitalGainFooter[6].TotalVolume })
    capitalGainCSV = capitalGain.filter((r, key) => key !== 0)
    capitalGainCSV.push({})
    capitalGainCSV.push({
      Script: "Expense Details"
    })
    capitalGainFooter.forEach((r, key) => {
      if (key === capitalGainFooter.length - 1) {
        capitalGainCSV.push({
          Script: 'TotalNetPnl',
          OpeningQty: r.TotalNetPnl
        })
        capitalGainCSV.push({
          Script: 'TotalVolume',
          OpeningQty: r.TotalVolume
        })

        capitalGainCSV.push({})
        capitalGainCSV.push({
          Script: "Summary Details"
        })
        capitalGainCSV.push({
          Script: 'Expense',
          OpeningQty: r.Expense
        })
        capitalGainCSV.push({
          Script: 'Transactions P&L',
          OpeningQty: r.TransPnl
        })
      }
      capitalGainCSV.push({
        Script: r.Description,
        OpeningQty: r.Value
      })
    })
  }

  const onFinish = (values) => {
    const { clientCode, selectYear } = values
    const financialYear = financialYearData.getFinancialYear.find((f) => f.FA_YEAR_ID === selectYear)
    const openingDate = financialYear.OPENING_DATE
    const startDate = financialYear.START_DATE
    const endDate = financialYear.END_DATE
    setFilters({
      where: {
        openingDate,
        clientCode,
        startDate,
        endDate,
      }
    })
  }
  let staticHtml = ``
  if (!loading && get(data, 'capitalGain')) {
    staticHtml = `
  <html>
  <head>
  <style>
  table{
    width: 100%;
    border-spacing: 0px;
  }
  td{
    border: 1px solid;
    border-spacing: 0px;
  }
  </style>
  <body>
  <div style=" margin:15px;">
<table class="table table-bordered" style="border: 1px solid;border-spacing: 0px;">
  <tbody>
    <tr>
      <td colspan="11">
        <div style="text-align:center;"><strong>ILTS taxable P&L Report</strong></div>
      </td>
    </tr>

    <!--tr>
      <td colspan="11">
        <div style="text-align:center;"><strong>From Date:01-Apr-2021 To Date:17-Sep-2021</strong></div>
      </td>
    </tr-->
    <tr>
      <td colspan="2">
        <div style="text-align:center;"><strong>Client Code</strong></div>
      </td>
      <td colspan="2">
        <div style="text-align:center;">${data.capitalGain.filter((report, key) => (key === 0))[0].ClientCode}</div>
      </td>
      <td><br /></td>
      <td><br /></td>
      <td colspan="2">
        <div style="text-align:center;"><strong>Client Name</strong></div>
      </td>
      <td colspan="3">
        <div style="text-align:center;">${data.capitalGain.filter((report, key) => (key === 0))[0].ClientName}</div>
      </td>
    </tr>
    <tr>
      <td colspan="11">
        <div style="text-align:center;"><strong>&nbsp;</strong></div>
      </td>
    </tr>
    <tr>
      <td colspan="11">
        <div style="text-align:center;"><strong>Transaction Details</strong></div>
      </td>
    </tr>
    <tr>
      <td>
        <div style="text-align:center;"><b>Script</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>OpeningQty</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>OpeningRate</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>BuyQty</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>BuyRate</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>SellQty</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>SellRate</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>ClosingQty</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>ClosingRate</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>GrossProfit</b></div>
      </td>
      <td>
        <div style="text-align:center;"><b>Volume</b></div>
      </td>
    </tr>`
    staticHtml = `${staticHtml}`
    capitalGain.filter((report, key) => key !== 0).forEach((data) => {
      const { Script, OpeningQty, OpeningRate, BuyQty, BuyRate, SellQty, SellRate, ClosingQty, ClosingRate, GrossProfit, Volume } = data
      staticHtml = `${staticHtml}
        <tr>
          <td>
            <div style= "width:160px;">${Script} </div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${OpeningQty}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${OpeningRate}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${BuyQty}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${BuyRate}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${SellQty}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${SellRate}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${ClosingQty}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${ClosingRate}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${GrossProfit}</div>
          </td>
          <td>
            <div style="text-align:center; float:right;">${Volume}</div>
          </td>
        </tr>`
    })
    staticHtml = `${staticHtml}
          <tr>
            <td colspan="11">
              <div style="text-align:center;"><strong>&nbsp;</strong></div>
            </td>
          </tr>
          <tr>
            <td colspan="10"><strong></strong></td>
            <td>
              <div style= "width:174px;">
                <b>Total Volume = ${capitalGainFooter[6].TotalVolume}</b>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="text-align:center;">
                <b>Expense Details</b>
              </div>
            </td>
            <td colspan="10"></td>
          </tr>
          <tr>
            <td>
              <div>
                ${capitalGainFooter[0].Description}
              </div>
            </td>
            <td>
              <div style="float: right;">
                ${capitalGainFooter[0].Value}
              </div>
            </td>
            <td colspan="7"><strong><br /></strong></td>
            <td colspan="3">
              <div style="text-align:center;">
                <b>Summary Details</b>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                ${capitalGainFooter[1].Description}
              </div>
            </td>
            <td>
              <div style="float: right;">
                ${capitalGainFooter[1].Value}
              </div>
            </td>
            <td colspan="7"></td>
            <td colspan="3">
            </td>
          </tr>
          <tr>
            <td>
              <div>
                ${capitalGainFooter[2].Description}
              </div>
            </td>
            <td>
              <div style="float: right;">
                ${capitalGainFooter[2].Value}
              </div>
            </td>
            <td colspan="7"></td>
            <td>Transactions P&L</td>
            <td>
              <div style="float: right;">
                ${capitalGainFooter[6].TransPnl}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                ${capitalGainFooter[3].Description}
              </div>
            </td>
            <td>
              <div style="float: right;">
                ${capitalGainFooter[3].Value}
              </div>
            </td>
            <td colspan="7"></td>
            <td>
              <div style= "width:121px">
                <p>Expense</p>
              </div>
            </td>
            <td>
              <div style="float: right;">
                ${capitalGainFooter[6].Expense}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                ${capitalGainFooter[4].Description}
              </div>
            </td>
            <td>
              <div style="float: right;">
                ${capitalGainFooter[4].Value}
              </div>
            </td>
            <td colspan="7"><strong><br /></strong></td>
            <td colspan="3">
              <div style="text-align:center;">

              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="float: right;">
                <b>${capitalGainFooter[5].Description}</b>
              </div>
            </td>
            <td>
              <div style="float: right;">
                <b>${capitalGainFooter[5].Value}</b>
              </div>
            </td>
            <td colspan="7"></td>
            <td>
              <div style= "width:121px;">
                <b>Total Net P&L</b>
              </div>
            </td>
            <td>
              <div style="float: right;">
                <b>${capitalGainFooter[6].TotalNetPnl}</b>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="11">
              <div><b>Disclaimer:This report is just for idea purpose. We don't take any responsibillity of autheticity
                of this. Kindly consult your legal advisor for accuracy.</b></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </body>
    </head>
    </html>`
  }

  return (
    <>
      {
        !error && get(data, 'capitalGain') && !isEmpty(filters) &&
        <DownloadReport data={capitalGainCSV} staticHtml={staticHtml} fileName='Taxation-Report' id='ILTS-PDF' />
      }
      <br />
      <br />
      <Row>
        <Col span={20}>
          <Form

            form={form}
            onFinish={onFinish}
            name="advanced_search"
          >
            <Row>
              <Col xs={24} sm={24} md={10} lg={10} xl={10} style={{ padding: '0 3rem' }}>
                <Form.Item
                  name='clientCode'
                  label='Select Users'
                  rules={[{ required: true, message: 'Please select users!' }]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    filterOption={true}
                    loading={userLoading}
                    optionFilterProp='title'
                    style={{ width: '100%' }}
                    placeholder='Please select users'
                  >
                    {
                      !userLoading && userData && userData.getUsers.users.map((user, key) => {
                        return (
                          <Option
                            key={key}
                            value={user.clientCode}
                            title={`${user.clientCode} - ${getName(user)}`}
                          >
                            {user.clientCode} - {getName(user)}
                          </Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ padding: '0 3rem' }}>
                <Form.Item
                  name='selectYear'
                  label='Select Year'
                  rules={[{ required: true, message: 'Please select year!' }]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    loading={financialYearLoading}
                    style={{ width: '100%' }}
                    placeholder="Select Year"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {
                      !financialYearLoading && financialYearData && financialYearData.getFinancialYear.map((financial) => {
                        return (
                          <Option value={financial.FA_YEAR_ID}>{financial.FA_YEAR}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields()
                    setFilters({})
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {
        loading ?
          <Loader />
          :
          error ?
            <Page404 />
            :
            (!get(data, 'capitalGain')) || isEmpty(filters) ?
              <NoData />
              :
              <div className='report-table-wrapper' id='ILTS-PDF'>

                <div className="ant-table">
                  <table className="table table-bordered">
                    <tbody className="ant-table-tbody">
                      <tr>
                        <td className="ant-table-cell" colspan="11">
                          <div style={{ textAlign: "center" }}><strong>ILTS taxable P&L Report</strong></div>
                        </td>
                      </tr>
                      {/* <tr>
                    <td className="ant-table-cell" colspan="11">
                      <div style={{ textAlign: "center" }}><strong>From Date:01-Apr-2021 To Date:17-Sep-2021</strong></div>
                    </td>
                  </tr> */}
                      <tr>
                        <td colspan="2">
                          <div style={{ textAlign: "center" }}><strong>Client Code</strong></div>
                        </td>
                        <td colspan="2">
                          <div style={{ textAlign: "center" }}>{data.capitalGain.filter((report, key) => (key === 0))[0].ClientCode}</div>
                        </td>
                        <td><br /></td>
                        <td><br /></td>
                        <td colspan="2">
                          <div style={{ textAlign: "center" }}><strong>Client Name</strong></div>
                        </td>
                        <td colspan="3">
                          <div style={{ textAlign: "center" }}>{data.capitalGain.filter((report, key) => (key === 0))[0].ClientName}</div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="11">
                          <div style={{ textAlign: "center" }}><strong>&nbsp;</strong></div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="11">
                          <div style={{ textAlign: "center" }}><strong>Transaction Details</strong></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ textAlign: "center" }}><b>Script</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>OpeningQty</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>OpeningRate</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>BuyQty</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>BuyRate</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>SellQty</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>SellRate</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>ClosingQty</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>ClosingRate</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>GrossProfit</b></div>
                        </td>
                        <td>
                          <div style={{ textAlign: "center" }}><b>Volume</b></div>
                        </td>
                      </tr>
                      {
                        capitalGain
                          .filter((report, key) => key !== 0)
                          .map((data) => {
                            const {
                              Script,
                              Volume,
                              BuyQty,
                              BuyRate,
                              SellQty,
                              SellRate,
                              OpeningQty,
                              ClosingQty,
                              GrossProfit,
                              ClosingRate,
                              OpeningRate,
                            } = data

                            return (
                              <tr>
                                <td>
                                  <div className="report-text">{Script} </div>
                                </td>
                                <td>
                                  <div className="report-numeric">{OpeningQty}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{OpeningRate}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{BuyQty}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{BuyRate}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{SellQty}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{SellRate}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{ClosingQty}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{ClosingRate}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{GrossProfit}</div>
                                </td>
                                <td>
                                  <div className="report-numeric">{Volume}</div>
                                </td>
                              </tr>
                            )
                          })}
                      <tr>
                        <td colspan="10"><strong></strong></td>
                        <td>
                          <div className="report-numeric">
                            <b>Total Volume = {capitalGainFooter[6].TotalVolume}</b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="report-text">
                            <b>Expense Details</b>
                          </div>
                        </td>
                        <td colspan="10"></td>
                      </tr>
                      <tr>
                        <td>
                          <div className="report-text">
                            {capitalGainFooter[0].Description}
                          </div>
                        </td>
                        <td>
                          <div className="report-numeric">
                            {capitalGainFooter[0].Value}
                          </div>
                        </td>
                        <td colspan="7"><strong><br /></strong></td>
                        <td colspan="3">
                          <div className="report-text">
                            <b>Summary Details</b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="report-text">
                            {capitalGainFooter[1].Description}
                          </div>
                        </td>
                        <td>
                          <div className="report-numeric">
                            {capitalGainFooter[1].Value}
                          </div>
                        </td>
                        <td colspan="7"></td>
                        <td colspan="3">
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="report-text">
                            {capitalGainFooter[2].Description}
                          </div>
                        </td>
                        <td>
                          <div className="report-numeric">
                            {capitalGainFooter[2].Value}
                          </div>
                        </td>
                        <td colspan="7"></td>
                        <td className="report-text">Transactions P&L</td>
                        <td>
                          <div className="report-numeric">
                            {capitalGainFooter[6].TransPnl}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="report-text">
                            {capitalGainFooter[3].Description}
                          </div>
                        </td>
                        <td>
                          <div className="report-numeric">
                            {capitalGainFooter[3].Value}
                          </div>
                        </td>
                        <td colspan="7"></td>
                        <td>
                          <div className="report-text" style={{ width: "121px" }}>
                            <p>Expense</p>
                          </div>
                        </td>
                        <td>
                          <div className="report-numeric">
                            {capitalGainFooter[6].Expense}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="report-text">
                            {capitalGainFooter[4].Description}
                          </div>
                        </td>
                        <td>
                          <div className="report-numeric">
                            {capitalGainFooter[4].Value}
                          </div>
                        </td>
                        <td colspan="7"><strong><br /></strong></td>
                        <td colspan="3">
                          <div className='report-text'></div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <b>{capitalGainFooter[5].Description}</b>
                          </div>
                        </td>
                        <td>
                          <div className="report-numeric">
                            <b>{capitalGainFooter[5].Value}</b>
                          </div>
                        </td>
                        <td colspan="7"></td>
                        <td>
                          <div className="report-text" style={{ width: "121px" }}>
                            <b>Total Net P&L</b>
                          </div>
                        </td>
                        <td>
                          <div className="report-numeric">
                            <b>{capitalGainFooter[6].TotalNetPnl}</b>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="11">
                          <div><b>Disclaimer:This report is just for idea purpose. We don't take any responsibillity of autheticity
                            of this. Kindly consult your legal advisor for accuracy.</b></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
      }
    </>
  )
}
